import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

class ChangeParameter extends Component {
  constructor(props){
    super(props);
    this.state = { 
        fade : false,
        value : this.props.changedCash
    }
  }

  
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({ fade: true, value: nextProps.value });
    }
  }

  render() {
    const fade = this.state.fade;
    return (
        <div id={this.props.id} className={"change-parameter " + this.props.className + (fade ? ' fade' : '')} onAnimationEnd={() => {this.setState({ fade: false });} }>{this.state.value}</div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ChangeParameter);
