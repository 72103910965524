import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';


class BoardValue extends Component {
    state = {
        style:{},
        imgStyle:{}
    }
  constructor(props){
    super(props);
  }

  componentDidMount(){
    let number = this.props.value;
    let displayNumber = number>this.props.max?1:number;

    this.setState({
        number,
        displayNumber
    });
  }

  render() {
    const style = {
        left:this.props.left,
        top:this.props.top,
        width:this.props.width
    };
    const imgStyle = {
        width:parseFloat(this.props.parentWidth) * parseFloat(this.props.width) + "px"
    };

    const imgs = (()=>{
        let imgs = []
        for(let i=0; i<this.state.displayNumber; i++){
            imgs.push((()=>{return (<img src={this.props.src} style={imgStyle} />) })());
        }
        return imgs;
    })();
    return (
        <div className="board-value" style={style}>
            <div className="inner">
                <div className="board-value-icon">{imgs}</div>
                {this.state.number>this.props.max?<div className="board-value-value">x {this.props.value}</div>:null}
            </div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(BoardValue);
