import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import Function from "../../../functions/Function";
import Server from '../../../functions/Server';

const func = new Function();
const server = new Server();

const { updateIn } = require('immutable');

class Table extends Component {
    constructor(props){
        super(props);
    }

    componentDidMount(){
        if(this.props.period>1)
        server.getProductSum(this.props.user.team, (list) => {
            let newUser;
            for(let i=0; i<this.props.period-1; i++){
                const value = func.numFix(this.props.user.analysis[3].value[i].value/list[i]*100);
                newUser = updateIn(
                    newUser!==undefined?newUser:this.props.user, 
                    ['analysis', 3, 'value', i, 'result'], 
                    val => value
                );

                newUser = updateIn(
                    newUser, 
                    ['performance', 5, 'value', i], 
                    val => value
                );
            }
            this.props.update(newUser);
        });
        this.tableFloatingAnim();
        document.querySelector('.table-container').addEventListener("scroll",this.tableFloating);
    }

    componentWillUnmount(){
        document.querySelector('.table-container').removeEventListener("scroll",this.tableFloating);
    }

    tableFloatingAnim = () => {
        this.tableFloating();
        if(document.querySelector('.table-container'))requestAnimationFrame(this.tableFloatingAnim);
    }
    
    tableFloating = () => {
        if(document.querySelector('.table-container')){
            let x = document.querySelector('.table-container').scrollLeft;
            document.querySelector('#floating-table').style.transform = "translateX("+x+"px)";
        }
    }

    render() {
        const analysis = this.props.state.user.analysis;

        let subject = analysis.map((item)=>{
            return(<tr className="row">
                <td className="table-label">{item.title}</td>
            </tr>)
        });

        let table = analysis.map((item)=>{
            let list = item.value.map((v)=>{
                return(
                    <Fragment>
                        <td className="table-value" colspan={v.result!=undefined?1:2}>{(v.value?func.round(v.value,1):0).toFixed(1)}</td>
                        {v.result!=undefined?<td className="table-value" colspan="1">{v.result+"%"}</td>:null}
                    </Fragment>
                )
            });

            return(<tr className="row">
                {list}
            </tr>)
        });

        return (
            <div className="table-container ui-primary ui-fix-tab bg-green text-default scroll-both">
                <div id="floating-table" className="table-col">
                    <table className="table">
                        <tr>
                            <th className="table-label">기수</th>
                        </tr>
                        {subject}
                    </table>
                </div>
                <div className="table-col">
                    <table className="table">
                        <tr>
                            {(()=>{
                                let list = [];
                                for(let i=1; i<this.props.period; i++){
                                    list.push( (()=>{return(<th className="table-period" colspan="2">{i}기</th>)})() );
                                }
                                return list;
                            })()}
                        </tr>
                        {table}
                    </table>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Table);