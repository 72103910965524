import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

/* Components */
import Speech from '../../../functions/Speech';
const speech = new Speech();

class SessionEnd extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount = () => {
    
    const this_ = this;
    this.props.voice_sound.play('턴을 되돌립니다.');

    // speech.text('턴을 되돌립니다.',this.props.utter);

    setTimeout(()=>{
        this_.props.changePage('session-start');
        this_.props.open_statusbox();
    }, 1000);
  }


  render() {
    return (
        <div id="page-return" className="ui-primary cover bg-green-dark text-white">
            <h3 className="abs-center">턴을 되돌립니다</h3>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SessionEnd);
