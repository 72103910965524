import Risks from "./game/Risks";
import Actions from "./game/Actions";
import Markets from "./game/Markets";
import TermProcessing from "./game/TermProcessing";
import PeriodEndList from "./settlement/PeriodEndList";
import SettlementList from "./settlement/SettlementList";
import TeamPerformance from "./performance/TeamPerformance";

class Game{
    constructor(){
        const game = {
            'actions': new Actions(),
            'option': [
                "Insurance",
                "Merchandiser",
                "PACProductivity",
                "MarketResearch",
                "BankBorrowing",
                "ArrangementConversion",
                "MachinerySale",
                "LoanWithdrawal",
                "Loan",
                "ProductOption",
                "bondBorrowing",
                "loanBorrowing"
            ],
            decision_card: [
                "Bidding",
                "PurchasingMaterials",
                "RecruitmentWorker",
                "MachineNormal",
                "MachinePart",
                "MachineLarge",
                "RecruitmentSalesman",
                "CompleteAndInput",
                "AdvertisingInvestment",
                "RND"
            ],
            lucky_card_1 : [
                "SalesmansFruition",
                "AdvertisingEffect"
            ],
            lucky_card_2: [
                "SpecialPurchase",
                "AdvertisingSpecialService",
                "ExclusiveSale"
            ],
            periodend_list: new PeriodEndList(),
            settlement_list: new SettlementList(),
            risk_card : new Risks(),
            actionGroup : ["decision_card","option","lucky_card_1","lucky_card_2"],
            markets : new Markets(),
            termProcessing : new TermProcessing(),
            team_performance: new TeamPerformance(),
            teams : [1,2,3,4]
        };
        return game;
    }
}

export default Game;