const sound_list = [
  "세일즈맨의 노력 결실!", // 0
  "제품판매",
  "광고효과가 나타남!",
  "응찰",
  "재료구입",
  "재료의 특별구입 가능!", // 5
  "광고의 특별써비스!",
  "워커채용",
  "보통기계",
  "제품의 독점판매 가능!",
  "부속품", // 10
  "재료의 특별구입",
  "대형기계",
  "응찰",
  "다른 마켓에서 판매",
  "세일즈맨채용", // 15
  "완성및투입",
  "판매 끝내기",
  "다른 마켓에서 구입",
  "투입",
  "구입 끝내기", // 20
  "완성",
  "광고투자",
  "서버연결에 실패했습니다. 네트워크를 다시 연결해주세요.",
  "연구개발",
  "턴을 되돌립니다.", // 25
  "보험",
  "턴을 종료합니다.",
  "머천다이저",
  "P A C 생산성",
  "마켓 리서치", // 30
  "은행 차 입",
  "배치전환",
  "기계 매각",
  "대여금 회수",
  "대여금",
  "특별차입", // 36
  "상품",
  "두 낫띵",
  "의사결정",
  "응찰",
  "특별 재료 구입",
  "기수 종료",
  "제주에서 재료를 구입하였습니다.",
  "광주에서 재료를 구입하였습니다.",
  "대전에서 재료를 구입하였습니다.",
  "대구에서 재료를 구입하였습니다.",
  "부산에서 재료를 구입하였습니다.",
  "서울에서 재료를 구입하였습니다.",
  "제주에서 제품을 판매하였습니다.",
  "광주에서 제품을 판매하였습니다.",
  "대전에서 제품을 판매하였습니다.",
  "대구에서 제품을 판매하였습니다.",
  "부산에서 제품을 판매하였습니다.",
  "서울에서 제품을 판매하였습니다.",
];

class VoiceSound{
    constructor(){
      this.list = sound_list;

      this.sound = {};
      for(let i=0; i<sound_list.length; i++){
        this.sound[sound_list[i]] = new Audio(process.env.PUBLIC_URL + "/voice/voice-"+i+".mp3");
      }
    }

    preload = () => {
        for(let prop in this.sound){
            this.sound[prop].load();
        }
    }

    play = (soundName) => {

      if(soundName.includes("기 종료"))soundName = "기수 종료";
      if(soundName.includes("두 나띵"))soundName = "두 낫띵";

      if(soundName == "턴을 종료합니다."){
        let _this = this;
        setTimeout(()=>{
          _this.playSound(soundName);

        },1000);
      }
      else this.playSound(soundName);
    }

    playSound = (soundName) => {
      if(this.list.includes(soundName)){
        // this.sound[soundName].pause();
        this.sound[soundName].currentTime = 0;
        this.sound[soundName].play();
      }
    }
}

export default VoiceSound;
