import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

/* Components */
import Button from '../parts/Button';

/* images */
import Server from '../../functions/Server';
import { statement } from '@babel/template';
const server = new Server();

class RankTable extends Component {

  state = {
    isPrinting:false
  }

  constructor(props){
    super(props);
  }

  componentDidMount(){
    
  }
  
  savePDF = () => {

    this.setState({isPrinting:true});

    const this_ = this;
    var pdf = new jsPDF('p', 'mm', 'a4');     

    window.html2canvas = html2canvas;
    const ele = document.querySelector('#tab-rank-table');
    ele.style.width = 1000+"px";
    document.querySelector('#print-title').style.display = "block";
    pdf.internal.scaleFactor = 10.75;

    
    pdf.addHTML(ele).then((canvas)=> {
      const pageWidth = 210 //캔버스 너비 mm
      const pageHeight = 295 //캔버스 높이 mm
      const padding = 20;
      let width = canvas.width; // 셀렉트한 요소의 px 너비
      let height = canvas.height; // 셀렉트한 요소의 px 높이
      let imgHeight = (pageWidth-padding*2) * height/width // 이미지 높이값 px to mm 변환

      let position = 0;
      let imgData = canvas.toDataURL('image/png');
      pdf.addImage(imgData, 'png', padding, position+padding, pageWidth-padding*2, imgHeight, undefined, 'slow');
      

      //Paging 처리
      let heightLeft = imgHeight //페이징 처리를 위해 남은 페이지 높이 세팅.
      heightLeft -= pageHeight
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight
        pdf.addPage();
        pdf.addImage(imgData, 'png', padding, position+padding, pageWidth-padding*2, imgHeight)
        heightLeft -= pageHeight
      }
      // pdf.output("dataurlnewwindow");
      document.querySelector('#print-title').style.display = "";
      ele.style.width = "";

      pdf.save(this_.props.group_name+"_전체순위표.pdf");

      setTimeout(() => {
        this_.setState({isPrinting:false});
      }, 1000);
      
    });
  }


  
  render() {
    let userlist = this.props.userlist;
    userlist = (()=>{
      let list = [];
      for(let prop in userlist){
        if(this.props.filtered_team === "all" || userlist[prop].team === this.props.filtered_team) list.push(userlist[prop]);
      }
      return list;
    })();

    let datalist = userlist.map(user => JSON.parse(user.data));

    datalist.sort(function(a, b) { 
        let value1 = a.status.equityCapital.value;
        let value2 = b.status.equityCapital.value;
        return value1 > value2 ? -1 : value1 < value2 ? 1 : 0;
    });

    let score;
    let rank = 1;
    const list = datalist.map( (data, i) => {
        const status = data.status;
        if(score === undefined) score = status.equityCapital.value;
        else if(score>status.equityCapital.value){
          score = status.equityCapital.value;
          rank++;
        }

        return (
            <tr>
                <td>{rank}</td>
                <td>{data.company}</td>
                <td>{data.name}</td>
                <td>{status.equityCapital.value}</td>
            </tr>
        )
    } );
    return (
      <Fragment>
        <Button id="btn-save-pdf" className="btn btn-green" onClick={this.savePDF}>PDF 저장</Button>
        <div id="tab-rank-table">
            <h3 id="print-title">{this.props.group_name} 전체순위표</h3>
            <table>
              <tr className="bg-green text-white">
                <th>순위</th>
                <th>회사명</th>
                <th>대표이사명</th>
                <th>자기자본</th>
              </tr>
              {list}
            </table>
        </div>
        {this.state.isPrinting&&<div id="print-blind"><div className="inner">PDF 저장중</div></div>}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(RankTable);
