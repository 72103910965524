import React, { Component } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';

/* images */
import Button from './parts/Button';
import Function from '../../functions/Function';
const func = new Function();

class Setting extends Component {

  constructor(props){
    super(props);
  }

  logout = () => {
    this.props.changeMode('login');
    this.props.changePage('login');
    this.props.close_setting();
    func.removeCookie("email");
    this.props.sound.play("turnend");
  }

  render() {
    const is_open_setting = this.props.is_open_setting;
    return (
      <div id="setting" className="ui-primary ui-fix-tab bg-green-dark text-green">
          <div className="fix">
              <div className="fix-inner">
              <div className="item-container">
                    <div className="item"><Button onClick={this.logout} speech="로그아웃">로그아웃</Button></div>
                </div>
              </div>
          </div>
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Setting);
