class Actions{
    constructor(){
        const actions =  [
            {
                name: "Bidding",
                title: "제품판매",
                actionTitle: "입찰",
                active: true,
                rule: [{
                    status:"product",
                    type:"bidding",
                    market:true,
                    min:1
                }]
            },
            {
                name: "SellingProducts",
                title: "제품판매",
                active: true,
                rule: [{
                    status:"product",
                    type:"sell",
                    min:1,
                    recordType:"G"
                }]
            },
            {
                name: "SellingProductsResponse",
                title: "응찰",
                active: true,
                rule: [{
                    status:"product",
                    type:"sell",
                    market:true,
                    min:1
                }]
            },
            {
                name: "PurchasingMaterials",
                title: "재료구입",
                active: true,
                rule: [{
                    status:"material",
                    type:"buy",
                    market:true,
                    min:1,
                    recordType:"N"
                }]
            },
            {
                name: "RecruitmentWorker",
                title: "워커채용",
                active: true,
                rule: [{
                    status:"worker",
                    type:"buy",
                    min:1,
                    recordType:"Q"
                }]
            },
            {
                name: "MachineNormal",
                title: "보통기계",
                active: true,
                rule: [{
                    status:"machineNormal",
                    type:"buy",
                    min:1,
                    recordType:"I"
                }]
            },
            {
                name: "MachinePart",
                title: "부속품",
                active: true,
                rule: [{
                    status:"machinePart",
                    type:"related_buy",
                    related:"machineNormal",
                    min:1,
                    recordType:"I"
                }]
            },
            {
                name: "MachineLarge",
                title: "대형기계",
                active: true,
                rule: [{
                    status:"machineLarge",
                    type:"buy",
                    min:1,
                    recordType:"I"
                }]
            },
            {
                name: "RecruitmentSalesman",
                title: "세일즈맨채용",
                active: true,
                rule: [{
                    status:"salesman",
                    type:"buy",
                    min:1,
                    recordType:"Q"
                }]
            },
            {
                name: "CompleteAndInput",
                title: "완성및투입",
                active: true,
                rule: [
                    {
                        title:"투입",
                        status:"workInProcess",
                        type:"convert",
                        related:"material",
                        min:0,
                        recordType:"L"
                    },
                    {
                        title:"완성",
                        status:"product",
                        type:"convert",
                        related:"workInProcess",
                        min:0,
                        recordType:"M"
                    }
                ]
            },
            {
                name: "AdvertisingInvestment",
                title: "광고투자",
                active: true,
                rule:[{
                    status:"advertising",
                    type:"buy",
                    min:1,
                    recordType:"P"
                }]
            },
            {
                name: "RND",
                title: "연구개발",
                active: true,
                rule:[{
                    status:"RND",
                    type:"buy",
                    max:1,
                    min:1,
                    recordType:"R"
                }]
            },
            {
                name: "Insurance",
                title: "보험",
                speech: "보험",
                active: true,
                rule: [{
                    status:"insurance",
                    type:"buy",
                    limit:1,
                    min:1,
                    recordType:"Q"
                }]
            },
            {
                name: "Merchandiser",
                title: "머천다이저",
                speech: "머천다이저",
                active: true,
                rule: [{
                    status:"merchandiser",
                    type:"buy",
                    limit:1,
                    min:1,
                    recordType:"Q"
                }]
            },
            {
                name: "PACProductivity",
                title: "PAC생산성",
                speech: "P A C 생산성",
                period: 3,
                active: true,
                rule: [{
                    status:"PAC",
                    type:"buy",
                    limit:1,
                    min:1,
                    recordType:"K"
                }]
            },
            {
                name: "MarketResearch",
                title: "마켓리서치",
                speech: "마켓 리서치",
                period: 3,
                active: true,
                rule: [{
                    status:"marketResearch",
                    type:"buy",
                    limit:1,
                    min:1,
                    recordType:"P"
                }]
            },
            {
                name: "BankBorrowing",
                title: "은행차입",
                speech: "은행 차 입",
                active: true,
                rule: [{
                    status:"borrowed",
                    type:"borrow",
                    min:1,
                    recordType:"E"
                }]
            },
            {
                name: "ArrangementConversion",
                title: "배치전환",
                speech: "배치전환",
                active: true,
                rule: [
                    {
                        title:"워커 > 세일즈맨",
                        status:"salesman",
                        type:"convert",
                        related:"worker",
                        min:0,
                        recordType:"Q"

                    },
                    {
                        title:"세일즈맨 > 워커",
                        status:"worker",
                        type:"convert",
                        related:"salesman",
                        min:0,
                        recordType:"Q"
                    }
                ]
            },
            {
                name: "MachinerySale",
                title: "기계 매각",
                speech: "기계 매각",
                period: 3,
                active: true,
                rule:[
                    {
                        status:"machineNormal",
                        type:"sell",
                        price:5.0,
                        min:0,
                        recordType:"B"
                    },
                    {
                        status:"machinePart",
                        type:"sell",
                        price:1.0,
                        min:0,
                        recordType:"B"
                    },
                    {
                        status:"machineLarge",
                        type:"sell",
                        price:10.0,
                        min:0,
                        recordType:"B"
                    }
                ]
            },
            {
                name: "LoanWithdrawal",
                title: "대여금회수",
                speech: "대여금 회수",
                active: true,
                period: 3,
                rule: [{
                    status:"cashBalance",
                    type:"loanWithdrawal",
                    price:1.0,
                    min:1,
                    recordType:"A"
                }]
            },
            {
                name: "Loan",
                title: "대여금",
                speech: "대여금",
                active: true,
                period: 3,
                rule: [{
                    status:"loanLended",
                    type:"loan",
                    min:1,
                    recordType:"H"
                }]
            },
            {
                name: "bondBorrowing",
                title: "특별차입(사채)",
                speech: "특별차입",
                active: true,
                period: 3,
                rule: [{
                    status:"bond",
                    type:"specialBorrow",
                    min:0,
                    max:60,
                    recordType:"E"
                }]
            },
            {
                name: "loanBorrowing",
                title: "특별차입(대여금)",
                speech: "특별차입",
                active: true,
                period: 3,
                rule: [{
                    status:"loanBorrowed",
                    type:"specialBorrow",
                    min:0,
                    max:60,
                    recordType:"E"
                }]
            },
            {
                name: "ProductOption",
                title: "상품",
                speech: "상품",
                active: false
            },
            {
                name: "SalesmansFruition",
                title: "세일즈맨의 노력 결실!",
                description:"제품의 특별가격으로 독점판매 가능. (세일즈맨 1인에 대하여 1개를 3.0으로 2개 까지 독점 판매 가능)",
                active: true,
                rule: [{
                    status:"product",
                    type:"sell",
                    related:"salesman",
                    relatedNum:2,
                    price:3.0,
                    min:1,
                    recordType:"G"
                }]
            },
            {
                name: "AdvertisingEffect",
                title: "광고효과가 나타남!",
                description:"광고를 투자한 기업은 광고칩 1개에 제품을 2개 까지 비어있는 시장에 독점 판매 가능)",
                active: true,
                rule: [{
                    status:"product",
                    type:"sell",
                    market:true,
                    related:"advertising",
                    relatedNum:2,
                    min:1,
                    recordType:"G"
                }]
            },
            {
                name: "SpecialPurchase",
                title: "재료의 특별구입 가능!",
                description:"1개 1.0으로 5개까지 구입 가능",
                active: true,
                rule:[{
                    status:"material",
                    type:"buy",
                    max:5,
                    min:1,
                    price:1.0,
                    recordType:"N"
                }]
            },
            {
                name: "AdvertisingSpecialService",
                title: "광고의 특별써비스!",
                description:"1개를 0.3의 가격으로(몇 개라도 좋음)",
                active: true,
                rule:[{
                    status:"advertising",
                    type:"buy",
                    min:1,
                    price:0.3,
                    recordType:"P"
                }]
            },
            {
                name: "ExclusiveSale",
                title: "제품의 독점판매 가능!",
                description:"연구개발칩이 있는 기업에 한하여, 1개에 3.2의 가격으로 독점판매 가능 (연구개발칩 1개에 대하여 2개 까지 판매가능 하나 판매능력의 범위 내에 한함)",
                active: true,
                rule: [{
                    status:"product",
                    type:"sell",
                    related:"RND",
                    relatedNum:2,
                    price:3.2,
                    min:0,
                    recordType:"G"
                }]
            },
            {
                name: "SpecialPurchaseAll",
                title: "재료의 특별구입",
                active: true,
                rule:[{
                    status:"material",
                    type:"buy",
                    max:3,
                    min:0,
                    price:1.2,
                    recordType:"N"
                }]
            },
            {
                name: "SpecialPurchaseOthers",
                title: "재료의 특별구입",
                active: true,
                rule:[{
                    status:"material",
                    type:"buy",
                    min:1,
                    price:1.2,
                    recordType:"N"
                }]
            },
            {
                name: "BiddingOthers",
                title: "응찰",
                active: true,
                rule: [{
                    status:"product",
                    type:"bidding",
                    market:true,
                    min:1
                }]
            },
            {
                name: "Complete",
                title: "완성",
                active: true,
                rule: [
                    {
                        title:"완성",
                        status:"product",
                        type:"convert",
                        related:"workInProcess",
                        min:1,
                        recordType:"M"
                    }
                ]
            },
            {
                name: "Input",
                title: "투입",
                active: true,
                rule: [
                    {
                        title:"투입",
                        status:"workInProcess",
                        type:"convert",
                        related:"material",
                        min:1,
                        recordType:"L"
                    }
                ]
            }
        ];

        return actions;
    }
}

export default Actions;