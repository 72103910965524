import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
/* functions */

class InputCheckbox extends Component {

  constructor(props){
    super(props);
  }

  onClick = () => {
    this.props.sound.play('button');
  }

  render() {
    return (
        <div className={'item ' + this.props.conClassName}>
          <input id={this.props.id} className={'input-checkbox ' + (this.props.className?this.props.className:null)} type="checkbox" ref={this.props.inputRef} onChange={this.props.onChange} checked={this.props.checked} onClick={this.onClick} />
          <label className="input-checkbox-container" htmlFor={this.props.id}>
            <div className="label">{this.props.children}</div>
            <div className="input-checkbox-icon"></div>
          </label>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(InputCheckbox);
