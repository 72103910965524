import React, { Component } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';

/* Components */
import NavBar from './NavBar';
import StatusBar from './StatusBar';
import Setting from './Setting';
import PerformanceBox from './PerformanceBox';
import ActivitiesBox from './ActivitiesBox';
import SettlementBox from './SettlementBox';
import LoginBox from './LoginBox';

/* images */
import icon_logo_original from '../../assets/icon/logo-original.png';
import icon_logo from '../../assets/icon/logo-small.svg';
import icon_status from '../../assets/icon/status.svg';

import icon_cards from '../../assets/icon/cards.svg';
import icon_cards_active from '../../assets/icon/cards-active.svg';


import icon_activities from '../../assets/icon/activities.svg';
import icon_activities_active from '../../assets/icon/activities-active.svg';
import icon_performance from '../../assets/icon/performance.svg';
import icon_performance_active from '../../assets/icon/performance-active.svg';
import icon_return from '../../assets/icon/return.svg';
import icon_return_active from '../../assets/icon/return-active.svg';
import icon_return_deactive from '../../assets/icon/return-deactive.svg';

import img_draw_card from '../../assets/images/draw-card.svg';
import img_draw_card_logo from '../../assets/images/draw-card-logo.svg';
import img_decision_card from '../../assets/images/decision-card.svg';
import img_risk_card from '../../assets/images/risk-card.svg';
import img_lucky_card from '../../assets/images/lucky-card.svg';

import img_chip_green from '../../assets/icon/chip-green.svg';
import img_chip_red from '../../assets/icon/chip-red.svg';
import img_chip_blue from '../../assets/icon/chip-blue.svg';
import img_chip_yellow from '../../assets/icon/chip-yellow.svg';
import img_chip_person from '../../assets/icon/chip-person.svg';
import img_chip_product from '../../assets/icon/chip-product.svg';
import img_chip_machine_large from '../../assets/icon/chip-machine-large.svg';
import img_chip_machine_normal from '../../assets/icon/chip-machine-normal.svg';
import img_chip_machine_part from '../../assets/icon/chip-machine-part.svg';

import img_board from '../../assets/images/status-board.svg';

import Server from '../../functions/Server';

const server = new Server();

class UserView extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){
    this.imagePreload();
    this.props.sound.preload();

    // 결산 테스트
    // const this_ = this;

    // server.getUserdata("g@g", function(data){
    //   this_.props.update(data, false);
    //   this_.props.setTabList(this_.props.game.periodend_list);
    //   this_.props.changeMode('settlement');
    //   this_.props.changeTab('settlement');
    // });
  }

  imagePreload = () => {
    const imageList = [
      icon_logo, 
      icon_status, 
      icon_cards,
      icon_cards_active,
      icon_activities,
      icon_activities_active,
      icon_performance,
      icon_performance_active,
      icon_return,
      icon_return_active,
      icon_return_deactive,
      img_draw_card,
      img_draw_card_logo,
      img_decision_card,
      img_risk_card,
      img_lucky_card,
      img_chip_green,
      img_chip_red,
      img_chip_blue,
      img_chip_yellow,
      img_chip_person,
      img_chip_product,
      img_chip_machine_large,
      img_chip_machine_normal,
      img_chip_machine_part,
      img_board
    ]
    imageList.forEach((image) => {
        new Image().src = image
    });
  }

  render() {
    const mode = this.props.mode;
    const is_open_setting = this.props.is_open_setting;
  //  console.log(is_open_setting);
    return (
      <div id="user-view">
        {mode=='login'&&<LoginBox></LoginBox>}
        {mode=='activities'&&<ActivitiesBox></ActivitiesBox>}
        {mode=='settlement'&&<SettlementBox></SettlementBox>}
        {(mode=="performance")&&<PerformanceBox></PerformanceBox>}
        {is_open_setting&&<Setting></Setting>}
        {(mode=="login"||mode=="activities")&&<StatusBar></StatusBar>}
        <NavBar></NavBar>
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(UserView);
