class PeriodEndList{
    constructor(){
        const data = [
            {
                title:"기간비용정산",
                tabName:"settle-period-cost"
            },
            {
                title:"기말비용정산",
                tabName:"final-costs",
            },
            {
                title:"입출금집계",
                tabName:"total",
            },
            {
                title:"제조고정비",
                tabName:"fixed-cost",
            },
            {
                title:"사고재해메모",
                tabName:"accidental-disaster",
            },
            {
                title:"원가방식선택",
                tabName:"cost-method",
            },
            {
                title:"결산",
                tabName:"settlement",
            },
            {
                title:"이익금처분",
                tabName:"disposal-profits"
            },
        ];

        return data;
    }
}

export default PeriodEndList;