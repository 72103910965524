import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import Function from "../../../functions/Function";

/* images */

/* Components */
import Button from '../parts/Button';
import InputCheckbox from '../parts/InputCheckbox';

/* functions */
import Server from '../../../functions/Server';
const server = new Server();

const { updateIn } = require('immutable');
const func = new Function();

class CostMethod extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){}

  confirm = (e) => {
    e.preventDefault();
    this.props.nextTab();
  }

  directCost = (e) => {
    this.props.updateSettlementPart({
        directCost:true
    });
    e.currentTarget.checked = true;
    this.allCostInput.checked = false;
    this.props.autoSettlement();
  }

  allCost = (e) => {
    this.props.updateSettlementPart({
        directCost:false
    });
    e.currentTarget.checked = true;
    this.directCostInput.checked = false;
    this.props.autoSettlement();
  }

  render() {
    const settlementData = this.props.settlementData;
    const directCost = settlementData.directCost;

    return (
        <div className="ui-primary ui-fix-tab text-black scroll-ver">
            <div className="fix">
                <div className="fix-inner">
                    <h3>원가방식선택</h3>
                    <div className="content-body">
                        <div className="row">
                            <InputCheckbox id="checkbox-directcost" inputRef={el => this.directCostInput = el} onChange={this.directCost} checked={directCost}>직접원가</InputCheckbox>
                            <InputCheckbox id="checkbox-allcost" inputRef={el => this.allCostInput = el} onChange={this.allCost} checked={!directCost}>전부원가</InputCheckbox>
                        </div>
                    </div>
                    <div className="confirm-container text-green row">
                        <div className="item col col-100"><Button onClick={this.confirm} soundplay="turnend">확인</Button></div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(CostMethod);
