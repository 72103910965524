import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import Sound from '../../../functions/Sound';
import Server from '../../../functions/Server';
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import InputSelect from '../parts/InputSelect';
import Button from '../parts/Button';

const server = new Server();

class SelectTeam extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){
    const this_ = this;
  }

  changeMode = (e) => {
    this.props.changeMode(e.currentTarget.getAttribute('data-val'));
  }

  confirm = (e) => {
    e.preventDefault();
    
    const user = this.props.user;
    let team = parseInt(this.input_team.value);
    
    const this_ = this;

    this.props.sound.play('turnend');
    this.props.selectTeam(team);
  }



  render() {
    let teams = [];
    for(let i=1; i<=this.props.team_number; i++){
      teams.push({
        label:i+"조",
        value:i
      });
    }
    return (
        <div id="page-selectteam" className="ui-card ver-center bg-white text-green">
          <h3>조 선택</h3>
          <div className="content-body">
            <div className="item-container">
            <InputSelect options={teams} inputRef={el => this.input_team = el}></InputSelect>
            </div>
          </div>
          <div className="confirm-container"><Button onClick={this.confirm}>확인</Button></div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SelectTeam);
