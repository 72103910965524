const mapStateToProps = (state) => {
    return {
        state: state,

        view: state.view,
        mode: state.mode,

        pagePrev: state.pagePrev,
        page: state.page,
        pageNext: state.pageNext,

        tab: state.tab,
        subtab: state.subtab,
        current_tab_list : state.current_tab_list,

        actionType: state.actionType,
        replay:state.replay,
        tempData: state.tempData,
        risk_started: state.risk_started,
        pprev_card: state.pprev_card,
        prev_card: state.prev_card,
        risk_count:state.risk_count,

        return: state.return,
        is_open_statusbox: state.is_open_statusbox,
        is_open_setting: state.is_open_setting,
        
        user: state.user,
        status: state.user.status,
        changedCash: state.changedCash,
        history: state.user.history,
        historyIndex: state.user.historyIndex,
        basic_process_complete: state.user.basic_process_complete,
        
        period: state.user.period,
        session_count : state.user.session_count,
        game: state.game,

        settlementData: state.settlementData,

        group_name: state.group_name,
        team_number: state.team_number,
        logo_dataUrl: state.logo_dataUrl,
        admin: state.admin,
        userlist: state.admin.userlist,
        simplify_userdata:state.admin.simplify_userdata,
        filtered_team: state.admin.filtered_team,
        filtered_period: state.admin.filtered_period,

        last_click_time: state.last_click_time,

        voice_sound: state.voice_sound,
        sound: state.sound,
        utter: state.utter
    }
}
    
const mapDispatchToProps = dispatch => {
    return {
        changeView: (value) => {
            dispatch({type:'CHANGE_VIEW', value})
        },
        changeMode: (value) => {
            dispatch({type:'CHANGE_MODE', value})
        },
        changePage: (value, savePrev=true) => {
            dispatch({type:'CHANGE_PAGE', value, savePrev})
        },
        changeTab: (value) => {
          dispatch({type:'CHANGE_TAB', value})
        },
        changeSubTab: (value) => {
          dispatch({type:'CHANGE_SUBTAB', value})
        },
        toggle_statusbox: () => {
            dispatch({type:'TOGGLE_STATUSBOX'})
        },
        open_statusbox: () => {
          dispatch({type:'OPEN_STATUSBOX'})
        },
        close_statusbox: () => {
          dispatch({type:'CLOSE_STATUSBOX'})
        },
        toggle_setting: () => {
          dispatch({type:'TOGGLE_SETTING'})
        },
        close_setting: () => {
          dispatch({type:'CLOSE_SETTING'})
        },
        selectTeam: (value) => {
          dispatch({type:'SELECT_TEAM', value})
        },
        signup: (email,name,company) => {
          dispatch({type:'SIGNUP',email,name,company})
        },
        setEmail: (value) => {
            dispatch({type:'SET_EMAIL', value})
        },
        back: () => {
          dispatch({type:'BACK'})
        },
        update: (value, changed = true) => {
          dispatch({type:'UPDATE', value, changed})
        },
        updateSettlement: (value) => {
          dispatch({type:'UPDATE_SETTLEMENT', value})
        },
        updateSettlementPart: (value) => {
          dispatch({type:'UPDATE_SETTLEMENT_PART', value})
        },
        save: (autoSave = false) => {
          dispatch({type:'SAVE', autoSave})
        },
        setPageNext: (value) => {
          dispatch({type:'SET_PAGE_NEXT', value})
        },
        setActionType: (value) => {
          dispatch({type:'SET_ACTIONTYPE', value})
        },
        setActive: () => {
          dispatch({type:'SET_ACTIVE'})
        },
        setReplay: (value) => {
            dispatch({type:'SET_REPLAY', value})
        },
        setTempData: (value) => {
          dispatch({type:'SET_TEMPDATA', value})
        },
        activateReturn: () => {
          dispatch({type:'ACTIVATE_RETURN'})
        },
        deactivateReturn: () => {
          dispatch({type:'DEACTIVATE_RETURN'})
        },
        autoSettlement: () => {
          dispatch({type:'AUTO_SETTLEMENT'})
        },
        setTabList: (value) => {
          dispatch({type:'SET_TAB_LIST',value})
        },
        nextTab: () => {
          dispatch({type:'NEXT_TAB'})
        },
        nextSession: () => {
          dispatch({type:'NEXT_SESSION'})
        },
        nextPeriod: () => {
          dispatch({type:'NEXT_PERIOD'})
        },
        setPeriod: (value) => {
          dispatch({type:'SET_PERIOD', value})
        },
        getRiskIndex: () => {
          dispatch({type:'GET_RISK'})
        },
        setRiskIndex: (value) => {
          dispatch({type:'SET_RISK_INDEX', value})
        },
        processRisk: (value) => {
          dispatch({type:'PROCESS_RISK', value})
        },
        setDrawingCard: (value) => {
          dispatch({type:'SET_DRAWING_CARD', value})
        },
        riskStart: () => {
          dispatch({type:'RISK_START'})
        },
        riskEnd: () => {
          dispatch({type:'RISK_END'})
        },
        setPrevCard: (value) => {
          dispatch({type:'SET_PREV_CARD', value})
        },
        setRiskCount: (value) => {
          dispatch({type:'SET_RISK_COUNT', value})
        },
        setUserList: (value) => {
          dispatch({type:'SET_USERLIST', value})
        },
        toggleSimplifyUserdata: () => {
          dispatch({type:'TOGGLE_SIMPLIFY_USERDATA'})
        },
        changeFilterTeam: (value) => {
          dispatch({type:'CHANGE_FILTER_TEAM', value})
        },
        changeFilterPeriod: (value) => {
          dispatch({type:'CHANGE_FILTER_PERIOD', value})
        },
        setGame: (group_name, team_number, logo_dataUrl) => {
          dispatch({type:'SET_GAME', group_name, team_number, logo_dataUrl})
        },
        setGroupName: (value) => {
          dispatch({type:'SET_GROUP_NAME', value})
        },
        setTeamNumber: (value) => {
          dispatch({type:'SET_TEAM_NUMBER', value})
        },
        setLogoDataUrl: (value) => {
          dispatch({type:'SET_LOGO_DATAURL', value})
        },
        setLastClickTime: (value) => {
          dispatch({type:'SET_LAST_CLICK_TIME', value})
        },
    }
}



export {mapStateToProps, mapDispatchToProps};