import 'babel-polyfill';
import 'whatwg-fetch';

class Server{
    login = (data, callback) => {
        fetch('/login',{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body:JSON.stringify(data)
        })
        .then(res => res.json())
        .then(result => {
            callback(result);
        //    console.log(result);
        });
    }

    autoLogin = (callback) => {
        fetch('/login',{
            method: 'GET',
            headers:{'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then(result => {
            callback(result);
        //    console.log(result);
        });
    }

    signup = (email,name,company,data) => {
        fetch('/signup',{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body:JSON.stringify({email,name,company,data})
        })
        .then(res => res.json())
        .then(result => {
        //    console.log(result);
        });
    }

    update = (email,data, callback = ()=>{}, fail = ()=>{}, autoSave = false ) => {
        fetch('/update',{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body:JSON.stringify({
                email:email,
                data:data,
                autoSave:autoSave
            })
        })
        .then(res => res.json())
        .then(result => {
        //    console.log("update",result)
            callback();
        }).catch(error => {
            fail();
            throw(error);
        });
    }

    select_team = (email,team,data) => {
        fetch('/select-team',{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body:JSON.stringify({email,team,data})
        })
        .then(res => res.json())
        .then(result => {
        //    console.log(result);
        });
    }

    new_game = (group_name,team_number,logo_dataUrl, callback = ()=>{}) => {
        fetch('/new-game',{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body:JSON.stringify({group_name,team_number,logo_dataUrl})
        })
        .then(res => res.json())
        .then(result => {
        //    console.log(result);
            callback();
        });
    }
    getGame = (callback = ()=>{}) => {
        fetch('/getGame')
        .then(res => res.json())
        .then(result => {
        //    console.log(result);
            callback(result.group_name,result.team_number,result.logo_dataUrl);
        });
    }
    getTeamNumber = (callback = ()=>{}) => {
        fetch('/getTeamNumber')
        .then(res => res.json())
        .then(result => {
        //    console.log(result);
            callback(result.team_number);
        });
    }
    getGroupName = (callback = ()=>{}) => {
        fetch('/getGroupName')
        .then(res => res.json())
        .then(result => {
        //    console.log(result);
            callback(result.group_name);
        });
    }
    getLogoDataUrl = (callback = ()=>{}) => {
        fetch('/getLogoDataUrl')
        .then(res => res.json())
        .then(result => {
        //    console.log(result);
            callback(result.logo_dataUrl);
        });
    }

    getUserdata = (email,callback) => {
        fetch('/getUserdata',{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body:JSON.stringify({email})
        })
        .then(res => res.json())
        .then(result => {
        //    console.log(result);
            callback(JSON.parse(result.userdata));
        });
    }

    getUserdataPrev = (email,callback) => {
        fetch('/getUserdataPrev',{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body:JSON.stringify({email})
        })
        .then(res => res.json())
        .then(result => {
        //    console.log(result);
            callback(JSON.parse(result.userdata));
        });
    }

    getUserList = (callback) => {
        fetch('/getUserList')
        .then(res => res.json())
        .then(result => {
        //    console.log(result);
            callback(result.userlist);
        });
    }

    getProductSum = (team, callback) => {
        fetch('/getProductSum',{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body:JSON.stringify({team})
        })
        .then(res => res.json())
        .then(result => {
        //    console.log(result);
            callback(result.list);
        });
    }

    removeUserTeam = (team,callback) => {
        fetch('/removeUserTeam',{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body:JSON.stringify({team})
        })
        .then(res => res.json())
        .then(result => {
        //    console.log(result);
            callback();
        });
    }

    removeUserAll = (callback) => {
        fetch('/removeUserAll')
        .then(res => res.json())
        .then(result => {
        //    console.log(result);
            callback();
        });
    }

    removeUser = (email,callback) => {
        fetch('/removeUser',{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body:JSON.stringify({email})
        })
        .then(res => res.json())
        .then(result => {
        //    console.log(result);
            callback();
        });
    }

    getAutoSaveListLength = (email,callback) => {
        fetch('/getAutoSaveListLength',{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body:JSON.stringify({email})
        })
        .then(res => res.json())
        .then(result => {
        //    console.log(result);
            callback(result.length);
        });
    }

    changeAutoSaveToData = (email,index,callback = () => {}) => {
        fetch('/changeAutoSaveToData',{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body:JSON.stringify({email,index})
        })
        .then(res => res.json())
        .then(result => {
        //    console.log(result);
            callback();
        });
    }
}
export default Server;
