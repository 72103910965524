class Analysis{
    constructor(){
        const data =  [
            {
                title:"판매단가",
                value:[]
            },
            {
                title:"변동단가",
                value:[]
            },
            {
                title:"단위당공헌이익",
                value:[]
            },
            {
                title:"매출개수",
                value:[]
            },
            {
                title:"매출액",
                value:[]
            },
            {
                title:"매출원가",
                value:[]
            },
            {
                title:"공헌이익",
                value:[]
            },
            {
                title:"고정비",
                value:[]
            },
            {
                title:"법인세비용차감전이익",
                value:[]
            },
            {
                title:"타인자본",
                value:[]
            },
            {
                title:"자기자본",
                value:[]
            },
            {
                title:"총자본",
                value:[]
            },
            {
                title:"매출액이익률",
                value:[]
            },
            {
                title:"총자산회전률",
                value:[]
            },
            {
                title:"총자산이익률",
                value:[]
            },
            {
                title:"당기평가",
                value:[]
            },
        ];

        return data;
    }
}

export default Analysis;