import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

/* Components */
import Speech from '../../../functions/Speech';
import Server from '../../../functions/Server';

const speech = new Speech();
const server = new Server();

class SessionEnd extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount = () => {
    const this_ = this;

    this.props.voice_sound.play('턴을 종료합니다.');
    //speech.text('턴을 종료합니다.',this.props.utter);

    setTimeout(()=>{
        this_.props.sound.play('turnend');
        this_.props.nextSession();
        
        server.update(this_.props.user.email, this_.props.user, 
        ()=>{
            this_.props.activateReturn();
            this_.props.open_statusbox();
            this_.props.changePage('session-start');
        }, 
        ()=>{
            this_.props.changePage('connect');
          //  console.log("전송실패");
        });
    }, 1000);
  }


  render() {
    const user = this.props.user;
    return (
        <div id="page-session-end" className="ui-primary cover bg-green-dark text-white">
            <h3 className="abs-center">턴을 종료합니다</h3>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SessionEnd);
