import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';

import StatusBox from './activities/StatusBox'; 
/* images */
 
/* Components */
import PeriodStart from './activities/PeriodStart'; 
import BasicProcess from './activities/BasicProcess'; 
import SessionStart from './activities/SessionStart'; 
import RuleB from './activities/RuleB'; 
import DrawCard from './activities/DrawCard'; 
import Card from './activities/Card'; 
import RiskCard from './activities/RiskCard'; 
import Action from './activities/Action'; 
import Replay from './activities/Replay'; 
import BiddingEnd from './activities/BiddingEnd'; 
import BackToBidding from './activities/BackToBidding'; 
import SessionEnd from './activities/SessionEnd'; 
import Return from './activities/Return'; 
import Connect from './activities/Connect'; 

class ActivitiesBox extends Component {

  constructor(props){
    super(props);
  }

  changeMode = (e) => {
    this.props.changeMode(e.currentTarget.getAttribute('data-val'));
  }

  render() {
    const is_open_statusbox = this.props.is_open_statusbox;
    const page = this.props.page;
    return (
        <Fragment>
            <div id="activities-box" className="content-box">
                {page==='period-start'&&<PeriodStart></PeriodStart>}
                {page==='basic-process'&&<BasicProcess></BasicProcess>}
                {page==='session-start'&&<SessionStart></SessionStart>}
                {page==='ruleb'&&<RuleB></RuleB>}
                {page==='draw-card'&&<DrawCard></DrawCard>}
                {(page==='decision_card'||page==='lucky_card_1'||page==='lucky_card_2')
                &&<Card></Card>}
                {(page==='risk_card')&&<RiskCard></RiskCard>}
                {(page==='action')&&<Action></Action>}
                {(page==='replay')&&<Replay></Replay>}
                {(page==='bidding-end')&&<BiddingEnd></BiddingEnd>}
                {(page==='backto-bidding')&&<BackToBidding></BackToBidding>}
                {page==='session-end'&&<SessionEnd></SessionEnd>}
                {page==='return'&&<Return></Return>}
                {page==='connect'&&<Connect></Connect>}
            </div>
            {is_open_statusbox&&<StatusBox></StatusBox>}
        </Fragment>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ActivitiesBox);
