import Function from "./Function";
import History from "../data/user/History";
import 'babel-polyfill';

const func = new Function();
const { updateIn } = require('immutable');

class SettlementFunc{

    getSettlement = (state) => {
        let newSettlementData = {};
        newSettlementData = Object.assign({},newSettlementData,this.returnOptions(state));
        newSettlementData = Object.assign({},newSettlementData,this.settlement(state));
        newSettlementData = Object.assign({},newSettlementData,{historyIndex:state.user.historyIndex, session_count:state.user.session_count});
        return newSettlementData;
    }

    returnOptions = (state) => {
        const status = state.user.status;
        const returnList = ['advertising', 'marketResearch', 'PAC', 'merchandiser', 'insurance', 'RND'];
        let returnOptions = [];
        for(let i=0; i<returnList.length; i++){
            
            let prop = returnList[i];
            if(prop === 'RND'){
                returnOptions.push({
                    name:status[prop].name,
                    value:Math.ceil(status[prop].value/2)
                });
            }
            else{
                returnOptions.push({
                    name:status[prop].name,
                    value:status[prop].value
                });
            }
        }

        return {returnOptions};
    }

    settlement = (state) => {

        let settlement = {};
        const user = state.user;
        const game = state.game;
        const history = user.history;
        const status = state.user.status;
        const termProcessing = game.termProcessing;
        const period = user.period - 1;
        const settlementData = state.settlementData;
        

        let chart = {};

        Object.keys(history).map(function(key){
            chart[key] = {};
            chart[key].title = history[key].title;
            if(history[key].value){
                chart[key].value = 0;
                for(var i=0; i<history[key].value.length; i++){
                    chart[key].value = func.numFix(chart[key].value + history[key].value[i]);

                }
            }
            if(history[key].number){
                chart[key].number = 0;
                for(var i=0; i<history[key].number.length; i++){
                    chart[key].number += history[key].number[i];
                }
            }
            if(history[key].carryOver){
                chart[key].carryOver = history[key].carryOver;
            }
        });


        // 기말 처리
            // 워커 급료
            let payWorker = func.numFix( status.worker.value * termProcessing.employeePay[period] + status.worker.retirement * termProcessing.severancePay[period] );
            chart["J"].value =  func.numFix( chart["J"].value + payWorker );

            chart["_payWorker"] = {};
            chart["_payWorker"].title = "워커급료";
            chart["_payWorker"].value = payWorker;

            // 기계광열비
            let machineUtilities = func.numFix( ( status.machineNormal.value  + status.machineLarge.value ) * termProcessing.machineUtilities[period] );
            chart["K"].value = func.numFix(chart["K"].value + machineUtilities);

            chart["_machineUtilities"] = {};
            chart["_machineUtilities"].title = "기계광열비";
            chart["_machineUtilities"].value = machineUtilities;

            // 세일즈맨 급료
            let paySalesman = func.numFix( status.salesman.value * termProcessing.employeePay[period] + status.salesman.retirement * termProcessing.severancePay[period] );
            chart["P"].value = func.numFix(chart["P"].value + paySalesman);

            chart["_paySalesman"] = {};
            chart["_paySalesman"].title = "세일즈맨급료";
            chart["_paySalesman"].value = paySalesman;

            // 전 종업원의 복리후생비
            let employeeBenefits = func.numFix( ( status.worker.value + status.salesman.value ) * termProcessing.employeeBenefits[period] );
            chart["Q"].value = func.numFix(chart["Q"].value + employeeBenefits);

            let paymentTotalBefore = func.numFix(payWorker + machineUtilities + paySalesman + employeeBenefits);

            chart["_employeeBenefits"] = {};
            chart["_employeeBenefits"].title = "복리후생비";
            chart["_employeeBenefits"].value = employeeBenefits;

            chart["_endProcess"] = [];
            chart["_endProcess"].push(chart["_payWorker"]);
            chart["_endProcess"].push(chart["_machineUtilities"]);
            chart["_endProcess"].push(chart["_paySalesman"]);
            chart["_endProcess"].push(chart["_employeeBenefits"]);


    
            let newChart = {
                ...chart
            };
    
            // 차입금 상환
            newChart["T"].value = func.numFix(newChart["T"].value
                + settlementData.endProcess.debtRepaymentAmount 
                + settlementData.endProcess.debtRepaymentAmountLoan
                + settlementData.endProcess.debtRepaymentAmountBond);

            // 기말처리
            let paymentTotal = 
            func.numFix(chart["_payWorker"].value 
                + chart["_machineUtilities"].value 
                + chart["_paySalesman"].value 
                + chart["_employeeBenefits"].value 
                + newChart["T"].value);
            
    
            newChart["_paymentTotal"] = {};
            newChart["_paymentTotal"].title = "계";
            newChart["_paymentTotal"].value = paymentTotal;
            
            // 지불합계 차감
            newChart["_finalCash"] = {
                title: "최종현금잔액",
                value: func.numFix(status.cashBalance.value - paymentTotal)
            };
    
    
            // 입금합계
            newChart["_totalDeposit"] = {
                title: "입금합계",
                value: 0
            };
            for(var i="A".charCodeAt(0); i<="G".charCodeAt(0);i++){
                newChart["_totalDeposit"].value = func.numFix( newChart["_totalDeposit"].value + chart[String.fromCharCode(i)].value );
            }
    
            // 출금합계
            newChart["_totalWithdrawal"] = {
                title: "출금합계",
                value: 0
            };
            for(var i="H".charCodeAt(0); i<="V".charCodeAt(0);i++){
                newChart["_totalWithdrawal"].value = func.numFix( newChart["_totalWithdrawal"].value + chart[String.fromCharCode(i)].value );
            }
    
            // 차기이월
            newChart["Y"] = {
                title: "차기이월",
                value: newChart["_finalCash"].value
            };
    
            // 감가삼각비
            let w = func.numFix(status.machineNormal.value * 1.0 + status.machinePart.value * 0.2 + status.machineLarge.value * 2.0 );
            newChart["W"] = {
                title: "감가상각비",
                value: func.numFix(w)
            };
            // 제조고정비계
            let x = func.numFix( newChart["W"].value + chart["J"].value + chart["K"].value );
            newChart["X"] = {
                title: "제조고정비계",
                value: func.numFix(x)
            };

        chart = {
            ...newChart
        };

        // A
        settlement["A"] = {};

        // 대여금
        settlement["A"][0] = {
            title : "대여금",
            items : {}
        };
        let A0 = func.numFix( chart["H"].carryOver.value ),
            A1 = func.numFix( chart["H"].value ),
            A2 = func.numFix( chart["A"].value ),
            A3 = func.numFix(A0 + A1 - A2);

        settlement["A"][0]["items"][0] = {
            title: "전기이월",
            value: A0
        }
        settlement["A"][0]["items"][1] = {
            title: "당기대여금",
            value: A1
        }
        settlement["A"][0]["items"][2] = {
            title: "대여금회수",
            value: A2
        }
        settlement["A"][0]["items"][3] = {
            title: "차기이월",
            value: A3
        }

        // 기계공구
        settlement["A"][1] = {
            title : "기계공구",
            items : {}
        };
            // 전기이월
        let A4 = func.numFix( chart["I"].carryOver.value ),
            A5 = func.numFix( chart["I"].value ),
            A6 = func.numFix( chart["W"].value ),
            //매각시 장부가 (확인필요)
            A7 = func.numFix( chart["_bookPrice"].value ),
            A8 = func.numFix( A4 + A5 - A6 - A7 );

        settlement["A"][1]["items"][0] = {
            title: "전기이월",
            value: A4
        }
        settlement["A"][1]["items"][1] = {
            title: "기계공구구입",
            value: A5
        }
        settlement["A"][1]["items"][2] = {
            title: "감가상각",
            value: A6
        }
        settlement["A"][1]["items"][3] = {
            title: "매각시장부가",
            value: A7
        }
        settlement["A"][1]["items"][4] = {
            title: "차기이월",
            value: A8
        }

        // 연구개발
        settlement["A"][2] = {
            title : "연구개발",
            items : {}
        };

            // 전기이월
        let A9 = {
                number: func.numFix( chart["R"].carryOver.number ),
                value:  func.numFix( chart["R"].carryOver.value )
            },
            // 당기발생 연구개발비
            A10 = {
                number: func.numFix( chart["R"].number ),
                value:  func.numFix( chart["R"].value )
            },
            // 차기이월 
            RND_number = Math.floor(status.RND.value/2),
            A12 = {
                number: func.numFix( RND_number ), 
                value:  func.numFix( RND_number*2 )
            },


            // 당기상각 연구개발비
            A11 = {
                number: func.numFix(A9.number + A10.number - A12.number),
                value:  func.numFix(A9.value + A10.value - A12.value)
            };

            

        settlement["A"][2]["items"][0] = {
            title: "전기이월",
            value: A9.value,
            number: A9.number
        }
        settlement["A"][2]["items"][1] = {
            title: "당기발생 연구개발비",
            value: A10.value,
            number: A10.number
        }
        settlement["A"][2]["items"][2] = {
            title: "당기상각 연구개발비",
            value: A11.value,
            number: A11.number
        }
        settlement["A"][2]["items"][3] = {
            title: "차기이월",
            value: A12.value,
            number: A12.number
        }

        // B
        settlement["B"] = {};

        // 재료
        settlement["B"][0] = {
            title : "재료",
            items : {}
        };

            // 전기이월
        let B0 = {
                number: func.numFix( chart["N"].carryOver.number),
                value:  func.numFix( chart["N"].carryOver.value)
            },
            // 당기재료매입
            B1 = {
                number: func.numFix( chart["N"].number ),
                value:  func.numFix( chart["N"].value )
            },

            // 합계
            SUM_material = {
                number: func.numFix( B0.number + B1.number ),
                value:  func.numFix( B0.value + B1.value )
            },
            Average_material = func.numFix(SUM_material.value/SUM_material.number),

            // 차기이월
            B4 = {
                number: func.numFix( status.material.value ), 
                value:  func.numFix( status.material.value*Average_material )
            },

            // 화재 -> D
            B3 = {
                number: func.numFix( status.fire.value ), 
                value:  func.numFix( status.fire.value*Average_material )
            },

            // 당기투입재료비
            B2_number = func.numFix(SUM_material.number - B4.number - B3.number),
            B2_value = B2_number>0?func.numFix(SUM_material.value - B4.value - B3.value):0,
            
            B2 = {
                number: B2_number,
                value:  B2_value
            };

            if(B2_number<=0) B4.value = func.numFix(SUM_material.value - B3.value);
            

        settlement["B"][0]["items"][0] = {
            title: "전기이월재료",
            number: B0.number,
            value: B0.value
        }
        settlement["B"][0]["items"][1] = {
            title: "당기재료매입",
            number: B1.number,
            value: B1.value
        }
        settlement["B"][0]["items"][2] = {
            title: "당기투입재료비",
            number: B2.number,
            value: B2.value
        }
        settlement["B"][0]["items"][3] = {
            title: "화재",
            number: B3.number,
            value: B3.value
        }
        settlement["B"][0]["items"][4] = {
            title: "차기이월",
            number: B4.number,
            value: B4.value
        }
        settlement["B"][0]["items"][5] = {
            title: "평균단가",
            value: Average_material
        }

        // 재공품
        settlement["B"][1] = {
            title : "재공품",
            items : {}
        };
        
        // B2
            // 전 이월
        let B5 = {
                number: func.numFix( chart["L"].carryOver.number ),
                value:  func.numFix( chart["L"].carryOver.value )
            },
            // 투입비
            B6 = func.numFix( chart["L"].value ),
            // 제조고정비
            B7 = !settlementData.directCost ? func.numFix( chart["X"].value ) : 0,

            // 합계금액
            SUM_workInProcess = {
                number:func.numFix(B2.number + B5.number),
                value:func.numFix(B2.value + B5.value + B6 + B7)
            },
            Average_workInProcess = func.numFix( SUM_workInProcess.value/SUM_workInProcess.number ),

            // 차기이월
            B10 = {
                number: func.numFix( status.workInProcess.value ), 
                value:  func.numFix( status.workInProcess.value*Average_workInProcess )
            },
            // 제조미스 -> D
            B9 = {
                number: func.numFix( status.mistake.value ), 
                value:  func.numFix( status.mistake.value*Average_workInProcess )
            },

            // 당기완성품 제조원가
            B8_number = func.numFix(SUM_workInProcess.number - B10.number - B9.number),
            B8_value = B8_number>0?func.numFix(SUM_workInProcess.value - B10.value - B9.value):0,

            B8 = {
                number:B8_number,
                value:B8_value,
            };
            
            if(B8_number<=0) B10.value = func.numFix( SUM_workInProcess.value - B9.value );

        settlement["B"][1]["items"][0] = {
            title: "전기이월재공품",
            number: B5.number,
            value: B5.value
        }
        settlement["B"][1]["items"][1] = {
            title: "투입비",
            value: B6
        }
        settlement["B"][1]["items"][2] = {
            title: "제조고정비",
            value: B7
        }
        settlement["B"][1]["items"][3] = {
            title: "당기투입재료비",
            number: B2.number,
            value: B2.value
        }
        settlement["B"][1]["items"][4] = {
            title: "당기완성품제조원가",
            number: B8.number,
            value: B8.value
        }
        settlement["B"][1]["items"][5] = {
            title: "제조미스",
            number: B9.number,
            value: B9.value
        }
        settlement["B"][1]["items"][6] = {
            title: "차기이월",
            number: B10.number,
            value: B10.value
        }
        settlement["B"][1]["items"][7] = {
            title: "평균단가",
            value: Average_workInProcess
        }

        


        // 제품
        settlement["B"][2] = {
            title : "제품",
            items : {}
        };
        // B3
            // 전기이월
        let B11 = {
                number: chart["M"].carryOver.number,
                value:  func.numFix( chart["M"].carryOver.value )
            },
            // 상품매입
            B12 = {
                number: chart["O"].number, 
                value:  func.numFix( chart["O"].value )
            },
            // 완성비
            B13 = func.numFix( chart["M"].value ),

            // 합계
            SUM_product = {
                number: func.numFix(B8.number + B11.number + B12.number),
                value:  func.numFix(B8.value + B11.value + B12.value + B13)
            },
            Average_product = func.numFix(SUM_product.value/SUM_product.number),

            // 차기이월
            B16 = {
                number: status.product.value, 
                value:  func.numFix( status.product.value*Average_product )
            },
            // 도난 -> D
            B15 = {
                number: status.theft.value, 
                value:  func.numFix( status.theft.value*Average_product )
            },

            // 매출원가

            B14_number = func.numFix(SUM_product.number - B16.number - B15.number),
            B14_value = B14_number>0?func.numFix(SUM_product.value - B16.value - B15.value):0,

            B14 = {
                number:B14_number,
                value: func.numFix( B14_value ),
            };

            if(B14_number<=0) B16.value = func.numFix(SUM_product.value - B15.value);
            

        settlement["B"][2]["items"][0] = {
            title: "전기이월제품",
            number: B11.number,
            value: B11.value
        }
        settlement["B"][2]["items"][1] = {
            title: "상품매입",
            number: B12.number,
            value: B12.value
        }
        settlement["B"][2]["items"][2] = {
            title: "완성비",
            value: B13
        }
        settlement["B"][2]["items"][3] = {
            title: "매출원가",
            number: B14.number,
            value: B14.value
        }
        settlement["B"][2]["items"][4] = {
            title: "도난",
            number: B15.number,
            value: B15.value
        }
        settlement["B"][2]["items"][5] = {
            title: "차기이월",
            number: B16.number,
            value: B16.value
        }
        settlement["B"][2]["items"][6] = {
            title: "평균단가",
            value: Average_product
        }

        // C
        settlement["C"] = {};

        // 차입금
        settlement["C"][0] = {
            title : "차입금",
            items : {}
        };

            // 전기이월
        let C0 = func.numFix( chart["E"].carryOver.value ),
            // 당기차입금
            C1 = func.numFix( chart["E"].value ),
            // 차입금상환
            C2 = func.numFix( chart["T"].value ),
            // 차기이월
            C3 = func.numFix(C0 + C1 - C2);

        settlement["C"][0]["items"][0] = {
            title: "전기이월",
            value: C0
        }
        settlement["C"][0]["items"][1] = {
            title: "당기차입금",
            value: C1
        }
        settlement["C"][0]["items"][2] = {
            title: "차입금상환",
            value: C2
        }
        settlement["C"][0]["items"][3] = {
            title: "차기이월",
            value: C3
        }

        // D
        settlement["D"] = {};

        // 특별손익
        settlement["D"][0] = {
            title : "특별손익",
            items : {}
        };

            // 매각시 장부가
        let D0 = func.numFix( chart["_bookPrice"].value ),
            // 매각액
            D1 = func.numFix( chart["B"].value ),
            // 매각시 특별손익 (매각시장부가 - 매각액)
            D2 = func.numFix( D0 - D1 ),
            // 화재 <- B
            D3 = B3.value,
            // 재조미스 <- B
            D4 = B9.value,
            // 도난 <- B
            D5 = B15.value,
            // 합계
            SUM_EPL = func.numFix( D2 + D3 + D4 + D5 ),
            // 수취보험금
            D6 = func.numFix( chart["D"].value ),
            // 특별손익 -> F
            D7 = func.numFix( SUM_EPL - D6 );

        settlement["D"][0]["items"][0] = {
            title: "매각시장부가",
            value: D0
        }
        settlement["D"][0]["items"][1] = {
            title: "매각액",
            value: D1
        }
        settlement["D"][0]["items"][2] = {
            title: "매각시 특별손익",
            value: D2
        }
        settlement["D"][0]["items"][3] = {
            title: "화재",
            value: D3
        }
        settlement["D"][0]["items"][4] = {
            title: "제조미스",
            value: D4
        }
        settlement["D"][0]["items"][5] = {
            title: "도난",
            value: D5
        }
        settlement["D"][0]["items"][6] = {
            title: "수취보험금",
            value: D6
        }
        settlement["D"][0]["items"][7] = {
            title: "특별손익",
            value: D7
        }

        // E
        settlement["E"] = {};

        // 손익계산서
        settlement["E"][0] = {
            title : "손익계산서(P/L)",
            items : {}
        };

        // 매출액
            // 매출개수 Q
        let E0 = func.numFix( chart["G"].number ),
            // 매출액 PQ
            E1 = func.numFix( chart["G"].value ),
            // 매출원가 (변동비) vPQ
            E2 = func.numFix( B14.value ),
            // 공헌이익 (매출 총이익) mPQ : PQ - vPQ
            E3 = func.numFix( E1 - E2 ),

            // 제조고정비
            E4 = settlementData.directCost ? func.numFix( chart["X"].value ) : 0,
            // 판매비
            E5 = func.numFix( chart["P"].value ),
            // 일반관리비
            E6 = func.numFix( chart["Q"].value ),
            // 연구개발비 (당기상각비)
            E7 = func.numFix( A11.value ),
            // 영업외 비용
            E8 = func.numFix( chart["S"].value ),
            // 영업외 수익
            E9 = func.numFix( chart["C"].value ),
            
            // 고정비 F : 제조고정비+판매비+일반관리비+연구개발비+영업외비용-영업외수익 (???)
            E10 = func.numFix(E4 + E5 + E6 + E7 + E8 - E9),

            // 법인세비용 차감전 이익 (경상이익) : mPQ - F
            E11 = func.numFix( E3 - E10 ),

            // v율 : 매출원가 / 매출액
            E12 = func.numFix( E2/E1 * 100 ),

            // m율 : 공헌이익 / 매출액
            E13 = func.numFix( E3/E1 * 100 ),

            // f율 : 고정비 / 매출액
            E14 = func.numFix( E10/E1  * 100 ),

            // g율 : 법인세비용차감전이익 / 매출액
            E15 = func.numFix( E11/E1 * 100 ),
 
            // B.E.P비율 : 고정비 / 공헌이익
            E16 = func.numFix( E10/E3 * 100 );


        settlement["E"][0]["items"][0] = {
            title: "매출개수",
            value: E0
        }
        settlement["E"][0]["items"][1] = {
            title: "매출액",
            value: E1
        }
        settlement["E"][0]["items"][2] = {
            title: "매출원가 (변동비)",
            value: E2
        }
        settlement["E"][0]["items"][3] = {
            title: "공헌이익 (매출 총이익)",
            value: E3
        }
        settlement["E"][0]["items"][4] = {
            title: "제조고정비",
            value: E4
        }
        settlement["E"][0]["items"][5] = {
            title: "판매비",
            value: E5
        }
        settlement["E"][0]["items"][6] = {
            title: "일반관리비",
            value: E6
        }
        settlement["E"][0]["items"][7] = {
            title: "연구개발비(당기상각비)",
            value: E7
        }
        settlement["E"][0]["items"][8] = {
            title: "영업외비용",
            value: E8
        }
        settlement["E"][0]["items"][9] = {
            title: "영업외수익",
            value: E9
        }
        settlement["E"][0]["items"][10] = {
            title: "고정비",
            value: E10
        }
        settlement["E"][0]["items"][11] = {
            title: "법인세비용차감전이익(경상이익)",
            value: E11
        }
        settlement["E"][0]["items"][12] = {
            title: "v율",
            percent: E12
        }
        settlement["E"][0]["items"][13] = {
            title: "m율",
            percent: E13
        }
        settlement["E"][0]["items"][14] = {
            title: "f율",
            percent: E14
        }
        settlement["E"][0]["items"][15] = {
            title: "g율",
            percent: E15
        }
        settlement["E"][0]["items"][16] = {
            title: "B.E.P비율",
            percent: E16
        }

        // F
        settlement["F"] = {};

        // 누적이익
        settlement["F"][0] = {
            title : "누적이익",
            items : {}
        };

            // 법인세비용 차감전 이익 (경상이익) <- E
        let F0 = E11,
            // 특별손실 <- D
            F1 = D7,
            // 납세전 당기이익 : 법인세비용 차감전 이익 - 특별손실
            F2 = func.numFix(F0 - F1),
            // 전기이월 이익
            F3 = func.numFix( chart["_profit"].carryOver.value ),
            // 누적이익
            F4 = func.numFix(F2 + F3);

            settlement["F"][0]["items"][0] = {
                title: "법인세비용 차감전 이익 (경상이익)",
                value: F0
            }
            settlement["F"][0]["items"][1] = {
                title: "특별손실",
                value: F1
            }
            settlement["F"][0]["items"][2] = {
                title: "납세전 당기이익",
                value: F2
            }
            settlement["F"][0]["items"][3] = {
                title: "전기이월 이익",
                value: F3
            }
            settlement["F"][0]["items"][4] = {
                title: "누적이익",
                value: F4
            }

        // G
        settlement["G"] = {};

        // 누적이익
        settlement["G"][0] = {
            title : "미처분이익",
            items : {}
        };

            // 납세전 당기이익 <- E
        let G0 = F2,
            // 납세 충당금 : 납세전 당기이익과 누적이익이 +일 경우는 납세한다 (???)
            // + 가 0이상인지 초과인지 확인
            G1 = func.numFix(( F2>0 && F4>0 ) ? ( F3>0 ? F2*0.5 : F4*0.5 ) : 0),
            // 납세후 당기이익
            G2 = func.numFix(G0 - G1);

            settlement["G"][0]["items"][0] = {
                title: "납세전 당기이익",
                value: G0
            }
            settlement["G"][0]["items"][1] = {
                title: "납세 충당금",
                value: G1
            }
            settlement["G"][0]["items"][2] = {
                title: "납세후 당기이익",
                value: G2
            }

        // I
        settlement["I"] = {};

        // 이익금처분
        settlement["I"][0] = {
            title : "이익금처분",
            items : {}
        };

            // 납세후 당기이익
        let I0 = G2,
            // 전기이월 이익
            I1 = func.numFix( chart["_profit"].carryOver.value ),
            // 당기 미처분 이익
            I2 = func.numFix(I0 + I1),
            // 배당
            I3 = 0.0,
            // 상여
            I4 = 0.0,
            // 차기이월이익
            I5 = func.numFix( I2 - I3 - I4 );

            settlement["I"][0]["items"][0] = {
                title: "납세후 당기이익",
                value: I0
            };
            settlement["I"][0]["items"][1] = {
                title: "전기이월 이익",
                value: I1
            };
            settlement["I"][0]["items"][2] = {
                title: "당기 미처분이익",
                value: I2
            };
            settlement["I"][0]["items"][3] = {
                title: "배당",
                value: I3
            };
            settlement["I"][0]["items"][4] = {
                title: "상여",
                value: I4
            };
            settlement["I"][0]["items"][5] = {
                title: "차기이월이익",
                value: I5
            };

        // J
        settlement["J"] = {};

        // 1개당
        settlement["J"][0] = {
            title : "1개당",
            items : {}
        };

            // P : 매출액 / 매출개수 <- E
        let J0 = func.numFix( E1/E0 ),
            // vP : 매출원가 / 매출개수 <- E
            J1 = func.numFix( E2/E0 ),
            // mP : P - vP
            J2 = func.numFix( J0 - J1 );

            settlement["J"][0]["items"][0] = {
                title: "P",
                value: J0
            }
            settlement["J"][0]["items"][1] = {
                title: "vP",
                value: J1
            }
            settlement["J"][0]["items"][2] = {
                title: "mP",
                value: J2
            }

        // H
        settlement["H"] = {};

        // 재무상태표 (B/S)
        settlement["H"][0] = {
            title : "재무상태표 (B/S)",
            items : {}
        };

            // 현금
        let H0 = func.numFix( chart["Y"].value ),
            // 대여금 <- A 대여금 차기이월
            H1 = A3,
            // 재료
            H2 = {number:B4.number, value:B4.value},
            // 재공품
            H3 = {number:B10.number, value:B10.value},
            // 제품
            H4 = {number:B16.number, value:B16.value},
            // 기계공구
            H5 = A8,
            // 연구개발
            H6 = {number:A12.number, value:A12.value},
            // 총자산
            H7 = func.numFix(H0 + H1 + H2.value + H3.value + H4.value + H5 + H6.value),

            // 납세충당금
            H8 = G1,
            // 차입금
            H9 = C3,
            // 자본금
            H10 = func.numFix(chart["F"].value + chart["F"].carryOver.value),
            // 당기미처분이익
            H11 = I2,
            // 납세후 당기이익
            H12 = G2,
            // 총자본
            H13 = func.numFix( H8 + H9 + H10 + H11 );


            settlement["H"][0]["items"][0] = {
                title: "현금",
                value: H0
            }
            settlement["H"][0]["items"][1] = {
                title: "대여금",
                value: H1
            }
            settlement["H"][0]["items"][2] = {
                title: "재료",
                number: H2.number,
                value: H2.value
            }
            settlement["H"][0]["items"][3] = {
                title: "재공품",
                number: H3.number,
                value: H3.value
            }
            settlement["H"][0]["items"][4] = {
                title: "제품",
                number: H4.number,
                value: H4.value
            }
            settlement["H"][0]["items"][5] = {
                title: "기계공구",
                value: H5
            }
            settlement["H"][0]["items"][6] = {
                title: "연구개발",
                number: H6.number,
                value: H6.value
            }
            settlement["H"][0]["items"][7] = {
                title: "총자산",
                value: H7
            }

            settlement["H"][0]["items"][8] = {
                title: "납세충당금",
                value: H8
            }
            settlement["H"][0]["items"][9] = {
                title: "차입금",
                value: H9
            }
            settlement["H"][0]["items"][10] = {
                title: "자본금",
                value: H10
            }
            settlement["H"][0]["items"][11] = {
                title: "당기미처분이익",
                value: H11
            }
            settlement["H"][0]["items"][12] = {
                title: "납세후 당기이익",
                value: H12
            }
            settlement["H"][0]["items"][13] = {
                title: "총자본",
                value: H13
            }

            // 당좌자산 : 현금 + 대여금
        let H14 = func.numFix(H0 + H1),
            // 재고자산 : 재료 + 재공품 + 제품
            H15 = func.numFix(H2.value + H3.value + H4.value),
            // 유동자산 : 당좌자산 + 재고자산
            H16 = func.numFix(H14 + H15),
            // 유형자산 : 기계공구
            H17 = H5,
            // 무형자산 : 연구개발
            H18 = H6.value,
            // 비유동자산 : 유형자산 + 무형자산
            H19 = func.numFix(H17 + H18),

            // 유동부채
            H20 = H8,
            // 비유동부채
            H21 = H9,
            // 타인자본
            H22 = func.numFix(H20 + H21),
            // 자기자본
            H23 = func.numFix(H10 + H11);

            settlement["H"][0]["items"][14] = {
                title: "당좌자산",
                value: H14
            }
            settlement["H"][0]["items"][15] = {
                title: "재고자산",
                value: H15
            }
            settlement["H"][0]["items"][16] = {
                title: "유동자산",
                value: H16
            }
            settlement["H"][0]["items"][17] = {
                title: "유형자산",
                value: H17
            }
            settlement["H"][0]["items"][18] = {
                title: "무형자산",
                value: H18
            }
            settlement["H"][0]["items"][19] = {
                title: "비유동자산",
                value: H19
            }
            settlement["H"][0]["items"][20] = {
                title: "유동부채",
                value: H20
            }
            settlement["H"][0]["items"][21] = {
                title: "비유동부채",
                value: H21
            }
            settlement["H"][0]["items"][22] = {
                title: "타인자본",
                value: H22
            }
            settlement["H"][0]["items"][23] = {
                title: "자기자본",
                value: H23
            }
            
        return {
            chart:newChart,
            settlement:settlement,
            disposalProfits:{
                ...settlementData.disposalProfits,
                carryOver:settlement["I"][0]["items"][2].value
            },
            endProcess:{
                ...settlementData.endProcess,
                paymentTotal:newChart["_paymentTotal"].value,
                paymentTotalBefore
            }
        };
    }

    carryOver = (user, settlementData) => {
        const status = user.status;
        const settlement = settlementData.settlement;
        const chart = settlementData.chart;

        let machine = {};
        let machineName = ["machinePart", "machineNormal", "machineLarge"];

        for(var i=0; i<machineName.length; i++){
            machine[machineName[i]] = [];
            var possesion_ = status[machineName[i]].possesion;
            for(var j=0; j<possesion_.length; j++){
                machine[machineName[i]].push( possesion_[j] - status[machineName[i]].depreciation );
            }
        }

        let newUser = updateIn(
            user, 
            ['status', 'cashBalance', 'value'], 
            val => status.cashBalance.value
        );

        Object.keys(machine).map(function(key) {
            newUser = updateIn(
                newUser, 
                ['status', key, 'possesion'], 
                val => machine[key]
            );
        });

        // 기초처리 

            // 차입금 이자
            let interest = {value:0};
            
            let bank = func.numFix(status.borrowed.value -  settlementData.endProcess.debtRepaymentAmount);
            let loan = func.numFix(status.loanBorrowed.value -  settlementData.endProcess.debtRepaymentAmountLoan);
            let bond = func.numFix(status.bond.value -  settlementData.endProcess.debtRepaymentAmountBond);

            if(user.period<3)  interest.value = func.numFix( interest.value + (bank * 0.1) );
            else  interest.value = func.numFix( interest.value + (bank * 0.05) );

            let loan_intr = func.numFix(loan*status.loanBorrowed.interestRate);
            let bond_intr = func.numFix(bond*status.bond.interestRate);

            interest.value = func.numFix( interest.value + (loan_intr + bond_intr) );

            // 대여금 이자
            let loanLended_intr =  {value:func.numFix(status.loanLended.value * status.loanLended.interestRate)};

            // 배당,상여
            let allocation_bonus = 
            {value:func.numFix(settlementData.disposalProfits.allocation + settlementData.disposalProfits.bonus)};
            
            // 납세
            let taxPayment = {value:settlement["H"][0]["items"][8].value};
        
            // 차기 이월이익
            let profit = {value:func.numFix( settlement["I"][0]["items"][5].value - allocation_bonus.value )};

        let carryOver = {
            E : settlement["C"][0]["items"][3],
            F : settlement["H"][0]["items"][10],
            H : settlement["A"][0]["items"][3],
            I : settlement["A"][1]["items"][4],
            L : settlement["B"][1]["items"][6],
            M : settlement["B"][2]["items"][5],
            N : settlement["B"][0]["items"][4],
            R : settlement["A"][2]["items"][3],

            S : interest,
            C : loanLended_intr,
            U : allocation_bonus,
            V : taxPayment,

            _profit : profit,
            _PQ : settlement["E"][0]["items"][1]
        }
        Object.keys(machine).map(function(key) {
            newUser = updateIn(
                newUser, 
                ['history'], 
                val => new History(carryOver)
            );
            newUser = updateIn(
                newUser, 
                ['historyIndex'], 
                val => new History()
            );
        });

        let statusCarryOver = {};

        // 옵션 리셋
        statusCarryOver["advertising"] = 0;
        statusCarryOver["marketResearch"] = 0;
        statusCarryOver["PAC"] = 0;
        statusCarryOver["merchandiser"] = 0;
        statusCarryOver["insurance"] = 0;
        statusCarryOver["RND"] = Math.floor(status.RND.value/2);

        // 사고재해 리셋
        statusCarryOver["fire"] = 0;
        statusCarryOver["mistake"] = 0;
        statusCarryOver["theft"] = 0;

        // 매출 리셋
        statusCarryOver["sales"] = 0;
        statusCarryOver["salesNumber"] = 0;

        // 차입금 상환
        statusCarryOver["borrowed"] = bank;
        statusCarryOver["loanBorrowed"] = loan;
        statusCarryOver["bond"] = bond;

        // 현금잔액
        statusCarryOver["cashBalance"] = func.numFix(chart["Y"].value);

        // 자기자본
        statusCarryOver["equityCapital"] = settlement["H"][0]["items"][23].value;

        Object.keys(statusCarryOver).map(function(key) {
            newUser = updateIn(
                newUser, 
                ['status', key, "value"], 
                val => statusCarryOver[key]
            );
        });

        // 분석표

            // 1 P 판매단가

        let an = [];

            an[0] = { value: settlement["J"][0]["items"][0].value };

            // 2 vP 변동단가
            an[1] = { value: settlement["J"][0]["items"][1].value };

            // 3 mP 단위당 공헌이익
            an[2] = { value: settlement["J"][0]["items"][2].value };

            // 4 Q 매출개수
            an[3] = { value: settlement["E"][0]["items"][0].value,
                     result:0};

            // 5 PQ 매출액
            an[4] = { value: settlement["E"][0]["items"][1].value };

            // 6 vPQ 매출원가
            an[5] = { value: settlement["E"][0]["items"][2].value };
            an[5].result = func.numFix( an[5].value/an[4].value * 100 );

            // 7 mPQ 공헌이익
            an[6] = { value: settlement["E"][0]["items"][3].value };
            an[6].result = func.numFix(an[6].value/an[4].value * 100 );

            // 8 F 고정비
            an[7] = { value : settlement["E"][0]["items"][10].value };
            an[7].result = func.numFix(an[7].value/an[4].value * 100 );

            // 9 G 법인세비용차감전순이익
            an[8] = { value: settlement["E"][0]["items"][11].value };

            // 10 타인자본
            an[9] = { value: settlement["H"][0]["items"][22].value };

            // 11 자기자본
            an[10] = { value: settlement["H"][0]["items"][23].value };

            // 12 총자본
            an[11] = { value: func.numFix(an[9].value + an[10].value) };

            // 13 매출액이익률
            an[12] = { value: func.numFix(an[8].value/an[4].value * 100 )};

            // 14 총자산회전률
            an[13] = { value: func.numFix(an[4].value/an[11].value) };

            // 15 총자산이익률
            an[14] = { value: func.numFix(an[8].value/an[11].value * 100 ) };

            // 16 당기평가
            an[15] = { value: func.numFix(an[7].value/an[6].value * 100 ) };

            //
            // 퍼센트 계산
            //

            // 매출액 : 성장률
            an[4].result = func.numFix(an[4].value/chart["_PQ"].carryOver.value * 100 );

            // 타인자본
            an[9].result = func.numFix(an[9].value/an[11].value * 100 );

            // 자기자본
            an[10].result = func.numFix(an[10].value/an[11].value * 100 );

            // 총자본
            an[11].result = 100;

        for(let i=0; i<an.length; i++){
            newUser.analysis[i].value[user.period - 1] = an[i];
        }
        // 16 1 11 7

        // 그래프

        // 
        newUser.performance[4].value[user.period - 1] = an[15].value;
        newUser.performance[5].value[user.period - 1] = an[3].result;
        newUser.performance[6].value[user.period - 1] = an[10].value;
        newUser.performance[7].value[user.period - 1] = an[10].result;

        return newUser;
    }

    
}

export default SettlementFunc;