class TeamPerformance{
    constructor(){
        const data =  [
            {
                title:"경영분석표",
                type:"table",
                tabName:"analisis-table"
            },
            {
                title:"V율, m율, f율",
                type:"graph",
                tabName:"v-m-f",
                setting:{
                    min:0,
                    max:100,
                    gap:10,
                    sign:"%",
                    col:[
                        [
                            {source:"공헌이익",value:"result"},
                            {source:"매출원가",value:"result"},
                        ],
                        [
                            {source:"고정비",value:"result"}
                        ]
                    ]
                }
            },

            {
                title:"자본비율",
                type:"graph",
                tabName:"capital-ratio",
                setting:{
                    min:0,
                    max:100,
                    gap:10,
                    col:[
                        [
                            {source:"자기자본",title:"자기자본비율",value:"result"},
                            {source:"타인자본",title:"부채비율",value:"result"},
                        ]
                    ]
                }
            },

            {
                title:"총자산회전률",
                type:"graph",
                tabName:"total-capital-turnover",
                setting:{
                    min:0,
                    max:6,
                    gap:1,
                    col:[
                        [
                            {source:"총자산회전률",title:"회전률",value:"value"}
                        ]
                    ]
                }
            },

            {
                title:"매출액이익률",
                type:"graph",
                tabName:"sales-margin",
                setting:{
                    min:-10,
                    max:15,
                    gap:5,
                    col:[
                        [
                            {source:"매출액이익률",title:"매출액이익률",value:"value"}
                        ]
                    ]
                }
            },

            {
                title:"총자산이익률",
                type:"graph",
                tabName:"total-capital-return",
                setting:{
                    min:-10,
                    max:10,
                    gap:5,
                    col:[
                        [
                            {source:"총자산이익률",title:"총자산이익률",value:"value"}
                        ]
                    ]
                }
            },

            {
                title:"손익분기점비율",
                type:"graph",
                tabName:"break-even-point-rate",
                // setting:{
                //     min:0,
                //     max:400,
                //     datum_point:100,
                //     gap:100,
                //     col:[
                //         [
                //             {source:"당기평가",title:"손익분기점비율",value:"value"}
                //         ]
                //     ]
                // }

                setting:{
                    min:40,
                    max:120,
                    datum_point:100,
                    gap:10,
                    dir:"reverse",
                    col:[
                        [
                            {source:"당기평가",title:"손익분기점비율",value:"value"}
                        ]
                    ]
                }

            },
        ];

        return data;
    }
}

export default TeamPerformance;