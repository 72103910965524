import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import Function from "../../../functions/Function";

/* images */

/* Components */
import Button from '../parts/Button';
import DisplayValue from '../parts/DisplayValue';

/* functions */
import Server from '../../../functions/Server';
const server = new Server();

const { updateIn, get } = require('immutable');
const func = new Function();

class BasicProcess extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){
    if(!this.props.basic_process_complete) this.basicProcess();
  }

  confirm = (e) => {
    e.preventDefault();
    this.props.changePage('session-start');
    this.props.open_statusbox();
  }

  basicProcess = () => {
    console.log("================basic process");
    const user = this.props.user;
    const status = user.status;
    const history = user.history;

    const cashBalance = func.numFix( (status.cashBalance.value + history['C'].carryOver.value)
                        - (history['S'].carryOver.value
                        + history['U'].carryOver.value
                        + history['V'].carryOver.value) );

    let newUser = updateIn(
        user, 
        ['status', 'cashBalance', 'value'], 
        val => cashBalance
    );

    newUser = updateIn(
        newUser, 
        ['history', 'C', 'value'], 
        val => [...val, history['C'].carryOver.value]
    );

    newUser = updateIn(
        newUser, 
        ['history', 'S', 'value'], 
        val => [...val, history['S'].carryOver.value]
    );

    newUser = updateIn(
        newUser, 
        ['history', 'U', 'value'], 
        val => [...val, history['U'].carryOver.value]
    );

    newUser = updateIn(
        newUser, 
        ['history', 'V', 'value'], 
        val => [...val, history['V'].carryOver.value]
    );

    
    let hIndex1 = newUser.historyIndex['C'].value.concat();
    hIndex1[this.props.session_count] = history['C'].carryOver.value;

    let hIndex2  = newUser.historyIndex['S'].value.concat();
    hIndex2[this.props.session_count] = history['S'].carryOver.value;

    let hIndex3 = newUser.historyIndex['U'].value.concat();
    hIndex3[this.props.session_count] = history['U'].carryOver.value;

    let hIndex4 = newUser.historyIndex['V'].value.concat();
    hIndex4[this.props.session_count] = history['V'].carryOver.value;


    newUser = updateIn(
        newUser, 
        ['historyIndex', 'C', 'value'], 
        val => hIndex1
    );
    newUser = updateIn(
        newUser, 
        ['historyIndex', 'S', 'value'], 
        val => hIndex2
    );
    newUser = updateIn(
        newUser, 
        ['historyIndex', 'U', 'value'], 
        val => hIndex3
    );
    newUser = updateIn(
        newUser, 
        ['historyIndex', 'V', 'value'], 
        val => hIndex4
    );

    let rightPeriod = user.analysis[0].value.length+1;
    if(rightPeriod !== user.period){
        newUser = updateIn(
            newUser, 
            ['period'], 
            val => rightPeriod
        );

        let settlementData_history = user.settlementData_history;
        settlementData_history[rightPeriod-2] = get(this.props.state,"settlementData");

        newUser = updateIn(
            newUser, 
            ['settlementData_history'], 
            val => settlementData_history
        );
    }
    newUser = updateIn(
        newUser, 
        ['session_count'], 
        val => 1
    );
    newUser = updateIn(
        newUser, 
        ['basic_process_complete'], 
        val => true
    );

    this.props.update(newUser);
    this.props.save(true);
  }


  render() {
    const history = this.props.history;
    return (
        <div id="page-basic-process" className="ui-primary ui-fix-activities text-black scroll-ver">
            <div className="fix">
                <div className="fix-inner">
                    <h3>기초처리</h3>
                    <div className="content-body">
                        <div className="row">
                            <DisplayValue label='영업외비용' conClassName='item col col-50' value={history['S'].carryOver.value}></DisplayValue>
                            <DisplayValue label='영업외수익' conClassName='item col col-50' value={history['C'].carryOver.value}></DisplayValue>
                        </div>
                        <div className="row">
                            <DisplayValue label='배당,상여' conClassName='item col col-50' value={history['U'].carryOver.value}></DisplayValue>
                            <DisplayValue label='납세' conClassName='item col col-50' value={history['V'].carryOver.value}></DisplayValue>
                        </div>
                    </div>
                    <div className="confirm-container text-green row">
                        <div className="item col col-100"><Button onClick={this.confirm} soundplay="turnend">확인</Button></div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(BasicProcess);
