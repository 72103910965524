class Markets{
    constructor(name,volume, material_price, product_price){
        return {
            name:name,
            volume:volume,
            material_price:material_price, 
            product_price:product_price
        };
    }
}

export default Markets;