import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

/* images */
import logo_white from '../../../assets/icon/logo-white.svg';
/* Components */

class Intro extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){
    const this_ = this;
      if(document.readyState === "complete") this.fade();
      else window.onload = function() { this_.fade(); }

      // document.getElementById('page-intro').classList.add('hide');
      // document.getElementById('page-intro').style.display = "none";
      // this_.props.changePage('login');
  }

  fade = () => {
    const this_ = this;
    setTimeout(function(){
      const intro = document.getElementById('page-intro');
      intro.classList.add('hide');
        intro.addEventListener("transitionend", function(){
            this.style.display = "none";
        });
        this_.props.changePage('login');
    },2500);
  }


  render() {
    return (
        <div id="page-intro">
          <div className="inner">
            <img src={logo_white} />
            <div className="intro-text-small">SMART</div>
            <div className="intro-text-large">B_MASTERS</div>
          </div>
          <div className="intro-text-bottom">(주)위드플러스</div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Intro);
