import { createStore } from 'redux' 
import Data from '../data/Data';
import Function from "../functions/Function";
import SettlementFunc from "../functions/SettlementFunc";
import Server from "../functions/Server";
import 'babel-polyfill';
import Sound from '../functions/Sound';
import VoiceSound from '../functions/VoiceSound';


const func = new Function();
const settlementFunc = new SettlementFunc();
const server = new Server();
const { updateIn, get } = require('immutable');
const data = new Data();

class Store {

  constructor(){

    this.initialState = {
      admin:{
        filtered_team:1,
        filtered_period:1,
        userlist:[],
        simplify_userdata:false
      },
      user:{},
      game:data.getGame(),
      
      group_name:null,
      team_number:null,
      logo_dataUrl:null,

      view:'user',
      mode:'login',
      tab: '',
      subtab:'',
      current_tab_list : null,

      page:'intro',
      pagePrev:'',
      pageNext:'',
      risk_count:0,

      risk_started: false,

      settlementData: data.getSettlementData(),

      replay:{},
      actionType:null,
      tempData:null,
      is_open_statusbox:true,
      is_open_setting:false,
      return:false,
      risk_index: -1,
      prev_card: '',
      pprev_card: '',
      risk_cards: [],

      is_drawing_card : false,

      last_click_time : 0,

      voice_sound: new VoiceSound(),
      sound: new Sound(),
      utter: new SpeechSynthesisUtterance()
    };

    return createStore(this.reducer);
  }

  reducer = (state = this.initialState, action) => {
    let newState = {};
    // console.log("basicprocess complete : "+state.user.basic_process_complete);
    if(action.type === 'CHANGE_VIEW'){
      newState = Object.assign({},state,{view: action.value});
    }
    else if(action.type === 'CHANGE_MODE'){
      newState = Object.assign({},state,{mode: action.value});
    }
    else if(action.type === 'CHANGE_PAGE'){
      newState = Object.assign({},state,{page: action.value});
      if(action.savePrev)newState = Object.assign({}, newState,{pagePrev: state.page});
    }
    else if(action.type === 'CHANGE_TAB'){
      newState = Object.assign({},state,{tab: action.value});
    }
    else if(action.type === 'CHANGE_SUBTAB'){
      newState = Object.assign({},state,{subtab: action.value});
    }
    else if(action.type === 'CHANGE_FILTER_TEAM'){
      let newAdmin = Object.assign({},state.admin,{
        filtered_team:action.value
      });
      newState = Object.assign({},state,{admin:newAdmin});
    }
    else if(action.type === 'CHANGE_FILTER_PERIOD'){
      let newAdmin = Object.assign({},state.admin,{
        filtered_period:action.value
      });
      newState = Object.assign({},state,{admin:newAdmin});
    }
    else if(action.type === 'TOGGLE_STATUSBOX'){
      newState = Object.assign({},state,{is_open_statusbox:!state.is_open_statusbox});
    }
    else if(action.type === 'OPEN_STATUSBOX'){
      newState = Object.assign({},state,{is_open_statusbox:true});
    }
    else if(action.type === 'CLOSE_STATUSBOX'){
      newState = Object.assign({},state,{is_open_statusbox:false});
    }
    else if(action.type === 'TOGGLE_SETTING'){
      newState = Object.assign({},state,{is_open_setting:!state.is_open_setting});
    }
    else if(action.type === 'CLOSE_SETTING'){
      newState = Object.assign({},state,{is_open_setting:false});
    }
    else if(action.type === 'SIGNUP'){
      
      let newUser = Object.assign({},state.user,{
        ...data.getUser(),
        email:action.email,
        name:action.name,
        company:action.company,
      });
      
      server.signup(action.email, action.name, action.company, newUser);
      newState = Object.assign({},state,{page:'selectteam', user:newUser});
    }
    else if(action.type === 'SELECT_TEAM'){
      let newUser = Object.assign({},state.user,{
        team:action.value
      });

      newUser = this.basicCapital(newUser);
      
      server.select_team(state.user.email, action.value, newUser);
      
      newState = Object.assign({},state,{
        user:newUser,
        mode:'activities',
        page:'session-start',
        open_statusbox:true
      });
    }
    else if(action.type === 'SET_EMAIL'){
      let newUser = Object.assign({},state.user,{
        email:action.value
      });
      newState = Object.assign({},state,{user:newUser});
    }
    else if(action.type === 'UPDATE'){
        newState = Object.assign({},state,{user:action.value});
      //  console.log("UPDATE",newState);
        if(action.changed){
          const prevCash = state.user.status.cashBalance.value;
          const currentCash = newState.user.status.cashBalance.value;
          const changedCash = func.numFix(currentCash - prevCash);
          if(changedCash !== 0) newState = Object.assign({},newState,{changedCash:changedCash});
        }
    }
    else if(action.type === 'UPDATE_SETTLEMENT'){
      newState = Object.assign({},state,{settlementData:action.value});
    }
    else if(action.type === 'UPDATE_SETTLEMENT_PART'){
      let newSettlementData = Object.assign({},state.settlementData,action.value);
      newState = Object.assign({},state,{settlementData:newSettlementData});
    }
    else if(action.type === 'AUTO_SETTLEMENT'){
      let newSettlementData = Object.assign({},state.settlementData,settlementFunc.getSettlement(state));
      newState = Object.assign({},state,{settlementData:newSettlementData});
    }
    else if(action.type === 'NEXT_SESSION'){
      let newUser = Object.assign({},state.user,{
        session_count:state.user.session_count+1
      });
      newState = Object.assign({},state,{user:newUser});
    }
    else if(action.type === 'SAVE'){
      server.update(state.user.email, state.user, function(){}, function(){}, action.autoSave);
      newState = state;
    }
    else if(action.type === 'SET_DRAWING_CARD'){
      newState = Object.assign({},state,{is_drawing_card:action.value});
    }
    else if(action.type === 'RISK_START'){
      newState = Object.assign({},state,{risk_started:true});
    }
    else if(action.type === 'RISK_END'){
      newState = Object.assign({},state,{risk_started:false});
    }
    else if(action.type === 'GET_RISK'){
      let risk_cards = [...state.risk_cards];
      if(risk_cards.length == 0){
        for(let i=0; i<state.game.risk_card.length; i++){
          risk_cards.push(i);
        }
        risk_cards.sort(() => Math.random() - 0.5);
      }
      let risk_index = risk_cards.shift();

      // console.log("리스크 카드",risk_cards,risk_index);
      newState = Object.assign({},state,{risk_index,risk_cards});
    }
    else if(action.type === 'SET_ACTIVE'){
      const newGame = this.setActive(state);
      newState = Object.assign({},state,{game:newGame});
    }
    else if(action.type === 'SET_PAGE_NEXT'){
      newState = Object.assign({},state,{pageNext:action.value});
    }
    else if(action.type === 'SET_RISK_INDEX'){
      const index = action.value!=undefined?action.value:Math.floor(Math.random() * state.game.risk_card.length);
      newState = Object.assign({},state,{risk_index:index});
    }
    else if(action.type === 'SET_PREV_CARD'){
      newState = Object.assign({},state,{pprev_card:state.prev_card,prev_card:action.value});
    }
    else if(action.type === 'SET_RISK_COUNT'){
      newState = Object.assign({},state,{risk_count:action.value});
    }
    else if(action.type === 'SET_ACTIONTYPE'){
      newState = Object.assign({},state,{actionType:action.value});
    }
    else if(action.type === 'BACK'){
      newState = Object.assign({},state,{page:state.pagePrev});
    }
    else if(action.type === 'SET_TEMPDATA'){
    //  console.log("TEMP!",action.value);
      newState = Object.assign({},state,{tempData:action.value});
    }
    else if(action.type === 'SET_REPLAY'){
      newState = Object.assign({},state,{replay:action.value});
    }
    else if(action.type === 'PROCESS_RISK'){
      const newUser = this.processRisk(state,action.value);
      newState = Object.assign({},state,{user:newUser});
    }
    else if(action.type === 'ACTIVATE_RETURN'){
      newState = Object.assign({},state,{return:true});
    }
    else if(action.type === 'DEACTIVATE_RETURN'){
      newState = Object.assign({},state,{return:false});
    }
    else if(action.type === 'NEXT_PERIOD'){
      let settlementData_history = state.user.settlementData_history;
      settlementData_history[state.user.period-1] = get(state,"settlementData");

      let historyNum = settlementData_history.length;
      let period = state.user.period+1;

      if(historyNum + 1 !== period){
        //  console.log("period error");
          period = historyNum + 1;
      }

      let newUser = Object.assign({},state.user,{
        period:period,
        basic_process_complete:false,
        session_count:0,
        settlementData_history: settlementData_history
      });
      newState = Object.assign({},state,{user:newUser});
    }
    else if(action.type === 'SET_PERIOD'){
      let newUser = Object.assign({},state.user,{
        period:action.value
      });
      newState = Object.assign({},state,{user:newUser});
    }
    else if(action.type === 'SET_TAB_LIST'){
      newState = Object.assign({},state,{current_tab_list:action.value});
    }
    else if(action.type === 'NEXT_TAB'){
      const list = state.current_tab_list;
      const currentTab = list.filter(tab=>tab.tabName===state.tab)[0];
      const index = list.indexOf(currentTab);
      const newTab = list[index+1].tabName;
      newState = Object.assign({},state,{tab:newTab});
    }
    else if(action.type === 'SET_USERLIST'){
      let newAdmin = Object.assign({},state.admin,{
        userlist:action.value
      });
      newState = Object.assign({},state,{admin:newAdmin});
    }
    else if(action.type === 'TOGGLE_SIMPLIFY_USERDATA'){
      let newAdmin = Object.assign({},state.admin,{
        simplify_userdata:!state.admin.simplify_userdata
      });
      newState = Object.assign({},state,{admin:newAdmin});
    }
    else if(action.type === 'SET_GAME'){

      newState = Object.assign({},state,{
        group_name:action.group_name,
        team_number:action.team_number,
        logo_dataUrl:action.logo_dataUrl
      });
    }
    else if(action.type === 'SET_GROUP_NAME'){
      newState = Object.assign({},state,{group_name:action.value});
    }
    else if(action.type === 'SET_TEAM_NUMBER'){
      newState = Object.assign({},state,{team_number:action.value});
    }
    else if(action.type === 'SET_LOGO_DATAURL'){
      newState = Object.assign({},state,{logo_dataUrl:action.value});
    }
    else if(action.type === 'SET_LAST_CLICK_TIME'){
      newState = Object.assign({},state,{last_click_time:action.value});
    }
    else{
      newState = state;
    }

    if(newState.mode!='activities') newState = Object.assign({},newState,{is_open_statusbox:false});
    
  //  console.log(newState);
    if(action.type === 'UPDATE'){
      const status =  newState.user.status;
      let m = "";
      for(let prop in status){
        m += status[prop].name + ":" + status[prop].value + "  ";
      }
    //  console.log(m);

    }
    return newState;
  }

  basicCapital = (user) => {
    // 초기 자본금 지급
    let capital = 30;
    let newUser = updateIn(
        user, 
        ['history', 'F', 'value'], 
        val => [...val, capital]
    );

    let hIndex = newUser.historyIndex['F'].value.concat();
  //  console.log(hIndex);
    hIndex[user.session_count] = capital;
    newUser = updateIn(
        newUser, 
        ['historyIndex', 'F', 'value'], 
        val => hIndex
    );

    newUser = updateIn(
        newUser, 
        ['status', 'cashBalance', 'value'], 
        val => capital
    );

    newUser = updateIn(
        newUser, 
        ['status', 'equityCapital', 'value'], 
        val => capital
    );

    return newUser;
  }

  setActive = (state) => {
      const game = get(state, 'game');
      const user = state.user;
      const status = user.status;

      const actions = get(state.game, "actions");
      let newActions;

      // Decision Button Activate
      actions.map(
          (action,i) => {
              const rule = action.rule;
              let active = false;
              if(rule) {
                  action.rule.map(
                      (rule) => {
                        // 재료구입시 2 이하일 시 해당 action 버튼 비활성화 문제
                        if(rule.status=="material" && !rule.price && status.cashBalance.value>=1)active =true;
                        else active +=func.getMax(rule,status,user.period)>0?true:false;
                      }
                  );
              }

              if(action.period && action.period>user.period) active = false;
              
              if(!newActions) newActions = updateIn(actions,[i,"active"],val=>active);
              else newActions = updateIn(newActions,[i,"active"],val=>active);
          }
      );

      let newGame = updateIn(game,["actions"],val=>newActions);
      return newGame;
  }
}

export default Store;