import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';

import SettlePeriodCost from './periodend/SettlePeriodCost'; 
import FinalCosts from './periodend/FinalCosts'; 
import Total from './periodend/Total'; 
import FixedCost from './periodend/FixedCost'; 
import AccidentalDisaster from './periodend/AccidentalDisaster'; 
import CostMethod from './periodend/CostMethod'; 
import Settlement from './periodend/Settlement'; 
import DisposalProfits from './periodend/DisposalProfits'; 
/* images */
 
/* Components */
import Tab from './parts/Tab';

class SettlementBox extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const tab = this.props.tab;
    const periodend_list = this.props.game.periodend_list;
    return (
        <Fragment>
            <Tab list={this.props.current_tab_list}></Tab>
            <div id="settlement-box" className="content-box">
                {tab==='settle-period-cost'&&<SettlePeriodCost></SettlePeriodCost>}
                {tab==='final-costs'&&<FinalCosts></FinalCosts>}
                {tab==='total'&&<Total></Total>}
                {tab==='fixed-cost'&&<FixedCost></FixedCost>}
                {tab==='accidental-disaster'&&<AccidentalDisaster></AccidentalDisaster>}
                {tab==='cost-method'&&<CostMethod></CostMethod>}
                {tab==='settlement'&&<Settlement></Settlement>}
                {tab==='disposal-profits'&&<DisposalProfits></DisposalProfits>}
            </div>
        </Fragment>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SettlementBox);
