import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import Function from "../../../functions/Function";

/* images */

/* Components */
import Button from '../parts/Button';
import DisplayValue from '../parts/DisplayValue';

/* functions */
import Server from '../../../functions/Server';
const server = new Server();

const { updateIn } = require('immutable');
const func = new Function();

class FixedCost extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){}

  confirm = (e) => {
    e.preventDefault();
    this.props.nextTab();
  }

  render() {
    const chart = this.props.settlementData.chart;
    return (
        <div className="ui-primary ui-fix-tab text-black scroll-ver">
            <div className="fix">
                <div className="fix-inner">
                    <h3>제조고정비</h3>
                    <div className="content-body">
                        <div className="row">
                            <DisplayValue label={chart['W'].title} conClassName='item col col-50' value={chart['W'].value}></DisplayValue>
                            <DisplayValue label={chart['J'].title} conClassName='item col col-50' value={chart['J'].value}></DisplayValue>
                            <DisplayValue label={chart['K'].title} conClassName='item col col-50' value={chart['K'].value}></DisplayValue>
                            <DisplayValue label={chart['X'].title} conClassName='item col col-50' value={chart['X'].value}></DisplayValue>
                        </div>
                    </div>
                    <div className="confirm-container text-green row">
                        <div className="item col col-100"><Button onClick={this.confirm} soundplay="turnend">확인</Button></div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(FixedCost);
