import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import FlowTableValue from './FlowTableValue';


class FinancialStatement extends Component {
  constructor(props){
    super(props);
  }

  render() {
    const settlementData = this.props.data;
    const settlement = settlementData.settlement;
    
    return (
      <div id="tab-settlement" className="ui-primary ui-fix-subtab bg-green-dark text-black scroll-both">
        <div className="fix">
            <div className="fix-inner fix-inner-hor fix-inner-30">
              <div id="financial-statement" className="flowtable square">
                <div className="inner">
                  <div className="fill-inner">
                      <div className="flowtable-col col col-50">
                        <div className="flowtable-col col col-50">
                            <div className="flowtable-row row row-10">
                                <FlowTableValue data={settlement["H"][0]["items"][0]} displayMode="hor" drawMode="center" left="50%" top="50%"></FlowTableValue>
                            </div>
                            <div className="flowtable-row row row-10">
                                <FlowTableValue data={settlement["H"][0]["items"][1]} displayMode="hor" drawMode="center" left="50%" top="50%"></FlowTableValue>
                            </div>
                            <div className="flowtable-row row row-40">
                                <FlowTableValue data={settlement["H"][0]["items"][2]} displayMode="hor" drawMode="center" numberSign="개" left="50%" top="25%"></FlowTableValue>
                                <FlowTableValue data={settlement["H"][0]["items"][3]} displayMode="hor" drawMode="center" numberSign="개" left="50%" top="50%"></FlowTableValue>
                                <FlowTableValue data={settlement["H"][0]["items"][4]} displayMode="hor" drawMode="center" numberSign="개" left="50%" top="75%"></FlowTableValue>
                            </div>
                            <div className="flowtable-row row row-30">
                                <div className="flowtable-col col col-100">
                                    <div className="flowtable-row row row-50">
                                        <FlowTableValue data={settlement["H"][0]["items"][5]} displayMode="hor" drawMode="center" numberSign="개" left="50%" top="50%"></FlowTableValue>
                                    </div>
                                    <div className="flowtable-row row row-50">
                                        <FlowTableValue data={settlement["H"][0]["items"][6]} displayMode="hor" drawMode="center" numberSign="매" left="50%" top="50%"></FlowTableValue>
                                    </div>
                                </div>
                            </div>
                            
                                
                            <div className="flowtable-row row row-10">
                                <FlowTableValue data={settlement["H"][0]["items"][7]} select="value" drawMode="center" left="50%" top="50%"></FlowTableValue>
                            </div>
                        </div>
                        <div className="flowtable-col col col-50 flowtable-border-left">
                            <div className="flowtable-row row row-60">
                                <div className="flowtable-col col col-50">
                                    <div className="flowtable-row row row-33">
                                        <FlowTableValue data={settlement["H"][0]["items"][14]} drawMode="center" left="50%" top="50%"></FlowTableValue>
                                    </div>
                                    <div className="flowtable-row row row-66">
                                        <FlowTableValue data={settlement["H"][0]["items"][15]} drawMode="center" left="50%" top="50%"></FlowTableValue>
                                    </div>
                                </div>
                                <div className="flowtable-col col col-50 flowtable-border-left">
                                 <FlowTableValue data={settlement["H"][0]["items"][16]} drawMode="center" left="50%" top="50%"></FlowTableValue>
                                </div>
                            </div>
                            <div className="flowtable-row row row-30">
                                <div className="flowtable-col col col-50">
                                    <div className="flowtable-row row row-50">
                                     <FlowTableValue data={settlement["H"][0]["items"][17]} drawMode="center" left="50%" top="50%"></FlowTableValue>
                                    </div>
                                    <div className="flowtable-row row row-50">
                                        <FlowTableValue data={settlement["H"][0]["items"][18]} drawMode="center" left="50%" top="50%"></FlowTableValue>
                                    </div>
                                </div>
                                <div className="flowtable-col col col-50 flowtable-border-left">
                                <FlowTableValue data={settlement["H"][0]["items"][19]} drawMode="center" left="50%" top="50%"></FlowTableValue>
                                </div>
                            </div>
                            <div className="flowtable-row row row-10">
                                <FlowTableValue data={settlement["H"][0]["items"][7]} select="title" drawMode="center" left="50%" top="50%"></FlowTableValue>
                            </div>
                        </div>
                      </div>
                      <div className="flowtable-col flowtable-border-left-bold col col-50">
                        <div className="flowtable-col col col-50">
                            <div className="flowtable-row row row-45">
                                <div className="flowtable-col col col-50">
                                    <FlowTableValue data={settlement["H"][0]["items"][22]} drawMode="center" left="50%" top="50%"></FlowTableValue>
                                </div>
                                <div className="flowtable-col col col-50 flowtable-border-left">
                                    <div className="flowtable-row row row-50">
                                        <FlowTableValue data={settlement["H"][0]["items"][20]} drawMode="center" left="50%" top="50%"></FlowTableValue>
                                    </div>
                                    <div className="flowtable-row row row-50">
                                        <FlowTableValue data={settlement["H"][0]["items"][21]} drawMode="center" left="50%" top="50%"></FlowTableValue>
                                    </div>
                                </div>
                            </div>
                            <div className="flowtable-row row row-45">
                                <FlowTableValue data={settlement["H"][0]["items"][23]} drawMode="center" left="50%" top="50%"></FlowTableValue>

                            </div>
                            <div className="flowtable-row row row-10">
                                <FlowTableValue data={settlement["H"][0]["items"][13]} select="title" drawMode="center" left="50%" top="50%"></FlowTableValue>
                            </div>
                        </div>
                        <div className="flowtable-col col col-50">
                            <div className="flowtable-row row row-45">
                                <div className="flowtable-col col col-100">
                                    <div className="flowtable-row row row-50">
                                        <FlowTableValue data={settlement["H"][0]["items"][8]} displayMode="hor" drawMode="center" left="50%" top="50%"></FlowTableValue>
                                    </div>
                                    <div className="flowtable-row row row-50">
                                        <FlowTableValue data={settlement["H"][0]["items"][9]} displayMode="hor" drawMode="center" left="50%" top="50%"></FlowTableValue>
                                    </div>
                                </div>
                            </div>
                            <div className="flowtable-row row row-45">
                                <FlowTableValue data={settlement["H"][0]["items"][10]} displayMode="hor" drawMode="center" left="50%" top="33.3%"></FlowTableValue>
                                <FlowTableValue data={settlement["H"][0]["items"][11]} displayMode="hor" drawMode="center" left="50%" top="60.0%"></FlowTableValue>
                                <FlowTableValue data={settlement["H"][0]["items"][12]} prefix="( " afterfix=" )" displayMode="hor" drawMode="center" left="50%" top="75.0%"></FlowTableValue>

                            </div>
                            <div className="flowtable-row row row-10">
                                <FlowTableValue data={settlement["H"][0]["items"][13]} select="value" drawMode="center" left="50%" top="50%"></FlowTableValue>
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(FinancialStatement);
