import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import CardForm from '../parts/CardForm';
import Function from "../../../functions/Function";
const { updateIn, get } = require('immutable');

const func = new Function();

class RiskCard extends Component {

    state = {
        cardType: {}
    }
  constructor(props){
    super(props);
  }

  componentDidMount(){
      this.props.setActive();
      this.props.setTempData(null);
  }

  processRisk = () => {
    
    const user = this.props.user;
    const status = user.status;
    const risk = this.props.game.risk_card[this.props.state.risk_index];

    let newUser = updateIn(
        user, 
        ['status', 'cashBalance', 'value'], 
        val => status.cashBalance.value
    );
    
    let change = {};
    let record = {};
    let retirement = {};

    if(risk.type === "lose"){
        let number;
        if(risk.number === "all" || risk.number>= status[risk.status].value) number = status[risk.status].value;
        else number = risk.number;
        
        change[risk.status] = status[risk.status].value - number;
        if(risk.loseRecord)change[risk.loseRecord] = status[risk.loseRecord].value + number;

        

        if(risk.insurance && status.insurance.value>0 && this.checkbox.checked && number>0){
            let insuranceValue = number * risk.insuranceValue;
            change.cashBalance = func.numFix(status.cashBalance.value + insuranceValue);
            change.insurance = 0;
            if(risk.recordType)record[risk.recordType] = {value:insuranceValue}; 
        }
    }
    else if(risk.type === "retirement"){
        let number;
        if(risk.number === "all" || risk.number>= status[risk.status].value) number = status[risk.status].value;
        else number = risk.number;

        retirement[risk.status] = status[risk.status].retirement + number;
        change[risk.status] = status[risk.status].value - number;
        let cost = number * risk.price;
        change.cashBalance = func.numFix(status.cashBalance.value - cost);

        if(risk.recordType)record[risk.recordType] = {value:cost}; 
    }
    else if(risk.type === "costs"){
        change.cashBalance = func.numFix(status.cashBalance.value - risk.price);
        if(risk.recordType)record[risk.recordType] = {value:risk.price};
    }

    Object.keys(change).map(function(key) {
        newUser = updateIn(
            newUser, 
            ['status', key, 'value'], 
            val => change[key]
        );
    });

    Object.keys(retirement).map(function(key) {
        newUser = updateIn(
            newUser, 
            ['status', key, 'retirement'], 
            val => retirement[key]
        );
    });

    Object.keys(record).map(function(key) {
        if(record[key].value && newUser.history[key].value){
            newUser = updateIn(
                newUser, 
                ['history', key, 'value'], 
                val => [...val, record[key].value]
            );

            let hIndex = newUser.historyIndex[key].value.concat();
            if(hIndex[user.session_count]===undefined)hIndex[user.session_count] = record[key].value;
            else hIndex[user.session_count] += record[key].value;
            newUser = updateIn(
                newUser, 
                ['historyIndex', key, 'value'], 
                val => hIndex
            );
        }
        
        if(record[key].number && newUser.history[key].number){
            newUser = updateIn(
                newUser, 
                ['history', key, 'number'], 
                val => [...val, record[key].number]
            );
            let hIndex = newUser.historyIndex[key].number.concat();
            if(hIndex[user.session_count]===undefined)hIndex[user.session_count] = record[key].number;
            else hIndex[user.session_count] += record[key].number;
            newUser = updateIn(
                newUser, 
                ['historyIndex', key, 'number'], 
                val => hIndex
            );
        }
        
    });

    // console.log("new User",newUser);

    return newUser;
  }


  render() {
    const this_ = this;
    const game = this.props.game;
    const risk = game.risk_card[this.props.state.risk_index];

    const actions =  (()=>{
        const actionGroup = risk.actions; 
        return actionGroup?game.actions.filter( action => actionGroup.indexOf(action.name) != -1 ):[];
    })();

    const title = risk.title;

    const message = risk.message;

    let itemsData = {
        col:2,
        items:[]
    };

    actions.map(
        action => {
            itemsData.items.push({
                title:action.title,
                speech:action.speech,
                description:action.description,
                active:action.active,
                message_disable:true,
                onClick:()=>{
                    if(this_.props.risk_started){
                        this_.props.update(this_.processRisk());
                        this_.props.riskEnd();
                    }
                    this_.props.changePage('action');
                    this_.props.setActionType(action.name);
                    this_.props.setPageNext(risk.restart?'draw-card':'session-end');
                }
            });
        }
    );


    itemsData.items.push({
        title:risk.actions?'Do Nothing':'확인',
        speech:risk.actions?'두 나띵':'확인',
        active:true,
        onClick:(e)=>{
            if(this_.props.risk_started){
                this_.props.update(this_.processRisk());
                this_.props.riskEnd();
            }
            this_.props.changePage(risk.restart?'draw-card':'session-end');
        }
    });

    return (
        <div id="page-Card" className="ui-primary ui-fix-activities scroll-ver">
            <div className="fix">
                <div className="fix-inner">
                    <CardForm id={this.props.page} itemsData={itemsData} title={title} message={message} checkbox={ el => this.checkbox = el} risk={risk}></CardForm>
                </div>
            </div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(RiskCard);
