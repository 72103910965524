class SettlementList{
    constructor(){
        const list = [
            {
                title:"대여금",
                tabName:"loan",
                data:{
                    type:"square",
                    heightPer:120,
                    col:[
                        {
                            width:50,
                            row:[
                                {
                                    index:["A",0,"items",0],
                                    height:40
                                },
                                {
                                    index:["A",0,"items",1],
                                    height:60
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["A",0,"items",2],
                                    height:60
                                },
                                {
                                    index:["A",0,"items",3],
                                    height:40
                                }
                            ]
                        }
                    ]
                }
            },
            {
                title:"기계공구",
                tabName:"machine-tool",
                data:{
                    type:"square",
                    heightPer:120,
                    col:[
                        {
                            width:50,
                            row:[
                                {
                                    index:["A",1,"items",0],
                                    height:35
                                },
                                {
                                    index:["A",1,"items",1],
                                    height:65
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["A",1,"items",2],
                                    height:45
                                },
                                {
                                    index:["A",1,"items",3],
                                    height:23
                                },
                                {
                                    index:["A",1,"items",4],
                                    height:33
                                }
                            ]
                        }
                    ]
                }
            },
            {
                title:"연구개발",
                tabName:"rnd",
                data:{
                    type:"square",
                    heightPer:120,
                    col:[
                        {
                            width:50,
                            row:[
                                {
                                    index:["A",2,"items",0],
                                    numberSign:"매",
                                    height:45
                                },
                                {
                                    index:["A",2,"items",1],
                                    numberSign:"매",
                                    height:55
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["A",2,"items",2],
                                    numberSign:"매",
                                    height:55
                                },
                                {
                                    index:["A",2,"items",3],
                                    numberSign:"매",
                                    height:45
                                }
                            ]
                        }
                    ]
                }
                
            },
            {
                title:"차입금",
                tabName:"borrowed",
                data:{
                    type:"square",
                    heightPer:120,
                    col:[
                        
                        {
                            width:50,
                            row:[
                                {
                                    index:["C",0,"items",2],
                                    height:40
                                },
                                {
                                    index:["C",0,"items",3],
                                    height:60
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["C",0,"items",0],
                                    height:55
                                },
                                {
                                    index:["C",0,"items",1],
                                    height:45
                                }
                            ]
                        }
                    ]
                }
            },
            {
                title:"재료",
                tabName:"material",
                data:{
                    type:"square",
                    heightPer:130,
                    col:[
                        {
                            width:50,
                            row:[
                                {
                                    index:["B",0,"items",0],
                                    numberSign:"개",
                                    height:50
                                },
                                {
                                    index:["B",0,"items",1],
                                    numberSign:"개",
                                    height:50
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["B",0,"items",2],
                                    numberSign:"개",
                                    height:45
                                },
                                {
                                    index:["B",0,"items",3],
                                    numberSign:"개",
                                    height:27
                                },
                                {
                                    index:["B",0,"items",4],
                                    numberSign:"개",
                                    height:28
                                }
                            ]
                        }
                        
                    ],
                    average: {
                        index:["B",0,"items",5]
                    }
                }
            },
            {
                title:"재공품",
                tabName:"work-in-process",
                data:{
                    type:"square",
                    heightPer:140,
                    col:[
                        {
                            width:50,
                            row:[
                                {
                                    index:["B",1,"items",0],
                                    numberSign:"개",
                                    height:25
                                },
                                {
                                    index:["B",1,"items",1],
                                    numberSign:"개",
                                    height:20
                                },
                                {
                                    index:["B",1,"items",2],
                                    numberSign:"개",
                                    height:20
                                },
                                {
                                    index:["B",1,"items",3],
                                    numberSign:"개",
                                    height:35
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["B",1,"items",4],
                                    numberSign:"개",
                                    height:50
                                },
                                {
                                    index:["B",1,"items",5],
                                    numberSign:"개",
                                    height:25
                                },
                                {
                                    index:["B",1,"items",6],
                                    numberSign:"개",
                                    height:25
                                }
                            ]
                        }
                    ],
                    average: {
                        index:["B",1,"items",7]
                    }
                }
            },
            {
                title:"제품",
                tabName:"product",
                data:{
                    type:"square",
                    heightPer:145,
                    col:[
                        {
                            width:50,
                            row:[
                                {
                                    index:["B",2,"items",0],
                                    numberSign:"개",
                                    height:25
                                },
                                {
                                    index:["B",2,"items",1],
                                    numberSign:"개",
                                    height:25
                                },
                                {
                                    index:["B",2,"items",2],
                                    numberSign:"개",
                                    height:17
                                },
                                {
                                    index:["B",1,"items",4],
                                    numberSign:"개",
                                    height:33
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["B",2,"items",3],
                                    numberSign:"개",
                                    height:55
                                },
                                {
                                    index:["B",2,"items",4],
                                    numberSign:"개",
                                    height:23
                                },
                                {
                                    index:["B",2,"items",5],
                                    numberSign:"개",
                                    height:22
                                }
                            ]
                        }
                    ],
                    average: {
                        index:["B",2,"items",6]
                    }
                }
            },
            {
                title:"특별손익",
                tabName:"profit-loss",
                data:{
                    type:"split",
                    heightPer:120,
                    col:[
                        {
                            width:50,
                            row:[
                                {
                                    index:["D",0,"items",0],
                                    height:45
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["D",0,"items",1],
                                    height:20
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["D",0,"items",2],
                                    height:25
                                },
                                {
                                    index:["D",0,"items",3],
                                    height:19
                                },
                                {
                                    index:["D",0,"items",4],
                                    height:18
                                },
                                {
                                    index:["D",0,"items",5],
                                    height:18
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["D",0,"items",6],
                                    height:50
                                },
                                {
                                    index:["D",0,"items",7],
                                    height:30
                                }
                            ]
                        }
                    ]
                }
            },
            {
                title:"손익계산서",
                tabName:"income-statement",
                data:{
                    type:"split",
                    heightPer:120,
                    col:[]
                }
            },
            {
                title:"1개당",
                tabName:"each",
                data:{
                    type:"square",
                    heightPer:80,
                    col:[
                        {
                            width:50,
                            row:[
                                {
                                    index:["J",0,"items",0],
                                    height:100
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["J",0,"items",1],
                                    height:45
                                },
                                {
                                    index:["J",0,"items",2],
                                    height:55
                                }
                            ]
                        }
                    ]
                }
            },
            {
                title:"누적이익",
                tabName:"cumulative-gain",
                data:{
                    type:"split",
                    heightPer:100,
                    col:[
                        {
                            width:50,
                            row:[
                                {
                                    index:["F",0,"items",0],
                                    height:65
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["F",0,"items",1],
                                    height:30
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["F",0,"items",2],
                                    height:35
                                },
                                {
                                    index:["F",0,"items",3],
                                    height:35
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["F",0,"items",4],
                                    height:70
                                }
                            ]
                        }
                    ]
                }
            },
            {
                title:"당기미처분이익",
                tabName:"unappropriated-gain",
                data:{
                    type:"split",
                    heightPer:100,
                    col:[
                        {
                            width:50,
                            row:[
                                {
                                    index:["F",0,"items",2],
                                    height:65
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["H",0,"items",8],
                                    height:30
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["H",0,"items",12],
                                    height:35
                                },
                                {
                                    index:["F",0,"items",3],
                                    height:35
                                }
                            ]
                        },
                        {
                            width:50,
                            row:[
                                {
                                    index:["H",0,"items",11],
                                    height:70
                                }
                            ]
                        }
                    ]
                }
            },
            {
                title:"재무상태표",
                tabName:"financial-statement",
                data:{
                    type:"split",
                    heightPer:120,
                    col:[]
                }
            },
        ];
        
        return list;
    }
}

export default SettlementList;