import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import Function from "../../../functions/Function";
const func = new Function();

class AnalysisTable extends Component {
    constructor(props){
        super(props);
    }

    render() {
        let userlist = this.props.userlist;
        userlist = (()=>{
            let list = [];
            for(let prop in userlist){
              if(this.props.filtered_team === "all" || userlist[prop].team === this.props.filtered_team)list.push(userlist[prop]);
            }
            return list;
        })();
        let dataList = userlist.map(user => JSON.parse(user.data));
        const period = this.props.filtered_period;

        let productSum = 0;
        dataList.map((user,userIndex)=>{
            user.analysis.map((item)=>{
                if(item.title == "매출개수" && item.value[period-1] !== undefined) productSum += item.value[period-1].value;
            });
        });

        // let data = [];
        let labels = dataList.map((user,userIndex)=>{
            if(userIndex==0){
                if(user.analysis){
                    let titles = user.analysis.map((item)=>{
                        return(<div className="row bg-green-dark text-white">
                            <div className="col col-100">{item.title}</div>
                        </div>)
                    });
                    return (<div className="title text-white inline-block">
                        <div className="row bg-green"></div>
                        {titles}
                    </div>)
                }
            }
        });

        let table = dataList.map((user,userIndex)=>{
            if(user.analysis){
            let userData = user.analysis.map((item)=>{
                if(item.title == "매출개수" && item.value[period-1] !== undefined) item.value[period-1].result = func.numFix(item.value[period-1].value/productSum * 100);
                let value = item.value.map((v,periodIndex)=>{
                    if(periodIndex+1 == period){
                        return(<Fragment>
                            <div className={"col "+(v.result !== undefined?"col-50":"col-100")}>{v.value?func.round(v.value,1):""}</div>
                            {v.result !== undefined&&<div className="col col-50">{v.result+"%"}</div>}
                        </Fragment>)
                    }
                });
    
                return(<div className="row text-green">
                    {value}
                </div>)
            });
            return (<div className="row-container inline-block text-green-dark">
                <div className="row bg-green text-white"><div className="col col-100">{user.name}</div></div>
                {userData}
            </div>)
            }
        });

        return (
            <div id="admin-analysis-table">
                {labels}
                {table}
            </div>
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AnalysisTable);