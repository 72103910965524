import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';

/* Components */
import UserCard from './parts/UserCard';

/* images */
import Server from '../../functions/Server';
const server = new Server();

class UserList extends Component {
  
  state = {
    isOpenSaveList : false,
    saveListLength : null,
    email: null,
    name: null
  }
  constructor(props){
    super(props);
  }

  componentDidMount(){
  }

  openSaveList = (email, name) => {
    let this_ = this;
    server.getAutoSaveListLength(email, (length)=>{
      this_.setState({isOpenSaveList:true,saveListLength:length, email: email, name: name});
    });
  }

  closeSaveList = () => {
    this.setState({isOpenSaveList:false,saveListLength:null, email:null, name: null});
  }

  changeData = (e) => {
    if(window.confirm("정말 이전 데이터를 덮어 씌우시겠습니까?")){
      let this_ = this;
      let index = e.target.getAttribute('index');
      server.changeAutoSaveToData(this.state.email, index, ()=>{
        server.getUserList((data)=>{
            this_.props.setUserList(data);
            this_.setState({isOpenSaveList:false,saveListLength:null, email:null, name: null});
        });
      });
    }
  }
  
  render() {
    let userlist = this.props.userlist;
    let saveList = (()=>{
      let list = [];
      for(let i=0; i<this.state.saveListLength; i++){
        list.push((()=>{return(<li>
          <span>{i+2}기 시작 ({i+1}기 결산완료)</span><button onClick={this.changeData} className="btn" index={i}>불러오기</button>
        </li>)})());
      }
      return list;
    })();

    userlist = (()=>{
      let list = [];
      for(let prop in userlist){
        if(this.props.filtered_team === "all" || userlist[prop].team === this.props.filtered_team) list.push(userlist[prop]);
      }
      return list;
    })();
    
    const usercards = userlist.map(user=>{
        const data = JSON.parse(user.data);
        return(<UserCard data={data} openSaveList={this.openSaveList} ></UserCard>)
    });
    return (
      <Fragment>
      <div id="tab-userlist">
        <div className="inner float-container">
          {usercards}
        </div>
      </div>
      {this.state.isOpenSaveList&&<div id="save-list" className="popup-container" onClick={this.closeSaveList}>
        <div className="admin-popup text-green" onClick={(e)=>{e.stopPropagation()}}>
          <h3>자동저장 리스트 | {this.state.name}</h3>
          <div className="content-body">
            <ul>
              {saveList}
            </ul>
          </div>
        </div>

      </div>}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(UserList);
