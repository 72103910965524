import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import BoardValue from '../parts/BoardValue';

/* images */
import img_board from '../../../assets/images/status-board.svg';

/* images - chip */
import img_chip_green from '../../../assets/icon/chip-green.svg';
import img_chip_red from '../../../assets/icon/chip-red.svg';
import img_chip_blue from '../../../assets/icon/chip-blue.svg';
import img_chip_yellow from '../../../assets/icon/chip-yellow.svg';
import img_chip_person from '../../../assets/icon/chip-person.svg';
import img_chip_product from '../../../assets/icon/chip-product.svg';
import img_chip_machine_large from '../../../assets/icon/chip-machine-large.svg';
import img_chip_machine_normal from '../../../assets/icon/chip-machine-normal.svg';
import img_chip_machine_part from '../../../assets/icon/chip-machine-part.svg';

/* Components */

class StatusBox extends Component {
  board = React.createRef();
  state = {
    width:0
  }
  constructor(props){
    super(props);
  }

  resizeEvent = () => {
    const this_ = this;
    this.setState({width:this_.board.current.offsetWidth})
  }

  componentDidMount(){
    const this_ = this;
    this.setState({width:this.board.current.offsetWidth})

    window.addEventListener("resize",this.resizeEvent);
  }

  componentWillUnmount(){
    window.removeEventListener("resize",this.resizeEvent);
  }


  changeMode = (e) => {
    this.props.changeMode(e.currentTarget.getAttribute('data-val'));
  }

  render() {
    const user = this.props.user;
    const status = user.status;
    const width = this.state.width;
    return (
        <div id="status-box" className="ui-primary text-white">
            <div id="status-board">
              <div className="board" ref={this.board}>
                <img src={img_board} />

                {/* person */}
                <BoardValue left="40%" top="20%" width="0.1" max="1" name={status.salesman.name} value={status.salesman.value} src={img_chip_person} parentWidth={width}></BoardValue>
                <BoardValue left="40%" top="50%" width="0.1"  max="1" name={status.worker.name} value={status.worker.value} src={img_chip_person} parentWidth={width}></BoardValue>

                {/* chip */}
                <BoardValue left="7.5%" top="20%" width="0.1"  max="1" name={status.marketResearch.name} value={status.marketResearch.value} src={img_chip_green} parentWidth={width}></BoardValue>
                <BoardValue left="7.5%" top="50%" width="0.1"  max="1" name={status.PAC.name} value={status.PAC.value} src={img_chip_green} parentWidth={width}></BoardValue>
                <BoardValue left="7.5%" top="84%" width="0.1"  max="1" name={status.merchandiser.name} value={status.merchandiser.value} src={img_chip_green} parentWidth={width}></BoardValue>
                <BoardValue left="7.5%" top="97%" width="0.1"  max="1" name={status.insurance.name} value={status.insurance.value} src={img_chip_yellow} parentWidth={width}></BoardValue>
                <BoardValue left="23.5%" top="20%" width="0.1"  max="1" name={status.advertising.name} value={status.advertising.value} src={img_chip_red} parentWidth={width}></BoardValue>
                <BoardValue left="23.5%" top="50%" width="0.1"  max="1" name={status.RND.name} value={status.RND.value} src={img_chip_blue} parentWidth={width}></BoardValue>

                {/* product */}
                <BoardValue left="75%" top="20%" width="0.07" max="3" name={status.product.name} value={status.product.value} src={img_chip_product} parentWidth={width}></BoardValue>
                <BoardValue left="81%" top="50%" width="0.07" max="3" name={status.workInProcess.name} value={status.workInProcess.value} src={img_chip_product} parentWidth={width}></BoardValue>
                <BoardValue left="75%" top="87%" width="0.07" max="3" name={status.material.name} value={status.material.value} src={img_chip_product} parentWidth={width}></BoardValue>
                
                {/* machine */}
                <BoardValue left="60%" top="41%" width="0.08" max="1" name={status.machineLarge.name} value={status.machineLarge.value} src={img_chip_machine_large} parentWidth={width}></BoardValue>
                <BoardValue left="60%" top="52%" width="0.08" max="1" name={status.machineNormal.name} value={status.machineNormal.value} src={img_chip_machine_normal} parentWidth={width}></BoardValue>
                <BoardValue left="60%" top="62%" width="0.08" max="1" name={status.machinePart.name} value={status.machinePart.value} src={img_chip_machine_part} parentWidth={width}></BoardValue>


              </div>
              <div className="board-label">회사판</div>
              <div className="period">{user.period}기</div>
              <div className="profile">{user.team}조 {user.name}({user.company})</div>
            </div>
            <div id="status-list">

              <div className="value-container">
                <div className="label">매출액</div>
                <div className="status-value">{status.sales.value}</div>
              </div>

              <div className="value-container">
                <div className="label">매출개수</div>
                <div className="status-value">{status.salesNumber.value}</div>
              </div>

              <div className="value-container">
                <div className="label">차입금</div>
                <div className="status-value">
                  {
                    status.borrowed.value
                    +status.loanBorrowed.value
                    +status.bond.value
                  }
                </div>
              </div>

              <div className="value-container">
                <div className="label">도난</div>
                <div className="status-value">{status.theft.value}</div>
              </div>

              <div className="value-container">
                <div className="label">제조미스</div>
                <div className="status-value">{status.mistake.value}</div>
              </div>

              <div className="value-container">
                <div className="label">화재</div>
                <div className="status-value">{status.fire.value}</div>
              </div>
            </div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(StatusBox);
