import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import FlowTableValue from './FlowTableValue';


class IncomeStatement extends Component {
  constructor(props){
    super(props);
  }

  render() {
    const settlementData = this.props.data;
    const settlement = settlementData.settlement;
    
    return (
      <div id="tab-settlement" className="ui-primary ui-fix-subtab bg-green-dark text-black scroll-ver">
        <div className="fix">
            <div className="fix-inner fix-inner-hor fix-inner-30">
              <div id="income-statement" className="flowtable square">
                <div className="inner">
                  <div className="fill-inner">
                      <div className="flowtable-col col col-25">
                            <FlowTableValue data={settlement["E"][0]["items"][0]} left="0" top="20%"></FlowTableValue>
                            <FlowTableValue data={settlement["E"][0]["items"][1]} left="0" top="40%"></FlowTableValue>
                            <FlowTableValue data={settlement["E"][0]["items"][1]} left="0" top="40%"></FlowTableValue>
                      </div>
                      <div className="flowtable-col col col-75">
                        <div className="flowtable-row row row-10">
                            <FlowTableValue data={settlement["E"][0]["items"][2]} drawMode="center" left="50%" top="50%"></FlowTableValue>
                            <FlowTableValue data={settlement["E"][0]["items"][12]} boxMode="box" drawMode="center" left="0" top="50%"></FlowTableValue>
                        </div>
                        <div className="flowtable-row row row-90">
                            <div className="flowtable-col col col-33">
                                <div className="flowtable-row row row-100">
                                    <FlowTableValue data={settlement["E"][0]["items"][3]} top="30%"></FlowTableValue>

                                    <FlowTableValue data={settlement["E"][0]["items"][13]} boxMode="box" drawMode="center" left="0" top="60%"></FlowTableValue>
                                    <FlowTableValue data={settlement["E"][0]["items"][15]} boxMode="box" drawMode="center" left="65%" top="92%"></FlowTableValue>
                                </div>
                            </div>
                            <div className="flowtable-col col col-66">
                                <div className="flowtable-row row row-85">
                                    <div className="flowtable-col col col-50">
                                        <div className="flowtable-row row-100">
                                            <FlowTableValue data={settlement["E"][0]["items"][10]} top="35%"></FlowTableValue>

                                            <FlowTableValue data={settlement["E"][0]["items"][14]} boxMode="box" drawMode="center" left="0" top="10%"></FlowTableValue>
                                            <FlowTableValue data={settlement["E"][0]["items"][16]} boxMode="box" drawMode="center" left="0" top="85%"></FlowTableValue>
                                        </div>
                                    </div>
                                    <div className="flowtable-col col col-50">
                                        <div className="flowtable-row row-100 flowtable-border-left">
                                            <FlowTableValue data={settlement["E"][0]["items"][4]} drawMode="center" left="50%" top="10%"></FlowTableValue>
                                            <FlowTableValue data={settlement["E"][0]["items"][5]} drawMode="center" left="50%" top="25%"></FlowTableValue>
                                            <FlowTableValue data={settlement["E"][0]["items"][6]} drawMode="center" left="50%" top="40%"></FlowTableValue>
                                            <FlowTableValue data={settlement["E"][0]["items"][7]} drawMode="center" left="50%" top="57%"></FlowTableValue>
                                            <FlowTableValue data={settlement["E"][0]["items"][8]} drawMode="center" left="50%" top="75%"></FlowTableValue>
                                            <FlowTableValue data={settlement["E"][0]["items"][9]} drawMode="center" left="50%" top="90%"></FlowTableValue>
                                        </div>
                                    </div>
                                </div>
                                <div className="flowtable-row row row-15">
                                <FlowTableValue data={settlement["E"][0]["items"][11]} drawMode="center" left="50%" top="50%"></FlowTableValue>
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(IncomeStatement);
