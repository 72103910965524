import button from '../assets/sound/button.mp3';
import back from '../assets/sound/back.mp3';
import decision from '../assets/sound/decision.mp3';
import turnend from '../assets/sound/turnend.mp3';
import card_decision from '../assets/sound/card-decision.mp3';
import card_risk from '../assets/sound/card-risk.mp3';
import card_lucky from '../assets/sound/card-lucky.mp3';

class Sound{
    constructor(){
        this.sound = {
            button : new Audio(button),
            back : new Audio(back),
            decision : new Audio(decision),
            turnend : new Audio(turnend),
            card_decision : new Audio(card_decision),
            card_risk : new Audio(card_risk),
            card_lucky : new Audio(card_lucky)
        };
    }

    preload = () => {
        for(let prop in this.sound){
            this.sound[prop].load();
        }
    }

    play = (soundName) => {
        
        // for(let prop in this.sound){
        //     if(this.sound[prop].currentTime !== 0){
        //         this.sound[prop].pause();
        //         this.sound[prop].currentTime = 0;
        //     }
        // }
        this.sound[soundName].pause();
        this.sound[soundName].currentTime = 0;
        this.sound[soundName].play();
    }
}

export default Sound;
