import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';

/* Components */
import UserList from './UserList';
import RankTable from './RankTable';
import TeamAnalysisTable from './TeamAnalysisTable';
import NewGame from './NewGame';

import Tab from '../parts/Tab';
import Button from '../parts/Button';

/* images */
import icon_logo from '../../assets/icon/logo-admin.svg';

import Server from '../../functions/Server';
const server = new Server();

const QRCode = require("qrcode-svg");



class AdminView extends Component {

  state = {
    open_qr: false,
    open_newgame: false,
  }
  constructor(props){
    super(props);
  }

  componentDidMount(){
      this.imagePreload();
      this.loadData();
      this.createQR();
  }

  toggleQR = () => {
    this.setState({open_qr:!this.state.open_qr});
  }

  createQR = () => {
    const qrcode = new QRCode({
      content: window.location.href,
      
      width: 400,
      height: 400,
      color: "#000000",
      background: "transparent",
      ecl: "M"
    });
    const svg = qrcode.svg();
    document.querySelector("#qr .inner").innerHTML = svg;
  }

  imagePreload = () => {
    const imageList = [

    ]
    imageList.forEach((image) => {
        new Image().src = image
    });
  }

  loadData = () => {
    server.getUserList((data)=>{
        this.props.setUserList(data);
    });
    server.getTeamNumber((team_number)=>{
      this.props.setTeamNumber(team_number);
    });
  }

  teamSelect = (value) => {
    var value = value=="all"?value:parseInt(value);
    this.props.changeFilterTeam(value);
  }
  
  changeTab = (value) => {
    this.props.changeTab(value);
  }

  

  refresh  = () => {
    server.getUserList((data)=>{
        this.props.setUserList(data);
    });
  }

  newGame = () => {
    this.setState({
      open_newgame:!this.state.open_newgame
    });
  }

  closeNewgame = () => {
    this.setState({
      open_newgame:false
    });
  }

  render() {
    const mode = this.props.mode;
    const teamItems = (()=>{
      let list = [];
      list.push({title:"All", tabName:"all"});
      for(let i=1; i<=this.props.team_number; i++){
        list.push({title:i+"조", tabName:i});
      }
      return list;
    })();
    
    const tabItems = [
      {title:"유저리스트", tabName:"userlist"},
      {title:"전체순위표", tabName:"rank-table"},
      {title:"조별경영분석표", tabName:"team-analysis-table"}
    ];
    
    return (
      <div id="view-admin">
        <div id="nav-admin" className="float-container" className="ui-fix ui-fix-top bg-default">
          <h1 className="float-left"><img src={icon_logo}></img></h1>
          <Tab id="tab-team" conClassName="float-left tab" connect="filtered_team" default="1" list={teamItems} callback={this.teamSelect}></Tab>
          <Tab id="tab-tab" conClassName="float-left tab" connect="tab" default="userlist" list={tabItems} callback={this.changeTab}></Tab>
          <nav className="float-right tab">
            <Button onClick={this.props.toggleSimplifyUserdata} soundplay="button">{this.props.simplify_userdata?"유저정보 확장":"유저정보 간소화"}</Button>
            <Button onClick={this.toggleQR} soundplay="button">QR코드</Button>
            <Button onClick={this.refresh} soundplay="button">새로고침</Button>
            <Button onClick={this.newGame} soundplay="button">새게임</Button>
          </nav>
        </div>
        <div id="admin-view">
            {this.props.tab === "userlist"&&<UserList></UserList>}
            {this.props.tab === "rank-table"&&<RankTable></RankTable>}
            {this.props.tab === "team-analysis-table"&&<TeamAnalysisTable></TeamAnalysisTable>}
        </div>
        <div id="qr" onClick={this.toggleQR} className={this.state.open_qr?"active":""}><div className="inner"></div></div>
        {this.state.open_newgame&&<NewGame closeNewgame={this.closeNewgame}></NewGame>}
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(AdminView);
