import React, { Component } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../store/StoreFunc';

/* font */
import '../assets/font/nanum-square/nanumsquare.css';

/* style */
import '../css/reset.css';
import '../css/main.css';
import '../css/user.css';
import '../css/admin.css';

/* Components */
import UserView from './user/UserView';
import AdminView from './admin/AdminView';
import Data from '../data/Data';

class App extends Component {

  constructor(props){
    super(props);
  }
  
  render() {
    return (
      <div className="App">
        {this.props.view === "user"&&<UserView></UserView>}
        {this.props.view === "admin"&&<AdminView></AdminView>}
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
