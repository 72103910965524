import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

import CardForm from '../parts/CardForm';

class Card extends Component {

    state = {
        cardType: {}
    }
  constructor(props){
    super(props);
  }

  componentDidMount(){
      this.props.setActive();
      this.props.setTempData(null);
  }

  render() {
    const this_ = this;
    const game = this.props.game;
    
    const actions =  (()=>{
        switch(this.props.page){
            case 'decision_card': 
            case 'lucky_card_1':
            case 'lucky_card_2': return game.actions.filter( action => game[this.props.page].indexOf(action.name) != -1 );
                        break;
        }
    })();

    const title = (()=>{
        switch(this.props.page){
            case 'decision_card': return 'Decision Card';
                                  break;
            case 'risk_card': return game.risk_card[this.props.state.risk_index].title;
                                  break;
            case 'lucky_card_1':
            case 'lucky_card_2': return 'Lucky Card';
                                break;
        }
    })();

    let itemsData = {
        col:this.props.page==='lucky_card_1'||this.props.page==='lucky_card_2'?1:2,
        items:[]
    };

    actions.map(
        action => {
            itemsData.items.push({
                title:action.title,
                speech:action.speech,
                description:action.description,
                active:action.active,
                onClick:()=>{
                    this_.props.changePage('action');
                    this_.props.setActionType(action.name);
                    this_.props.setPageNext('session-end');
                }
            });
        }
    );

    itemsData.items.push({
        title:'Do Nothing',
        speech:'두 나띵',
        active:true,
        onClick:(e)=>{this_.props.changePage('session-end')}
    });

    return (
        <div id="page-Card" className="ui-primary ui-fix-activities scroll-ver">
            <div className="fix">
                <div className="fix-inner">
                 <CardForm id={this.props.page} itemsData={itemsData} title={title}></CardForm>
                </div>
            </div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Card);
