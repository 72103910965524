import React, { Component } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';

/* Components */
import InputText from '../parts/InputText';
import InputSelect from '../parts/InputSelect';
import UploaderImage from '../parts/UploaderImage';
import Button from '../parts/Button';

/* images */
import Server from '../../functions/Server';
const server = new Server();

class NewGame extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){
  }

  confirm = () => {
    const group_name = this.input_group_name.value;
    const team_number = parseInt(this.input_team.value);
    const logo_dataUrl = this.input_logo.getAttribute('src');
    if(window.confirm("정말 모든 유저를 삭제하시겠습니까?")){
        const this_ = this;
        server.new_game(group_name, team_number,logo_dataUrl, ()=>{
            server.getUserList((data)=>{
                this_.props.setUserList(data);
                this_.props.closeNewgame();
            });

            this_.props.changeFilterTeam(1);
            this_.props.setGame(group_name,team_number,logo_dataUrl);
        });
    }
  }
  
  render() {
    const options = (()=>{
        let list = [];
        for(let i=1; i<=20; i++){
            list.push({value:i, label:i});
        }
        return list;
    })();

    return (
      <div id="popup-newgame" className="popup-container">
        <div className="admin-popup text-green">
            <h3>새게임</h3>
            <div className="content-body">
                <div className="item-container">
                  <div className="item"><InputText placeholder="회사명" inputRef={el => this.input_group_name = el}>회사명</InputText></div>
                  <div className="item"><InputSelect options={options} default={this.props.team_number} inputRef={el => this.input_team = el}>조 생성</InputSelect></div>
                  <div className="item"><UploaderImage inputRef={el => this.input_logo = el}>로고 업로드</UploaderImage></div>
                </div>
                <div className="button-container row">
                    <div className="item col col-50"><Button onClick={this.confirm} className="btn">새게임 시작</Button></div>      
                    <div className="item col col-50"><Button onClick={this.props.closeNewgame} className="btn">취소</Button></div>      
                </div>
            </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(NewGame);
