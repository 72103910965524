import React, { Component } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';
import ChangeParameter from './parts/ChangeParameter';

/* images */
import icon_logo from '../../assets/icon/logo-small.svg';
import icon_status from '../../assets/icon/status.svg';

class StatusBar extends Component {

  constructor(props){
    super(props);
  }

  toggle_statusbox = () => {
    this.props.toggle_statusbox();
    this.props.sound.play("button");
  }
  toggle_setting = () => {
    this.props.toggle_setting();
    this.props.sound.play("button");
  }

  render() {
    const mode = this.props.mode;
    const is_open_statusbox = this.props.is_open_statusbox;
    const status = this.props.status;
    const cashBalance = status?status.cashBalance.value:0;
    return (
      <div id="status-bar" className="ui-primary text-white">
          <h1 onClick={this.toggle_setting}><img src={icon_logo} /></h1>
          {mode==='activities'?<div className="cashbalance"><span>현금잔액</span><span>{cashBalance}</span><ChangeParameter id="changed-cash" className={this.props.changedCash>0?"bg-blue-bright":this.props.changedCash<0?"bg-red-bright":""} value={this.props.changedCash}></ChangeParameter></div>:null}
          {mode==='activities'?<button id="btn-status" className={is_open_statusbox?"active":null} onClick={this.toggle_statusbox}><img src={icon_status} /></button>:null}
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(StatusBar);
