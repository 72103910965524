import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import Speech from '../../../functions/Speech';

const speech = new Speech();

class Button extends Component {
  constructor(props){
    super(props);
  }

  onClick = (e) => {
    const currentTime = new Date().getTime();
    if(currentTime - this.props.last_click_time > 300){
      this.props.onClick(e);

      const speechText = e.currentTarget.getAttribute('speech');
      const soundName = e.currentTarget.getAttribute('sound');
      if(speechText){
        this.props.voice_sound.play(speechText);
        //speech.text(speechText,this.props.utter);
      }
      if(soundName)this.props.sound.play(soundName);
    }
    this.props.setLastClickTime(currentTime);
  }

  render() {
    return (
        <button id={this.props.id} className={'btn ' + (this.props.className?this.props.className:"")} onClick={this.onClick} speech={this.props.speech} sound={this.props.soundplay}>
          {this.props.children}
          {this.props.description&&!this.props.message_disable?<div className="description"><div className="text-inner">{this.props.description}</div></div>:null}
        </button>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Button);
