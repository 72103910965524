import Game from './Game';
import User from './User';
import SettlementData from './settlement/SettlementData';

class Data{
    constructor(){}
    
    getGame = () =>{
        return new Game();
    }

    getUser = () => {
        return new User();
    }

    getSettlementData = () => {
        return new SettlementData();
    }
}

export default Data