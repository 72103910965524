import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';

class InputSelect extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const this_ = this;
    const options = this.props.options.map(option => {return(<option key={option.value} value={option.value} selected={this_.props.default===option.value?"selected":""}>{option.label}</option>)});
    return (
        <div className={'item ' + this.props.conClassName}>
            <div className="label">{this.props.children}</div>
            <select id={this.props.id} className={'input-select ' + this.props.className} ref={this.props.inputRef} onChange={this.props.onChange} value={this.props.value}>
                {options}
            </select>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(InputSelect);