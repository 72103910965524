import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import Button from './Button';

/* Components */

class ButtonForm extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const itemsData = this.props.itemsData;
    
    const items = itemsData.items.map((item,i)=>{
        let className = 'item col';
        if(itemsData.col === 2){
            if(i%2===0&&itemsData.items.length===i+1){
                className += " col-100";
            }
            else{
                className += " col-50";
            }
        }
        else{
            className += " col-100";
        }

        const speech = item.speech?item.speech:item.title;

        return(
        <div className={className}>
            <Button onClick={item.onClick} className={!item.active?'deactive':null} speech={speech} description={item.description} message_disable={item.message_disable}>{item.title}</Button>
        </div>
        )
    });
    
    return (
        <div className="item-container row">
            {items}
        </div>
    );
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(ButtonForm);
