import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

import Speech from '../../../functions/Speech';
import Sound from '../../../functions/Sound';

/* images */


const speech = new Speech();


class RuleB extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){
    this.props.setActive();
  }

  gotoAction = (e) => {
    this.props.changePage('action');
    this.props.setActionType(e.currentTarget.getAttribute('data-action'));
    this.props.setPageNext('draw-card');

    this.props.voice_sound.play(e.currentTarget.getAttribute('data-speech'));
    //speech.text(e.currentTarget.getAttribute('data-speech'),this.props.utter);
  }

  doNothing = (e) => {
    this.props.changePage('draw-card');
    this.props.voice_sound.play(e.currentTarget.getAttribute('data-speech'));
    //speech.text(e.currentTarget.getAttribute('data-speech'),this.props.utter);
  }

  back = () => {
    this.props.back();
    this.props.sound.play('back');
  }

  render() {
    const game = this.props.game;
    const actions = game.actions.filter(action => game['option'].indexOf(action.name)!= -1 );
    const buttons = actions.map(
        action => (<div className="item col col-50"><button className={"btn "+(action.active?"":"deactive")} key={action.name} onClick={this.gotoAction} data-action={action.name} data-speech={action.speech}>{action.title}</button></div>)
    );
    return (
        <div id="page-ruleb" className="ui-primary ui-fix-activities text-green scroll-ver">
          <div className="fix">
            <div className="fix-inner">
              <div className="page-inner">
                <div className="item-container">
                  <h3>옵션</h3>
                  <div className="content-body">
                    {buttons}
                    <div className="item col col-100"><button className="btn active" onClick={this.doNothing} data-speech="두 낫띵">Do Nothing</button></div>
                    <div className="item col col-100"><button className="btn active" onClick={this.back}>뒤로가기</button></div>
                  </div>
                </div>
             </div>
            </div>
          </div>
        </div>
    );
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(RuleB);
