import React, { Component,Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';

/* Components */
import AnalysisTable from './parts/AnalysisTable';
import Graph from './parts/Graph';
import Tab from '../parts/Tab';

import Server from '../../functions/Server';
const server = new Server();

class TeamAnalysisTable extends Component {

  constructor(props){
    super(props);
  }

  periodSelect = (value) => {
    this.props.changeFilterPeriod(parseInt(value));
  }

  changeSubTab = (value) => {
    this.props.changeSubTab(value);
  }
  
  render() {
    let subtab = this.props.subtab;
    const periodItems = (()=>{
        let list = [];
        for(let i=1; i<=5; i++){
            list.push((()=>{return {title:i+"기", tabName:i}})());
        }
        return list;
    })()
    const team_performance = this.props.game.team_performance;
    return (
      <div id="tab-team-analysis-table" className="bg-white">
        <div id="nav-team-analysis-table" className="bg-green-dark text-white float-container">
            <Tab conClassName="float-left tab" connect="filtered_period" default="1" list={periodItems} callback={this.periodSelect}></Tab>
            <Tab conClassName="float-right tab" connect="subtab" default="analisis-table" list={team_performance} callback={this.changeSubTab}></Tab>
        </div>
        <div className="scroll-hor">
            <div className="scroll-inner">
            {subtab === "analisis-table"&&<AnalysisTable></AnalysisTable>}
            {subtab !== "analisis-table" && subtab !== ""&&<Graph></Graph>}
            </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(TeamAnalysisTable);
