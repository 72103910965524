import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

/* Components */
import Button from '../parts/Button';
import Speech from '../../../functions/Speech';
import Server from '../../../functions/Server';

const speech = new Speech();
const server = new Server();

class Connect extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount = () => {
    this.props.voice_sound.play('서버연결에 실패했습니다. 네트워크를 다시 연결해주세요.');
    //speech.text('서버연결에 실패했습니다. 네트워크를 다시 연결해주세요.',this.props.utter);
  }

  connect = () => {
    const this_ = this;
    server.update(this_.props.user.email, this_.props.user, 
    ()=>{
        this_.props.activateReturn();
        this_.props.open_statusbox();
        this_.props.changePage('session-start');
        this_.props.sound.play('turnend');
    }, 
    ()=>{
    //    console.log("전송실패");
    });
  }


  render() {
    return (
        <div id="page-connect" className="ui-primary ui-fix-activities bg-green-dark text-white">
            <div className="fix">
                <div className="fix-inner">
                    <div className="item-container">
                        <div className="item"><div className="message text-center">서버연결에 실패했습니다.<br />네트워크를 다시 연결해주세요.</div>
</div>
                        <div className="item"><Button onClick={this.connect} speech="확인" >확인</Button></div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Connect);
