import React, { Component } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';

class UploaderImage extends Component {

  state = {
    image_dataUrl: null
  }

  constructor(props){
    super(props);
    this.container_image = React.createRef();
  }

  onChange = (e) => {
    const this_ = this;
    const input = e.target;

    if (input.files && input.files[0]) {
        let reader = new FileReader();  
        reader.onload = function(e) {
            this_.setState({image_dataUrl:e.target.result});
        };
        reader.readAsDataURL(input.files[0]); 
    }
  }

  render() {
    return (
        <div className="item">
            <div className="label">{this.props.children}</div>
            {this.state.image_dataUrl&&<div className="uploaded-image" ref={this.container_image}>
                <img src={this.state.image_dataUrl} ref={this.props.inputRef} />
            </div>}
            <input id={this.props.id} className={'uploader-image ' + (this.props.className?this.props.className:null)} type="file" name="file" onChange={this.onChange} />
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(UploaderImage);
