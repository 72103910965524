import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

class FlowTable extends Component {
  constructor(props){
    super(props);
  }

  render() {
    const settlementData = this.props.settlementData;
    const settlement = settlementData.settlement;
    const item = this.props.item;

    const col = item?item.data.col:[];
    
    let content = col.map((colItem,i) => {
      const style = {
        width:colItem.width + "%"
      };

      const rows = colItem.row.map((rowItem)=>{
        const boxHeight = (()=>{
          let height = 0;
          const first = Math.floor(i/2)*2;
          for(let k=first; k<first+2; k++){
            let heightTemp = 0;
            for(let l=0; l<item.data.col[k].row.length; l++){
              heightTemp += item.data.col[k].row[l].height;
            }
            if(heightTemp>height)height = heightTemp;
          }
          return height;
        })();

        const style = {
          height: (rowItem.height/boxHeight*100) + "%"
        };
        const index = rowItem.index;
        const data = settlement[index[0]][index[1]][index[2]][index[3]];
        
        return(<div className={'flowtable-row ' + (rowItem.height<30?"tight":"")} style={style}>
          <div className="cell-title">{data.title}</div>
          {data.number!=undefined&&<div className="cell-number"><span>{data.number} {rowItem.numberSign}</span></div>}
          <div className="cell-value">{data.value.toFixed(1)}</div>
        </div>);
      });

      return (<div className="flowtable-col" style={style}>{rows}</div>);
    });

    if(item&&item.data.type=='split')content = (()=>{
      let content_ = [];
      let cols;
      let height;
      for(let i=0; i<content.length; i++){
        if(i%2===0){
          cols = [];
          height = 0;
        } 

        const heightTemp = (()=>{
          let height = 0;
          for(let j=0; j<item.data.col[i].row.length; j++){
            height += item.data.col[i].row[j].height;
          }
          return height;
        })();

        if(heightTemp>height)height = heightTemp;

        cols.push(content[i]);
        
        if(i%2===1){
          const style = {
            height:height + "%"
          };
          content_.push((()=>{return (<div className="flowtable-row-container" style={style}>{cols}</div>)})());
        } 
      }
      return content_;
    })()

    const style = item?{
      "padding-top":item.data.heightPer+"%"
    }:{};

    let average = item&&item.data?item.data.average:null;

    return (
      <div id="tab-settlement" className="ui-primary ui-fix-subtab bg-green-dark text-black scroll-ver">
        <div className="fix">
            <div className={'fix-inner fix-inner-hor ' + (item&&item.data.type==='split'?'fix-inner-30':'')}>
              <div className={'flowtable ' + (item?item.data.type:"")}>
                <div className="inner" style={style}>
                  <div className="fill-inner">{content}</div>
                </div>
              </div>
              {average&&<div className="flowtable-average text-white">[{settlement[average.index[0]][average.index[1]][average.index[2]][average.index[3]].title} : {settlement[average.index[0]][average.index[1]][average.index[2]][average.index[3]].value}]</div>}
            </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(FlowTable);
