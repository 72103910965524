import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import Function from "../../../functions/Function";
const func = new Function();

class Graph extends Component {
    constructor(props){
        super(props);
        this.state = {
            container:{
                style:{},
                size:{
                    width:0,
                    height:0
                },
                padding:{
                    left:50,
                    right:0,
                    top:30,
                    bottom:50
                }
            },
            graph:{
                area:200,
                barWidth:40
            }
        };

    }

    componentDidMount() {
        let userlist = this.props.userlist;
        userlist = (()=>{
            let list = [];
            for(let prop in userlist){
              if(this.props.filtered_team === "all" || userlist[prop].team === this.props.filtered_team)list.push(userlist[prop]);
            }
            return list;
        })();
        const padding = this.state.container.padding;
        const graph = this.state.graph;
        let width = padding.left + padding.right + userlist.length * graph.area;
        let height = window.innerHeight * 0.75;
        const style = {
            height: height + "px"
        };
        const size = { width, height };

        this.setState({
            ...this.state,
            container:{ 
                ...this.state.container,
                style, size 
            }
        });
    }

    render() {
        let userlist = this.props.userlist;
        userlist = (()=>{
            let list = [];
            for(let prop in userlist){
              if(this.props.filtered_team === "all" || userlist[prop].team === this.props.filtered_team)list.push(userlist[prop]);
            }
            return list;
        })();
        userlist = userlist.map(user => JSON.parse(user.data));
        const chartSetting = this.props.game.team_performance.filter(item=>item.tabName==this.props.subtab)[0];
        const { setting } = chartSetting;

        const container = this.state.container;
        const { size, padding } = container;
        const { area, barWidth } = this.state.graph;
        const period = this.props.filtered_period;

        const height = Math.abs(container.size.height - (padding.top + padding.bottom));
        const div = func.numFix((setting.max - setting.min)/setting.gap);
        const gap = func.numFix(height/div);
        const range = setting.max - setting.min;
        
        const lines = (()=>{
            let rowLines = [];
            const x1 = padding.left;
            const x2 = padding.left + userlist.length * area;

            if(gap>0){
                let y = padding.top;
                for(let row=0; row<=div; row++){
                    rowLines.push((()=>{
                        return (<line className="grid-line grid-line-solid" x1={x1} y1={y} x2={x2} y2={y}></line>);
                    })());
                    y += gap;
                }
            }

            return (
                <Fragment>
                    <line className="grid-line grid-line-solid" x1={x1} y1={padding.top} x2={x1} y2={height+padding.top}></line>
                    <line className="grid-line grid-line-solid" x1={x2} y1={padding.top} x2={x2} y2={height+padding.top}></line>
                    {rowLines}
                </Fragment>
            )
        })(); 

        const stairs = (()=>{
            let stairs = [];
            const x = padding.left - 20;
            
            if(gap>0){
                let y = padding.top + height;
                let value = setting.min;

                if(setting.dir == "reverse"){
                    value = setting.max;
                }

                for(let row=0; row<=div; row++){
                    stairs.push((()=>{
                        return (<text className="unit-text" x={x} y={y}>{value}</text>);
                    })());
                    y -= gap;
                    
                    if(setting.dir == "reverse") value -= setting.gap;
                    else value += setting.gap;
                }
            }

            return stairs
        })(); 

        const userGraphic = userlist.map((user,index)=>{
            let minSize = (Math.round(-setting.min / setting.gap) * gap);
            let datumPoint = setting.datum_point?setting.datum_point:0;
            let datumPointSize = datumPoint?(datumPoint / setting.gap) * gap:0;
            let center = height - (minSize+datumPointSize) + padding.top ;
            let graphX = padding.left + index * area + area*0.5;
            let graphY = (minSize+datumPointSize)==0?center+20:center;

            let bars = [];
            let valueText = [];

            let n = 0;
            for(let c=0; c<setting.col.length; c++){
                let colVal = 0;
                for(let r=0; r<setting.col[c].length; r++){
                    let row = setting.col[c][r];
                    let valArray = user.analysis.filter(item=>item.title == row.source)[0].value[period-1];
                    let val = valArray?valArray[row.value]:null;

                    

                    let tVal = val;
                    if(setting.col[c].length > 1){
                        tVal = val>setting.max?setting.max:val<setting.min?setting.min:val;
                    }
                    let min = setting.min;
                    let max = setting.max;

                    if(setting.dir == "reverse"){
                        tVal = (tVal!=null&&tVal!=undefined)?(setting.max - val) + setting.min:tVal;
                        datumPoint = (setting.max - datumPoint) + setting.min;
                        datumPointSize = (datumPoint / setting.gap) * gap;
                        center = height - (minSize+datumPointSize) + padding.top;
                        graphY = (minSize+datumPointSize)==0?center+20:center;
                    }

                    let fixX = setting.col.length>1?barWidth*0.5:0;
                    let h = Math.abs( (tVal-datumPoint)/range * height);
                    
                    let sumVal = (colVal+tVal);
                    let ratio = (sumVal/range);
                    let y = tVal>datumPoint?ratio * height-datumPointSize:0;

                    
                    let isOver = false;

                    if(sumVal < min){
                        isOver = true;
                        ratio = 0.95;
                        let limit = Math.abs((-min+datumPoint)/range);
                        h = (height*ratio*limit) - Math.abs(func.numFix(colVal/Math.abs(min))*(limit*height) );
                        y = tVal>datumPoint?ratio * (limit*height):0;
                    } 
                    else if(sumVal > max){
                        isOver = true;
                        ratio = 0.97;
                        let limit = ( (max-datumPoint) /range);
                        h = (height*ratio*limit) - Math.abs(func.numFix(colVal/Math.abs(max))*(limit*height) );
                        y = ratio * (limit * height);
                    }
                    
                    let textX = (fixX!=0?fixX*(c==0?-1:1):0) + graphX;
                    let textY = center -  (y - h*0.5);

                    let barX = (fixX!=0?fixX*(c==0?-1:1):0) + graphX - barWidth*0.5;
                    let barY = center - y;

                    let x_ = (fixX!=0?fixX*(c==0?-1:1):0) + graphX - barWidth*0.5;
                    let y_ = center - y;
                    let x1 = x_, y1 = y_, x2 = x_ + barWidth, y2 = y_, x3 = x2, y3 = y_ + h, x4 = x_, y4 = y3;
                    let waveGap = height*0.01;
                    
                    if(tVal < datumPoint){
                        x1 = x_;
                        y1 = y_ + h;

                        x2 = x_ + barWidth;
                        y2 = y_ + h;

                        x3 = x2; 
                        y3 = y_;

                        x4 = x_;
                        y4 = y_;
                        waveGap = -waveGap;
                    }

                    let mx = (x2 + x1)*0.5, my = y1, cx = x_ + (x2 - x1)*0.25, cy = y1-waveGap;
                    if(tVal != undefined && tVal != null){
                        let className = "bar bar-"+n
                        bars.push(( ()=>{
                            if(isOver) return (<Fragment><path className={className} d={"M"+x1+" "+y1+" Q"+cx+" "+cy+" "+mx+" "+my+" T"+x2+" "+y2+" L"+x3+" "+y3+" L"+x4+" "+y4+" Z"} /><path className="value-line" d={"M"+x1+" "+(y1-waveGap)+" Q"+cx+" "+(cy-waveGap)+" "+mx+" "+(my-waveGap)+" T"+x2+" "+(y2-waveGap)} /></Fragment>);
                            else return (<rect className={className} x={barX} y={barY} width={barWidth} height={h}></rect>)
                        } )());
                        valueText.push(( ()=>{
                            return (<text className="value-text" x={textX} y={textY}>{val}</text>)
                        } )());
                    }

                    colVal += tVal;
                    n++;
                }
            }

            return (
                <Fragment>
                    {bars}
                    {valueText}
                    <text className="user-name" x={graphX} y={graphY}>{user.name}</text>
                </Fragment>
            );
        });

        const sourceLabel = setting.col.map((col,index)=>{
            let marks = [];
            let n = index*setting.col.length;
            for(let r=0; r<col.length; r++){
                let markClass = "mark mark-"+n;

                marks.push((()=>{
                    return (
                        <div className="mark-container">
                            <i className={markClass}></i>
                            <span className="mark-text">{col[r].title?col[r].title:col[r].source}</span>
                        </div>
                    )
                })());
                n++;
            }
            return marks;
        });

        const viewBox = "0 0 " + (container.size.width+3) + " " + (container.size.height+3);

        return (
            <div id="admin-graph" className="admin-graph">
                <div className="source-label">{sourceLabel}</div>
                <div className="graph-container" style={container.style}>
                    <svg viewBox={viewBox} width={container.size.width+3} height={container.size.height+3}>
                        {lines}
                        {stairs}
                        {userGraphic}
                    </svg>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Graph);