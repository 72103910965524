import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import Function from "../../../functions/Function";
import SettlementFunc from "../../../functions/SettlementFunc";
/* images */

/* Components */
import Button from '../parts/Button';
import DisplayValue from '../parts/DisplayValue';
import InputCheckbox from '../parts/InputCheckbox';
import InputSelect from '../parts/InputSelect';

/* functions */
import Server from '../../../functions/Server';
const server = new Server();

const { updateIn } = require('immutable');
const func = new Function();
const settlementFunc = new SettlementFunc();

class DisposalProfits extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){}

  confirm = (e) => {
    e.preventDefault();
    if(window.confirm("정말 결산을 마치시겠습니까?\n진행 후 데이터를 되돌릴 수 없습니다")){
        this.props.update(settlementFunc.carryOver(this.props.user, this.props.settlementData));
        this.props.nextPeriod();
        this.props.changeMode("activities");
        this.props.changePage("period-start");
        this.props.deactivateReturn();
        this.props.save();
        this.props.sound.play('turnend');
    }
  }

  profitDisposal = () => {
    this.props.updateSettlementPart({
        profitDisposal: true
    });
  }

  noProfitDisposal = () => {
    this.props.updateSettlementPart({
        profitDisposal: false
    });
  }

  allocation = (e) => {
    const settlementData = this.props.settlementData;
    const settlement = settlementData.settlement;
    
    let noDisposal = settlement["I"][0]["items"][2].value;
    let bonus = settlementData.disposalProfits.bonus;
    let allocation = parseFloat(e.currentTarget.value);
    let carryOver = func.numFix( noDisposal - (bonus + allocation) );

    this.props.updateSettlementPart({
        disposalProfits:{
            ...settlementData.disposalProfits,
            allocation : allocation,
            carryOver : carryOver
        }
    });

  }

  bonus = (e) => {
    const settlementData = this.props.settlementData;
    const settlement = settlementData.settlement;
    
    let noDisposal = settlement["I"][0]["items"][2].value;
    let bonus = parseFloat(e.currentTarget.value);
    let allocation = settlementData.disposalProfits.allocation;
    let carryOver = func.numFix( noDisposal - (bonus + allocation) );

    this.props.updateSettlementPart({
        disposalProfits:{
            ...settlementData.disposalProfits,
            bonus : bonus,
            carryOver : carryOver
        }
    });

  }


  render() {
    const settlementData = this.props.settlementData;

    let noDisposal = settlementData.settlement["I"][0]["items"][2].value;

    // allocation
    let max = func.numFix(noDisposal - settlementData.disposalProfits.bonus);
    if(max<0) max = 0;

    let options = [];
    for(var value=0; value<=max; value=func.numFix(value+0.1)){
        options.push({label:value,value:value});
    }

    let allocation = Object.keys(options).map(function(key){
        return(<option value={options[key]}>{options[key]}</option>)
    });

    // bonus
    let max_bonus = func.numFix(noDisposal - settlementData.disposalProfits.allocation);
    if(max_bonus<0) max_bonus = 0;

    let options_bonus = [];
    for(var value=0; value<=max_bonus; value=func.numFix(value+0.1)){
        options_bonus.push({label:value,value:value});
    }

    let bonus = Object.keys(options_bonus).map(function(key){
        return(<option value={options_bonus[key]}>{options_bonus[key]}</option>)
    });


    return (
        <div className="ui-primary ui-fix-tab text-black scroll-ver">
            <div className="fix">
                <div className="fix-inner">
                    <h3>이익금처분</h3>
                    <div className="content-body">
                        <div className="row item-container">
                            {noDisposal>0?(
                            <Fragment>
                                <InputCheckbox conClassName='col col-50' id="checkbox-no-profit-disposal" onChange={this.noProfitDisposal} checked={!settlementData.profitDisposal} soundplay="button">미처분</InputCheckbox>
                                <InputCheckbox conClassName='col col-50' id="checkbox-profit-disposal" onChange={this.profitDisposal} checked={settlementData.profitDisposal} soundplay="button">처분</InputCheckbox>
                            </Fragment>
                            ):null}
                            <DisplayValue label="당기미처분이익" conClassName='item col col-100' value={noDisposal}></DisplayValue>
                            
                            {settlementData.profitDisposal?(
                            <Fragment>
                                <InputSelect conClassName="col col-50" options={options} onChange={this.allocation} inputRef={el => this.input_allocation = el} value={settlementData.disposalProfits.allocation}>배당</InputSelect>
                                <InputSelect conClassName="col col-50" options={options_bonus} onChange={this.bonus} inputRef={el => this.input_bonus = el} value={settlementData.disposalProfits.bonus}>상여</InputSelect>
                            </Fragment>
                            ):null}
                            <DisplayValue label='차기이월이익' conClassName='item col col-100' value={settlementData.disposalProfits.carryOver}></DisplayValue>
                        </div>
                    </div>
                    <div className="confirm-container text-green row">
                        <div className="item col col-100"><Button onClick={this.confirm}>확인</Button></div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(DisposalProfits);
