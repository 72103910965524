import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import Function from "../../../functions/Function";

/* images */

/* Components */
import Button from '../parts/Button';
import DisplayValue from '../parts/DisplayValue';
import InputSelect from '../parts/InputSelect';

/* functions */
import Server from '../../../functions/Server';
const server = new Server();

const { updateIn } = require('immutable');
const func = new Function();

class FinalCosts extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){}

  confirm = (e) => {
    e.preventDefault();
    this.props.nextTab();
  }

  debtRepayment = (e) => {
    const settlementData = this.props.settlementData;
    const endProcess = settlementData.chart['_endProcess'];
    const _endProcess = settlementData.endProcess;
    const loan = _endProcess.debtRepaymentAmountLoan;
    const bond = _endProcess.debtRepaymentAmountBond;
    const bank = parseFloat(e.target.value);

    let paymentTotal = 0;
    for(let prop in endProcess){
        paymentTotal = func.numFix( paymentTotal + endProcess[prop].value );
    }

    paymentTotal = func.numFix( paymentTotal + (bank+loan+bond) );

    this.props.updateSettlementPart({
        endProcess:{
            ...settlementData.endProcess,
            debtRepaymentAmount:bank,
            paymentTotal:paymentTotal
        }
    });
    this.props.autoSettlement();
  }

  debtRepaymentLoan = (e) => {
    const settlementData = this.props.settlementData;
    const endProcess = settlementData.chart['_endProcess'];

    const _endProcess = settlementData.endProcess;
    const bank = _endProcess.debtRepaymentAmount;
    const bond = _endProcess.debtRepaymentAmountBond;

    const loan = parseFloat(e.target.value);

    let paymentTotal = 0;
    for(let prop in endProcess){
        paymentTotal = func.numFix( paymentTotal + endProcess[prop].value );
    }

    paymentTotal = func.numFix( paymentTotal + (bank+loan+bond) );

    this.props.updateSettlementPart({
        endProcess:{
            ...settlementData.endProcess,
            debtRepaymentAmountLoan:loan,
            paymentTotal:paymentTotal
        }
    });
    this.props.autoSettlement();
}

debtRepaymentBond = (e) => {
    const settlementData = this.props.settlementData;
    const endProcess = settlementData.chart['_endProcess'];

    const _endProcess = settlementData.endProcess;
    const bank = _endProcess.debtRepaymentAmount;
    const loan = _endProcess.debtRepaymentAmountLoan;

    const bond = parseFloat(e.target.value);

    let paymentTotal = 0;
    for(let prop in endProcess){
        paymentTotal = func.numFix( paymentTotal + endProcess[prop].value );
    }

    paymentTotal = func.numFix( paymentTotal + (bank+loan+bond) );

    this.props.updateSettlementPart({
        endProcess:{
            ...settlementData.endProcess,
            debtRepaymentAmountBond:bond,
            paymentTotal:paymentTotal
        }
    });
    this.props.autoSettlement();
}

  render() {
    const settlementData = this.props.settlementData;
    const endProcess = settlementData.chart['_endProcess'];
    const status = this.props.status;
    

    const values = Object.keys(endProcess).map(key => {
        return(<DisplayValue label={endProcess[key].title} conClassName='item col col-50' value={endProcess[key].value}></DisplayValue>);
    });

    const settlement = settlementData.settlement;
    const _endProcess = settlementData.endProcess;

    const amount = (()=>{
        let list = [];
        let max = status.borrowed.value;

        const loan = _endProcess.debtRepaymentAmountLoan;
        const bond = _endProcess.debtRepaymentAmountBond;
        const paymentTotalBefore = _endProcess.paymentTotalBefore;
        const cash =  func.numFix(status.cashBalance.value - (paymentTotalBefore+loan+bond));

        if(max > cash) max = cash>0?cash:0;
        

        for(let i=0; i<=max; i = func.numFix(i+0.1) ){
            list.push({label:i, value:i});
        }
        return list;
    })();

    const amountLoan = (()=>{
        let list = [];
        let max = status.loanBorrowed.value;

        const bank = _endProcess.debtRepaymentAmount;
        const bond = _endProcess.debtRepaymentAmountBond;
        const paymentTotalBefore = _endProcess.paymentTotalBefore;
        const cash =  func.numFix(status.cashBalance.value - (paymentTotalBefore+bank+bond));

        if(max > cash) max = cash>0?cash:0;
        

        for(let i=0; i<=max; i = func.numFix(i+0.1) ){
            list.push({label:i, value:i});
        }
        return list;
    })();

    const amountBond = (()=>{
        let list = [];
        let max = status.bond.value;

        const bank = _endProcess.debtRepaymentAmount;
        const loan = _endProcess.debtRepaymentAmountLoan;
        const paymentTotalBefore = _endProcess.paymentTotalBefore;
        const cash =  func.numFix(status.cashBalance.value - (paymentTotalBefore+bank+loan));

        if(max > cash) max = cash>0?cash:0;
        

        for(let i=0; i<=max; i = func.numFix(i+0.1) ){
            list.push({label:i, value:i});
        }
        return list;
    })();

    return (
        <div className="ui-primary ui-fix-tab text-black scroll-ver">
            <div className="fix">
                <div className="fix-inner">
                    <h3>기말비용정산</h3>
                    <div className="content-body">
                        <div className="row">
                            {values}
                            <InputSelect conClassName="col col-50" options={amount} onChange={this.debtRepayment} inputRef={el => this.input_amount = el} value={_endProcess.debtRepaymentAmount}>차입금 상환</InputSelect>
                            <InputSelect conClassName="col col-50"  options={amountLoan} onChange={this.debtRepaymentLoan} inputRef={el => this.input_amount_loan = el} value={_endProcess.debtRepaymentAmountLoan}>대여금 상환</InputSelect>
                            <InputSelect conClassName="col col-50"  options={amountBond} onChange={this.debtRepaymentBond} inputRef={el => this.input_amount_bond = el} value={_endProcess.debtRepaymentAmountBond}>사채 상환</InputSelect>
                            <DisplayValue label="계" conClassName='item col col-50' className="bg-black text-white" value={_endProcess.paymentTotal}></DisplayValue>
                        </div>
                    </div>
                    <div className="confirm-container text-green row">
                        <div className="item col col-100"><Button onClick={this.confirm} soundplay="turnend">확인</Button></div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(FinalCosts);
