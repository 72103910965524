import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

class InputText extends Component {

  
  constructor(props){
    super(props);
  }

  render() {
    return (
        <div className="item">
            <div className="label">{this.props.children}</div>
            <input id={this.props.id} className={'input-text ' + (this.props.className?this.props.className:null)} type={this.props.type} placeholder={this.props.placeholder}  ref={this.props.inputRef}/>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(InputText);
