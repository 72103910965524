import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';
import Speech from '../../functions/Speech';

class Tab extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){
    this.props.callback(this.props.default);
  }

  tabClick = (e) => {
    this.props.callback(e.currentTarget.getAttribute('tab'));
    this.props.sound.play('button');
  }

  render() {
    const list = this.props.list;
    const tab = this.props[this.props.connect];
    const buttons = list.map(item=>(<button className={tab===item.tabName?'active':''} onClick={this.tabClick} tab={item.tabName}>{item.title}</button>));
    return (
        <nav id={this.props.id} className={this.props.conClassName}>
            <div className={this.props.scroll?"scroll-inner":""}>
            {buttons}
            </div>
        </nav>
    );
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(Tab);
