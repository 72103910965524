import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';


/* images */

/* Components */
import Button from '../parts/Button';
import InputText from '../parts/InputText';

/* functions */
import Sound from '../../../functions/Sound';
import Server from '../../../functions/Server';
import Function from '../../../functions/Function';
const server = new Server();
const func = new Function();

class Login extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){
    var email = func.getCookie("email");
    if(email){
      let this_ = this;
      server.login({email: email},function(result){
          if(result.type === 0){
            this_.props.changePage('signup');
          }
          else if(result.type === 1){
            server.getUserdata(email, function(data){
              //console.log(data);
              this_.props.update(data);
              this_.props.changePage('selectteam');
              func.setCookie("email",email,2);
            });
            server.getGame((group_name,team_number,logo_dataUrl)=>{
              this_.props.setGame(group_name,team_number,logo_dataUrl);
            });
          }
          else if(result.type === 2){
            server.getUserdata(email, function(data){
              //console.log(data);
              this_.props.update(data, false);
              this_.props.changeMode('activities');
              if(data.basic_process_complete) this_.props.changePage('session-start');
              else this_.props.changePage('period-start');
              this_.props.sound.play("turnend");
              func.setCookie("email",email,2);
            });
            server.getGame((group_name,team_number,logo_dataUrl)=>{
              this_.props.setGame(group_name,team_number,logo_dataUrl);
            });
          }
      });
    }
  }

  changeMode = (e) => {
    this.props.changeMode(e.currentTarget.getAttribute('data-val'));
  }

  confirm = (e) => {
    const page = this.props.page;
    e.preventDefault();
    
    let email = this.input_email.value;
    
    this.props.setEmail(email);

    if(page === 'signup'){
      let name = this.input_name.value;
      let company = this.input_company.value;
      this.signup(email,name,company);
    }
    else{
        this.login(email);
    }
  }

  login = (email) => {
      let data = {
          email: email
      };

      const isEmail = this.input_email.validity.valid;
      const this_ = this;
      if(isEmail && email!="" && email!=" ")server.login(data,function(result){
          if(result.type === 0){
            this_.props.changePage('signup');
          }
          else if(result.type === 1){
            server.getUserdata(this_.props.user.email, function(data){
              this_.props.update(data);
              this_.props.changePage('selectteam');
              func.setCookie("email",email,2);
            });
            server.getGame((group_name,team_number,logo_dataUrl)=>{
              this_.props.setGame(group_name,team_number,logo_dataUrl);
            });
          }
          else if(result.type === 2){
            server.getUserdata(this_.props.user.email, function(data){
              this_.props.update(data, false);
              this_.props.changeMode('activities');
              this_.props.changePage('session-start');
              this_.props.sound.play("turnend");
              func.setCookie("email",email,2);
            });
            server.getGame((group_name,team_number,logo_dataUrl)=>{
              this_.props.setGame(group_name,team_number,logo_dataUrl);
            });
          }
      });
      else if(email === "admin"){
        this.props.changeView('admin');
        this.props.changeTab('userlist');
      } 
      

  }

  signup = (email, name, company) => {
    const this_ = this;
      this.props.signup(email,name,company);
      server.getGame((group_name,team_number,logo_dataUrl)=>{
        this_.props.setGame(group_name,team_number,logo_dataUrl);
      });
      func.setCookie("email",email,2);
      this.props.sound.play("turnend");
  }


  render() {
    const page = this.props.page;
    return (
        <div id="page-login" className="ui-card ver-center bg-white text-green">
          <h3>로그인</h3>
          <div className="content-body">
            <div className="item-container">
            <InputText  type="email" placeholder="id@domain.com" inputRef={el => this.input_email = el}>이메일</InputText>
            {page === "signup"?
                <Fragment>
                   <InputText placeholder="이름" inputRef={el => this.input_name = el}>이름</InputText>
                   <InputText placeholder="회사명" inputRef={el => this.input_company = el}>회사명</InputText>
                </Fragment>
            :""}
            </div>
          </div>
          
          <div className="confirm-container"><Button onClick={this.confirm} soundplay="button">확인</Button></div>
          {/* {this.state.message!=""?<div className="message">{this.state.message}</div>:""} */}
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login);
