import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';
import Sound from '../../functions/Sound';
import Speech from '../../functions/Speech';

const speech = new Speech();

class Button extends Component {
  constructor(props){
    super(props);
  }

  onClick = (e) => {
    this.props.onClick(e);

    const speechText = e.currentTarget.getAttribute('speech');
    const soundName = e.currentTarget.getAttribute('sound');
    if(speechText){
      this.props.voice_sound.play(speechText);
      // speech.text(speechText,this.props.utter);
    }
    if(soundName)this.props.sound.play(soundName);
  }

  render() {
    return (
        <button id={this.props.id} className={(this.props.className?this.props.className:"")} onClick={this.onClick} speech={this.props.speech} sound={this.props.soundplay}>
          {this.props.children}
        </button>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Button);