import React, { Component,Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';


/* functions */
import Sound from '../../functions/Sound';
import Server from '../../functions/Server';

/* images */
import icon_activities from '../../assets/icon/activities.svg';
import icon_activities_active from '../../assets/icon/activities-active.svg';
import icon_performance from '../../assets/icon/performance.svg';
import icon_performance_active from '../../assets/icon/performance-active.svg';
import icon_return from '../../assets/icon/return.svg';
import icon_return_active from '../../assets/icon/return-active.svg';
import icon_return_deactive from '../../assets/icon/return-deactive.svg';
/* Components */

const server = new Server();

class NavBar extends Component {

  constructor(props){
    super(props);
  }

  changeMode = (e) => {
    this.props.changeMode(e.currentTarget.getAttribute('data-val'));
    this.props.close_statusbox();
    this.props.sound.play('button');
  }

  return = (e) => {
    const this_ = this;
    if(window.confirm("정말 턴을 되돌리시겠습니까?")){
      server.getUserdataPrev(this.props.user.email, function(data){
        this_.props.sound.play('turnend');
        this_.props.deactivateReturn();
        this_.props.update(data);
        this_.props.changePage('return');
        this_.props.close_statusbox();
      })
    }
  }

  returnToPlay = (e) => {
    const this_ = this;
    if(window.confirm("정말 "+this.props.period+"기로 되돌리시겠습니까?")){
      this.props.changeMode('activities');
    }
  }

  render() {
    const mode = this.props.mode;
    return (
      <div id="nav-bar" className="ui-primary">
        {mode!='login'&&mode!='settlement'&&
        (<Fragment><button id="btn-activities" className={this.props.mode === 'activities'?"active":null}  onClick={this.changeMode} data-val="activities">
          <img className="icon-active" src={icon_activities_active} />
          <img className="icon" src={icon_activities} />
          <p>경영활동</p>
        </button>
        <button id="btn-performance" className={this.props.mode === 'performance'?"active":null} onClick={this.changeMode} data-val="performance">
          <img className="icon-active" src={icon_performance_active} />
          <img className="icon" src={icon_performance} />
          <p>경영실적</p>
        </button>
        <button id="btn-return" onClick={this.return} className={!this.props.return?"deactive":null}>
          <img className="icon-active" src={icon_return_active} />
          <img className="icon" src={icon_return} />
          <img className="icon-deactive" src={icon_return_deactive} />
          <p>턴 되돌리기</p>
        </button></Fragment>)
        }
        {mode==='settlement'&&
        (<button id="btn-return" onClick={this.returnToPlay}>
          <img className="icon-active" src={icon_return_active} />
          <img className="icon" src={icon_return} />
          <img className="icon-deactive" src={icon_return_deactive} />
          <p>돌아가기</p>
        </button>)
        }
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(NavBar);