import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

class BiddingEnd extends Component {
    componentDidMount() {
        this.gotoSelling();
    }

    gotoSelling = () => {
        this.props.setActionType("SellingProducts");
        this.props.changePage("action", false);
    //    console.log(this.props.actionType);
    }

    render() {
        return(<div></div>)
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(BiddingEnd);