import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

class FlowTableValue extends Component {
  constructor(props){
    super(props);
  }

  render() {
    const data = this.props.data;
    const select = this.props.select;
    const displayMode = this.props.displayMode;
    const drawMode = this.props.drawMode;
    const boxMode = this.props.boxMode;
    const numberSign = this.props.numberSign;
    const left = this.props.left;
    const top = this.props.top;
    
    
    const style = {
        left, top
    };
    return (
        <div className={'flowtable-value' + (drawMode?(" mode-"+drawMode):"") + (boxMode?" mode-box":"") + (displayMode?" mode-"+displayMode:"") + (select?" mode-"+select:"")} style={style}>
          {select!="value"&&<div className="flowtable-value-title">{this.props.prefix}{data.title}</div>}
            {data.number!=undefined&&select!="title"&&<div className="flowtable-value-number"><span>({data.number}{numberSign})</span></div>}
          {data.value!=undefined&&select!="title"&&<div className="flowtable-value-value">{data.value.toFixed(1)}{this.props.afterfix}</div>}
          {data.percent!=undefined&&select!="title"&&<div className="flowtable-value-value">{data.percent}%</div>}
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(FlowTableValue);
