class Speech{
    constructor(){
        if(window.speechSynthesis){
            const voices = window.speechSynthesis.getVoices();
            this.voice = voices[0];
            for(var i=0; i<voices.length; i++){
                if(voices[i].lang === 'ko-KR') this.voice = voices[i];
            }
        }
    }
    
    text = (text,utter) =>{
        if(window.speechSynthesis){
            utter.voice = this.voice;
            utter.volume = 0.7; // 0 to 1
            utter.rate = 0.92; // 0.1 to 10
            utter.pitch = 0.98; //0 to 2
            utter.text = text;

            speechSynthesis.cancel();
            speechSynthesis.resume();
            speechSynthesis.speak(utter);
        }
    }
}

export default Speech;
