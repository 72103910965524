import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

import Function from "../../../functions/Function";
import Button from '../parts/Button';
import DisplayValue from '../parts/DisplayValue';

import Speech from '../../../functions/Speech';
import Sound from '../../../functions/Sound';

const speech = new Speech();

const { updateIn, get } = require('immutable');

const func = new Function();

class Action extends Component {
    state = {
        action:null,
        items:[],
        isResponse:false
    }

    componentDidMount() {
        const user = this.props.user;
        const status = user.status;
        const action = this.getAction();

        let items = [];

        this.input_number = [];
        this.input_option = [];
        this.input_optionvalue = [];
        this.input_interestRate = [];

        action.rule.map(
            (rule) => {
                items.push(this.initItem(rule,status));
                
                this.input_number.push(React.createRef());
                this.input_option.push(React.createRef());
                this.input_optionvalue.push(React.createRef());
                this.input_interestRate.push(React.createRef());
            }
        );

        this.setState({
           action:action,
           items:items
        });
    }

    componentDidUpdate(){
        const items = this.state.items;
        const action = this.getAction();
        const user = this.props.state.user;
        const status = user.status;

        for(let i in items){
            if(items[i].optionValue && this.input_optionvalue[i].current){
                this.input_optionvalue[i].current.value = items[i].optionValue;
            } 
            if(items[i].num && this.input_number[i].current){
                this.input_number[i].current.value = items[i].num;
            } 
        }
    }

    getAction = () => {
        const actionType = this.props.actionType;
        const game = this.props.game;
        return game.actions.filter(action => action.name === actionType)[0];
    }

    initItem = (rule,status) => {
        const game = this.props.game;
        const user = this.props.user;
        const item = {};

        if(rule.title) item.name = rule.title;
        else item.name = status[rule.status].name;
        item.max = func.getMax(rule,status,user.period);

        if(rule.type === "bidding" && rule.status === "product" && rule.market){
            item.options = [];
            game.markets.map(market => {
                if( (this.props.tempData && this.props.tempData.marketName &&
                    this.props.tempData.marketName.indexOf(market.name) === -1) || !this.props.tempData || (this.props.tempData && !this.props.tempData.marketName) ){
                    item.options.push({
                        name: market.name + " " + market.product_price,
                        marketName: market.name,
                        value: market.product_price,
                        volume: market.volume
                    });
                }
            });
            item.marketName = item.options[0].marketName;
            item.volume = item.options[0].volume;
            item.option = item.options[0];
            item.optionValues = [];
            for(let i=item.option.value; i>=0.8; i-=0.1){
                item.optionValues.push(parseFloat(i.toFixed(1)));
            }
            item.optionMax = item.optionValue = item.option.value;
            if(item.max>item.volume) item.max = item.volume;


            if(this.props.tempData && this.props.tempData.totalNum){
                let limit = this.props.tempData.sellingAbilityTotal - this.props.tempData.totalNum;
                if(item.max > limit) item.max = limit;
            } 

            if(item.max<0) item.max = 0;

            // console.log("item max "+item.max);

            if(this.props.tempData && this.props.tempData.sellingAbility != undefined && item.max>this.props.tempData.sellingAbility)
            item.max = this.props.tempData.sellingAbility;
        }
        else if(rule.type === "sell" && rule.status === "product" && rule.market){
            item.options = [];
            game.markets.map(market => {
            if( this.props.actionType !== "AdvertisingEffect" || (this.props.tempData && this.props.tempData.marketName &&
                this.props.tempData.marketName.indexOf(market.name) === -1) || !this.props.tempData || (this.props.tempData && !this.props.tempData.marketName) ){
                    item.options.push({
                        name: market.name + " " + market.product_price,
                        marketName: market.name,
                        value: market.product_price,
                        volume: market.volume
                    });
                }
            });
            
            if(this.props.actionType === "AdvertisingEffect")item.marketName = item.options[0].marketName;
            item.volume = item.options[0].volume;
            item.option = item.options[0];
            item.optionMax = item.optionValue = item.option.value;
            if(item.max>item.volume) item.max = item.volume;

            if(this.props.tempData && this.props.tempData.sellingAbility != undefined && item.max>this.props.tempData.sellingAbility)
            item.max = this.props.tempData.sellingAbility;
        }
        else if(rule.type === "buy" && rule.status === "material" && rule.market){
            item.options = [];
            game.markets.map(market => {
                
                if( (this.props.tempData && this.props.tempData.marketName &&
                this.props.tempData.marketName.indexOf(market.name) === -1) || !this.props.tempData || (this.props.tempData && !this.props.tempData.marketName) ){
                    // 재료구입 구입가능한 마켓만 표시
                    if(rule.price || user.status.cashBalance.value >= market.material_price)
                    item.options.push({
                        name: market.name + " " + market.material_price,
                        value: market.material_price,
                        marketName: market.name,
                        volume: market.volume
                    });
                }
            });
            item.marketName = item.options[0].marketName;
            item.option = item.options[0];
            item.max = status.cashBalance.value/item.option.value;
            if(item.max>item.option.volume)item.max = item.option.volume;
        }
        
        item.values = []; 
        
        if(this.props.actionType === "SellingProducts"){
            //console.log("temp data" + this.props.tempData,this.props.state);
            for(let i=rule.min; i<=this.props.tempData.num; i++){
                item.values.push(i);
            }
        }
        else{
            for(let i=rule.min; i<=item.max; i++){
                item.values.push(i);
            }
        }

        //console.log(item.values);


        item.num = rule.min;
        if(rule.type === "bidding" && rule.status === "product" && rule.market){
            
            if(rule.price){
                item.priceResult = item.price = rule.price;
            }
            else{
                item.price = item.optionValue;
                item.priceResult = item.price + status.marketResearch.value*0.2
                if(item.priceResult >item.optionMax) item.priceResult  = item.optionMax;
            }
            
            item.result = func.round(item.num*item.priceResult,1);
        }
        else if(rule.type === "sell" && rule.status === "product" && rule.market){
            
            if(rule.price){
                item.priceResult = item.price = rule.price;
            }
            else{
                item.price = item.optionValue;
                item.priceResult = item.price + status.marketResearch.value*0.2
                if(item.priceResult >item.optionMax) item.priceResult  = item.optionMax;
            }
            
            item.result = func.round(item.num*item.priceResult,1);
        }
        else if(rule.type === "buy" && rule.status === "material" && rule.market){
            item.price = item.option.value-status.merchandiser.value*0.2;
            item.result = func.round(item.num*item.price,1);
        }
        else if(this.props.actionType === "SellingProducts"){
            item.price = this.props.tempData.price;
            item.result = func.round(item.num*item.price,1);
        }
        else{
            if(rule.price) item.price = rule.price;
            else item.price = status[rule.status].price;
            item.result = func.round(item.num*item.price,1);
        } 

        
        return item;
    }

    handleChange = (e) => {
        const num = parseInt(e.target.value);
        const index = e.target.getAttribute("data-index");
        const items = this.state.items;
        const action = this.getAction();
        const user = this.props.state.user;
        const status = user.status;

        let price;
        let priceResult;
        let result;
        if(action.rule[index].type === "bidding" && action.rule[index].status === "product" && action.rule[index].market){
            price = items[index].optionValue;
            priceResult = price + status.marketResearch.value*0.2;
            if(priceResult>items[index].optionMax) priceResult = items[index].optionMax;
            result = func.round(num*priceResult,1);
        } 
        else if(action.rule[index].type === "buy" && action.rule[index].status === "material" && action.rule[index].market){
            price = items[index].option.value-status.merchandiser.value*0.2;
            result = func.round(num*price,1);
        }
        else if(this.props.actionType === "SellingProducts"){
            price = this.props.tempData.price;
            result = func.round(num*price,1);
        }
        else{
            price = items[index].price;
            
            if(action.rule[index].type !== "convert" && action.rule[index].status === "product"){
                if(action.rule[index].price){
                    priceResult = price;
                }
                else{
                    priceResult = price + status.marketResearch.value*0.2;
                    if(priceResult>items[index].optionMax) priceResult = items[index].optionMax;
                }
                result = func.round(num*priceResult,1);
            }
            else result = func.round(num*price,1);
        } 

        let newItems = updateIn(
            items, 
            [index, 'num'], 
            val=>num
        );

        newItems = updateIn(
            newItems, 
            [index, 'result'], 
            val=>result
        );

        this.setState({
            items:newItems
        });
    }

    handleChangeOption = (e) => {
        const index = e.target.getAttribute("data-index");
        const items = this.state.items;
        const action = this.getAction();
        const user = this.props.user;
        const status = user.status;

        let change = {};

        if(action.rule[index].type === "bidding" && action.rule[index].status === "product" && action.rule[index].market){
            let selectedIndex = this.input_option[index].current.selectedIndex;
            change.marketName = this.input_option[index].current.options[selectedIndex].getAttribute("data-market-name");

            change.optionMax = parseFloat(e.target.value);;
            change.optionValue = items[index].optionValue;
            if(change.optionValue>change.optionMax)change.optionValue=change.optionMax;

            change.optionValues = [];
            for(let i=change.optionMax; i>=0.8; i-=0.1){
                change.optionValues.push(parseFloat(i.toFixed(1)));
            }

            let market = items[index].options.filter(market=>parseFloat(market.value)==change.optionMax)[0];
            change.volume = market.volume;
            change.max = func.getMax(action.rule[index],status);
            if(change.max>change.volume) change.max=change.volume;

            if(this.props.tempData && this.props.tempData.totalNum){
                let limit = this.props.tempData.sellingAbilityTotal - this.props.tempData.totalNum;
                if(change.max>limit) change.max = limit;
            } 
            if(change.max<0) change.max = 0;

            //console.log("change.max "+change.max);

            if(this.props.tempData && this.props.tempData.sellingAbility != undefined && change.max>this.props.tempData.sellingAbility)
            change.max = this.props.tempData.sellingAbility;
            
            change.values = []; 
            for(let i=action.rule[index].min; i<=change.max; i++){
                change.values.push(i);
            }

            change.num = items[index].num;
            if(change.num>change.max) change.num = change.max;
            
            if(action.rule[index].price){
                change.priceResult = change.price = action.rule[index].price;
            } 
            else{
                change.price = change.optionValue;
                change.priceResult = change.price + status.marketResearch.value*0.2;
            } 
            if(change.priceResult>change.optionMax) change.priceResult = change.optionMax;
            change.result = func.round(change.num*change.priceResult,1);
        } 
        else if(action.rule[index].type === "sell" && action.rule[index].status === "product" && action.rule[index].market){
            change.optionMax = parseFloat(e.target.value);
            change.optionValue = items[index].optionValue;
            if(change.optionValue>change.optionMax)change.optionValue=change.optionMax;

            let market = items[index].options.filter(market=>parseFloat(market.value)==change.optionMax)[0];
            change.volume = market.volume;
            change.max = func.getMax(action.rule[index],status);
            if(change.max>change.volume) change.max=change.volume;

            if(this.props.actionType === "AdvertisingEffect") change.marketName = market.marketName;

            if(this.props.tempData && this.props.tempData.sellingAbility != undefined && change.max>this.props.tempData.sellingAbility)
            change.max = this.props.tempData.sellingAbility;
            
            change.values = []; 
            for(let i=action.rule[index].min; i<=change.max; i++){
                change.values.push(i);
            }

            change.num = items[index].num;
            if(change.num>change.max) change.num = change.max;

            if(action.rule[index].price){
                change.priceResult = change.price = action.rule[index].price;
            } 
            else{
                change.price = change.optionValue;
                change.priceResult = change.price + status.marketResearch.value*0.2;
            } 
            if(change.priceResult>change.optionMax) change.priceResult = change.optionMax;
            change.result = func.round(change.num*change.priceResult,1);
        } 
        else if(action.rule[index].type === "buy" && action.rule[index].status === "material" && action.rule[index].market){
            
            let option = items[index].options.filter(option => option.value === parseFloat(e.target.value))[0];
            let selectedIndex = this.input_option[index].current.selectedIndex;
            change.marketName = this.input_option[index].current.options[selectedIndex].getAttribute("data-market-name");
            change.option = option;
            change.price = option.value-status.merchandiser.value*0.2;
            change.max = status.cashBalance.value/option.value;
            if(change.max>option.volume)change.max = option.volume;

            change.values = []; 
            for(let i=action.rule[index].min; i<=change.max; i++){
                change.values.push(i);
            }

            change.num = items[index].num;
            if(change.num>change.max) change.num = change.max;
            
            change.result = func.round(change.num*change.price,1);
        } 
        
        //this.input_number.current.value = change.num;
        
        let newItems = updateIn(
            items, 
            [index, 'name'], 
            val=>val
        );

        Object.keys(change).map(function(key) {
            newItems = updateIn(
                newItems, 
                [index, key], 
                val => change[key]
            );
        });

        this.setState({
            items:newItems
        });
    }

    handleChangeOptionValue = (e) => {
        const index = e.target.getAttribute("data-index");
        const items = this.state.items;
        const action = this.getAction();
        const status = this.props.state.user.status;

        let change = {};
        change.optionValue = parseFloat(e.target.value);
        change.num = items[index].num;
        if(change.num>items[index].max) change.num = items[index].max;
        change.price = change.optionValue;
        
        // 마켓리서치 적용
        change.priceResult = change.price + status.marketResearch.value*0.2;
        if(change.priceResult>items[index].optionMax)change.priceResult = items[index].optionMax;

        change.result = func.round(change.num*change.priceResult,1);
        
        //this.input_number.current.value = change.num;

        let newItems = updateIn(
            items, 
            [index, 'optionValue'], 
            val=>change.optionValue
        );

        Object.keys(change).map(function(key) {
            newItems = updateIn(
                newItems, 
                [index, key], 
                val => change[key]
            );
        });

        this.setState({
            items:newItems
        });
    }

    back = () => {
        if(this.props.actionType === "SellingProducts") this.props.changePage("backto-bidding",false);
        else if( (this.props.actionType === "Bidding" || this.props.actionType === "BiddingOthers") && this.props.tempData && this.props.tempData.marketName ){
            this.props.setReplay({replayText:"다른 마켓에서 판매", finishText:"판매 끝내기"});
            this.props.changePage('replay');
        }
        else this.props.back();

        this.props.sound.play('back');
    }

    submit = (e) => {
        e.preventDefault();
        // console.log("submit",this.props.tempData);

        if(this.props.actionType === "SellingProducts"){
            const market = this.props.tempData.marketNameTemp;
            const num = this.state.items[0].num;
            this.props.voice_sound.play(market + "에서 제품을 판매하였습니다.");
            //speech.text(market + " " + num + "개",this.props.utter);
        }

        if(this.props.actionType === "Bidding" || this.props.actionType === "BiddingOthers"){
            this.setState({isResponse:true});
            this.props.sound.play('button');
        }
        else{
            if(this.props.actionType === "PurchasingMaterials") this.setPurchasingData();
            this.apply();

            if(this.props.actionType === "SellingProducts" || this.props.actionType === "AdvertisingEffect" ){
                this.props.setReplay({replayText:"다른 마켓에서 판매", finishText:"판매 끝내기"});
                this.props.changePage("replay");
            } 
            else if(this.props.actionType === "PurchasingMaterials"){
                this.props.setReplay({replayText:"다른 마켓에서 구입", finishText:"구입 끝내기"});
                this.props.changePage("replay");
            } 
            else this.props.changePage(this.props.pageNext);

            this.props.sound.play('turnend');
        } 
        //console.log(this.props.tempDate);
    }

    winningBid = () => {
        this.setBiddingData();
        this.props.changePage("bidding-end",false);
        //console.log(this.props.tempDate);
    }

    miscarriage = () => {
        this.applyMiscarriage();
        this.props.setActionType("SellingProducts");
        this.props.setReplay({replayText:"다른 마켓에서 판매", finishText:"판매 끝내기"});
        this.props.changePage("replay");
    }

    closePopup = () => {
        this.setState({isResponse:false});
    }

    applyMiscarriage = () => {
        const items = this.state.items;
        const status = this.props.status;

        let tempData = {};

        if(this.props.tempData){
            if(this.props.tempData.sellingAbility != undefined ) tempData.sellingAbility = this.props.tempData.sellingAbility;
            if(this.props.tempData.marketName != undefined ) tempData.marketName = this.props.tempData.marketName.concat();
            if(this.props.tempData.totalNum != undefined ) tempData.totalNum = this.props.tempData.totalNum;
        }

        
        items.map(
            (item) => {
                if(!tempData.num) tempData.num  = 0;
                tempData.num += item.num;

                if(!tempData.totalNum) tempData.totalNum  = 0;
                tempData.totalNum += item.num;

                if(!tempData.price) tempData.price  = 0;
                tempData.price += item.priceResult;

                if(!tempData.marketNameTemp) tempData.marketNameTemp  = "";
                tempData.marketNameTemp = item.marketName;

                if(this.props.actionType === "Bidding") tempData.type = "first";
                else if(this.props.actionType === "BiddingOthers") tempData.type = "others";

                if(!tempData.sellingAbilityTotal)tempData.sellingAbilityTotal = func.getSellingAbility(status);
            }
        );

        if(tempData 
            && (tempData.sellingAbility === null 
            || tempData.sellingAbility === undefined)) tempData.sellingAbility = func.getSellingAbility(status);
        
        if(tempData.sellingAbility>status.product.value) tempData.sellingAbility = status.product.value;
        tempData.sellingAbility -= tempData.num;

        if(!tempData.marketName) tempData.marketName  = [];
        tempData.marketName.push(tempData.marketNameTemp);

        this.props.setTempData(tempData);
    }

    applySelling = () => {
        const status = this.props.status;

        let tempData = {...this.props.tempData};
        if(tempData 
            && (tempData.sellingAbility === null 
            || tempData.sellingAbility === undefined)) tempData.sellingAbility = func.getSellingAbility(status);
        
        if(tempData.sellingAbility>status.product.value) tempData.sellingAbility = status.product.value;
        tempData.sellingAbility -= tempData.num;

        if(this.props.tempData.marketName != undefined ) tempData.marketName = this.props.tempData.marketName.concat();

        if(!tempData.marketName) tempData.marketName  = [];
        tempData.marketName.push(tempData.marketNameTemp);

        this.props.setTempData(tempData);
    }

    applyRelatedSelling = (item,rule) => {
        const status = this.props.status;

        let tempData = {};
        if(this.props.tempData) tempData = {...this.props.tempData};

        if(tempData 
            && (tempData.sellingAbility === null 
            || tempData.sellingAbility === undefined)) tempData.sellingAbility = func.getMax(rule,status);
        
        if(this.props.tempData && this.props.tempData.marketName != undefined ) tempData.marketName = this.props.tempData.marketName.concat();

        if(!tempData.marketName) tempData.marketName  = [];
        tempData.marketName.push(item.marketName);

        //console.log("items! " + item.marketName);
        tempData.sellingAbility -= item.num;


        //console.log("tempData.sellingAbility " + tempData.sellingAbility);

        this.props.setTempData(tempData);
    }

    setBiddingData = () => {
        const items = this.state.items;
        const status = this.props.state.user.status;

        //console.log("====================");
        //console.log("setTempData");
        //console.log(this.props.tempData);
        
        let tempData = {};

        if(this.props.tempData){
            if(this.props.tempData.sellingAbility != undefined ) tempData.sellingAbility = this.props.tempData.sellingAbility;
            if(this.props.tempData.marketName != undefined ) tempData.marketName = this.props.tempData.marketName.concat();
            if(this.props.tempData.totalNum != undefined ) tempData.totalNum = this.props.tempData.totalNum;
        }

        //console.log(tempData);
        //console.log("====================");
        
        items.map(
            (item) => {
                if(!tempData.num) tempData.num  = 0;
                tempData.num += item.num;

                if(!tempData.totalNum) tempData.totalNum  = 0;
                tempData.totalNum += item.num;

                if(!tempData.price) tempData.price  = 0;
                tempData.price += item.priceResult;

                if(!tempData.marketNameTemp) tempData.marketNameTemp  = "";
                tempData.marketNameTemp = item.marketName;

                if(this.props.actionType === "Bidding") tempData.type = "first";
                else if(this.props.actionType === "BiddingOthers") tempData.type = "others";

                if(!tempData.sellingAbilityTotal)tempData.sellingAbilityTotal = func.getSellingAbility(status);
            }
        );

        this.props.setTempData(tempData);
    }

    setPurchasingData = () => {
        const items = this.state.items;
        const action = this.getAction();
        const status = this.props.state.user.status;

        //console.log("====================");
        //console.log("setTempData");
        //console.log(this.props.tempData);
        
        let tempData = {};

        if(this.props.tempData){
            if(this.props.tempData.marketName != undefined ) tempData.marketName = this.props.tempData.marketName.concat();
        }

        //console.log(tempData);
        //console.log("====================");
        
        items.map(
            (item) => {
                if(!tempData.marketName) tempData.marketName  = [];
                tempData.marketName.push(item.marketName);
            }
        );

        if(tempData.marketName){
            let market = tempData.marketName[tempData.marketName.length-1]
            this.props.voice_sound.play(market + "에서 재료를 구입하였습니다.");
        }

        this.props.setTempData(tempData);
    }

    

    apply = () => {
        const items = this.state.items;
        const action = this.getAction();
        const user = this.props.state.user;
        const status = user.status;

        let newUser = updateIn(
            user, 
            ['status', 'cashBalance', 'value'], 
            val => status.cashBalance.value
        );
        
        let change = {};
        change.cashBalance = status.cashBalance.value;

        let retirement = {};
        let interestRate = {};
        let machine = {};
        let record = {};

        action.rule.map(
            (rule,i) => {
                if(items[i].num)items[i].num = Math.round(items[i].num); // 소수점처리 대비
                if(rule.recordType){
                    if(!record[rule.recordType]){
                        record[rule.recordType] = {};
                        if(items[i].result) record[rule.recordType].value = func.round(items[i].result,1);
                        if(items[i].num) record[rule.recordType].number = items[i].num;
                    } 
                    else{
                        if(!record[rule.recordType].value) record[rule.recordType].value = 0;
                        if(!record[rule.recordType].number) record[rule.recordType].number = 0;

                        if(items[i].result) record[rule.recordType].value += func.round(items[i].result,1);
                        if(items[i].num) record[rule.recordType].number += items[i].num;
                    }
                } 
                if(rule.type === "buy" || rule.type === "related_buy"){
                    change.cashBalance = func.numFix(change.cashBalance - items[i].result);

                    if(!change[rule.status]) change[rule.status] = status[rule.status].value;
                    change[rule.status] += items[i].num;
                    change[rule.status] = Math.round(change[rule.status]); // 소수점처리 대비

                    if(rule.status === "worker" || rule.status === "salesman"){
                        retirement[rule.status] = status[rule.status].retirement - items[i].num;
                        if(retirement[rule.status]<0) retirement[rule.status] = 0;
                    }

                    if(rule.status === "machinePart" || rule.status === "machineNormal" || rule.status === "machineLarge"){
                        machine[rule.status] = [];
                        for(var idx=0; idx<status[rule.status].possesion.length; idx++){
                            machine[rule.status].push(status[rule.status].possesion[idx]);
                        }
                        for(var idx=0; idx<items[i].num; idx++){
                            machine[rule.status].push(status[rule.status].price);
                        }
                    }
                }
                else if(rule.type === "convert"){
                    change.cashBalance = func.numFix(change.cashBalance - items[i].result);

                    if(func.isEmpty(change[rule.status])) change[rule.status] = status[rule.status].value;
                    change[rule.status] += items[i].num;
                    change[rule.status] = Math.round(change[rule.status]);  // 소수점처리 대비

                    if(func.isEmpty(change[rule.related])) change[rule.related] = status[rule.related].value;
                    change[rule.related] -= items[i].num;
                    change[rule.related] = Math.round(change[rule.related]);  // 소수점처리 대비
                }
                else if(rule.type === "sell"){
                    change.cashBalance = func.numFix(change.cashBalance + items[i].result);

                    if(!change[rule.status]) change[rule.status] = status[rule.status].value;
                    change[rule.status] -= items[i].num;
                    change[rule.status] = Math.round(change[rule.status]);  // 소수점처리 대비

                    
                    if(rule.status === "product"){
                        if(!change.sales) change.sales = status.sales.value;
                        change.sales += func.round(items[i].result,1);
                        if(!change.salesNumber)change.salesNumber = status.salesNumber.value;
                        change.salesNumber += items[i].num;

                        if(this.props.actionType === "SellingProducts") this.applySelling(items[i].num);
                        if(rule.related) this.applyRelatedSelling(items[i],rule);
                    }

                    if(rule.status === "machinePart" || rule.status === "machineNormal" || rule.status === "machineLarge"){
                        machine[rule.status] = [];
                        
                        for(var idx=0; idx<status[rule.status].possesion.length; idx++){
                            machine[rule.status].push(status[rule.status].possesion[idx]);
                        }
                        //console.log( status[rule.status].possesion );
                        //console.log( machine[rule.status]);
                        let bookPrice = 0;
                        for(var idx=0; idx<items[i].num; idx++){
                        //    console.log(idx);
                        //    console.log(machine[rule.status][idx]);

                            bookPrice += machine[rule.status][idx];
                        }
                        //console.log(rule.status);
                        //console.log(bookPrice);
                        if(!record["_bookPrice"]) record["_bookPrice"] = {value:0};
                        record["_bookPrice"].value += bookPrice;
                        //console.log(record["_bookPrice"]);
                        machine[rule.status].splice(0,items[i].num);
                    }
                }
                else if(rule.type === "borrow"){
                    let interest;
                    if(user.period<=3) interest = items[i].result*0.1;
                    else interest = items[i].result*0.05;

                    record["S"] = {value:interest};
                    change.cashBalance = func.numFix(change.cashBalance + items[i].result-interest);

                    if(!change[rule.status]) change[rule.status] = status[rule.status].value;
                    change[rule.status] += items[i].num;
                }
                else if(rule.type === "specialBorrow" || rule.type === "loanBorrow"){
                    let interestRate_ = func.round(this.input_interestRate[i].current.value,2);

                    let interest = func.numFix(items[i].result*interestRate_);

                    if(!record["S"])record["S"] = {value:interest};
                    else record["S"].value = func.numFix(record["S"].value+interest);

                    change.cashBalance =  func.numFix(change.cashBalance + (items[i].result-interest));

                    interestRate[rule.status] = interestRate_;

                    if(!change[rule.status]) change[rule.status] = status[rule.status].value;
                    change[rule.status] += items[i].num;
                }
                else if(rule.type === "loan"){
                    let interestRate_ = func.round(this.input_interestRate[i].current.value,2);
                    let interest = items[i].result*interestRate_;
                    record["C"] = {value:interest};
                    change.cashBalance = func.numFix(change.cashBalance - (items[i].result-interest));

                    interestRate[rule.status] = interestRate_;

                    if(!change[rule.status]) change[rule.status] = status[rule.status].value;
                    change[rule.status] += items[i].num;
                }
                else if(rule.type === "loanWithdrawal"){
                    if(!change.loanLended) change.loanLended = status["loanLended"].value;
                    change.loanLended -= func.round(items[i].result,1);

                    if(!change[rule.status]) change[rule.status] = status[rule.status].value;
                    change[rule.status] += items[i].num;
                }

                change.cashBalance = func.numFix(change.cashBalance);
            }
        );

        if(change.sales) change.sales = func.round(change.sales,1);

        Object.keys(record).map(function(key) {
            if(record[key].value && newUser.history[key].value){
                newUser = updateIn(
                    newUser, 
                    ['history', key, 'value'], 
                    val => [...val, record[key].value]
                );

                let hIndex = newUser.historyIndex[key].value.concat();
                if(hIndex[user.session_count]===undefined)hIndex[user.session_count] = record[key].value;
                else hIndex[user.session_count] += record[key].value;
                newUser = updateIn(
                    newUser, 
                    ['historyIndex', key, 'value'], 
                    val => hIndex
                );
            }
            
            if(record[key].number && newUser.history[key].number){
                newUser = updateIn(
                    newUser, 
                    ['history', key, 'number'], 
                    val => [...val, record[key].number]
                );

                let hIndex = newUser.historyIndex[key].number.concat();
                if(hIndex[user.session_count]===undefined)hIndex[user.session_count] = record[key].number;
                else hIndex[user.session_count] += record[key].number;
                newUser = updateIn(
                    newUser, 
                    ['historyIndex', key, 'number'], 
                    val => hIndex
                );
            }
        });

        Object.keys(change).map(function(key) {
            newUser = updateIn(
                newUser, 
                ['status', key, 'value'], 
                val => change[key]
            );
        });

        Object.keys(interestRate).map(function(key) {
            newUser = updateIn(
                newUser, 
                ['status', key, 'interestRate'], 
                val => interestRate[key]
            );
        });

        Object.keys(retirement).map(function(key) {
            newUser = updateIn(
                newUser, 
                ['status', key, 'retirement'], 
                val => retirement[key]
            );
        });

        Object.keys(machine).map(function(key) {
            newUser = updateIn(
                newUser, 
                ['status', key, 'possesion'], 
                val => machine[key]
            );
        });
        this.props.update(newUser);
    }

    render() {
        const user = this.props.user;
        const status = user.status;

        let actionTitle = "";
        if(this.state.action) actionTitle = (<h3>{this.state.action.actionTitle?this.state.action.actionTitle:this.state.action.title}</h3>);

        const items = this.state.items.map(
            (item,i) => (
                <div key={item.name} className="content-body">
                    {this.state.items.length>1?<h3>{item.name}</h3>:null}
                    <div className="row">
                    <div className={'item'+((this.props.actionType === "Bidding" || this.props.actionType === "BiddingOthers")?' col col-50':"")}>
                        {item.options?<div className="label">마켓</div>:""}
                        {item.options?
                            <select className="input-select" onChange={this.handleChangeOption} data-index={i} ref={this.input_option[i]}>
                                {item.options.map((option,j)=>{return <option key={option.name} value={option.value} data-market-name={option.marketName} data-index={j} data-volume={option.volume}>{option.name}</option> })}
                            </select>:""
                        }
                    </div>
                    <div className={'item'+((this.props.actionType === "Bidding" || this.props.actionType === "BiddingOthers")?' col col-50':"")}>
                        {item.optionValues?<div className="label">가격</div>:""}
                        {item.optionValues?
                            <select className="input-select" onChange={this.handleChangeOptionValue} data-index={i} ref={this.input_optionvalue[i]}>
                                    {item.optionValues.map(optionValue=>{return <option key={optionValue} value={optionValue}>{optionValue}</option> })}
                            </select>:""
                        }
                    </div>
                    {this.props.actionType === "Bidding" || this.props.actionType === "BiddingOthers"
                        ?<div className="item col col-100">
                            <div className="label">제시 금액</div>
                            <div className="value">{
                                this.props.actionType === "Bidding"?
                                func.round(item.price-this.props.state.user.status.RND.value*0.2-0.2,1):
                                func.round(item.price-this.props.state.user.status.RND.value*0.2,1)
                            }</div>
                        </div>
                        :""
                    }
                    {this.state.action.rule[i].limit!=1&&this.state.action.rule[i].max!=1?<div className="item col col-100">
                        <div className="label">{this.props.actionType === "BankBorrowing"||this.props.actionType === "SpecialBorrowing"?"금액":((this.props.actionType === "RecruitmentWorker"||this.props.actionType === "RecruitmentSalesman")?"인원":"개수")}</div>
                        <select className="input-select" onChange={this.handleChange} data-index={i} ref={this.input_number[i]} >
                            {item.values.map(value=>{return <option key={value} value={value}>{value}</option> })}
                        </select>
                    </div>:null}
                    {this.props.actionType !== "BankBorrowing"?<DisplayValue label={this.props.actionType === "Bidding" || this.props.actionType === "BiddingOthers" || this.props.actionType === "SellingProducts"?'매출':this.props.actionType === "bondBorrowing"?"금액":"비용"} conClassName='item col col-100' value={item.result}></DisplayValue>:""}
                    {this.props.actionType === "Loan" || this.props.actionType === "bondBorrowing" || this.props.actionType === "loanBorrowing"
                        ?<div className="item col col-100">
                            <div className="label">이자율</div>
                            <select className="input-select" data-index={i} ref={this.input_interestRate[i]}>
                                    {(()=>{
                                        let options = [];
                                        for(let rate=0;rate<=0.2;rate=func.round(rate+0.01,2)){
                                            options.push( ( ()=>{
                                                return (<option key={rate} value={rate}>{func.round(rate*100,0)}%</option>) 
                                            } )() );
                                        }
                                        return options;
                                    })()}
                            </select>
                        </div>
                        :""
                    }
                    </div>
                </div>
                )
        );

        return (
            <Fragment>
            <div id="page-action" className="ui-primary ui-fix-activities text-black scroll-ver">
                <div className="fix">
                    <div className="fix-inner">
                        {actionTitle}
                        {items}
                        <div className="confirm-container text-green row">
                            {this.props.actionType !== "SellingProducts"&&<div className="item col col-50"><Button onClick={this.back}>뒤로가기</Button></div>}
                            <div className={"item col "+ (this.props.actionType === "SellingProducts"?"col-100":"col-50")}><Button onClick={this.submit}>{this.props.actionType === "Bidding"||this.props.actionType === "BiddingOthers"?"응찰":"확인"}</Button></div>
                        </div>
                    </div>
                </div>
            </div>
            {this.state.isResponse?
            <div id="popup-response" className="ui-primary ui-fix-activities text-white bg-green-dark scroll-ver">
                <div className="fix">
                    <div className="fix-inner">
                        <div id="response-container">
                            <div id="response-price-container">
                                <div id="response-price">{this.state.items[0].price.toFixed(1)}</div>
                                <div id="response-price-sub-container">
                                    {this.props.actionType === "Bidding"?<div className="response-price-sub">
                                        <span>선</span><span>-0.2</span>
                                    </div>:null}
                                    {status.RND.value>0?<div className="response-price-sub">
                                        <span>연구개발</span><span>-{func.numFix(status.RND.value*0.2).toFixed(1)}</span>
                                    </div>:null}
                                    <div id="competitive-price" className="response-price-sub">
                                        <span>경쟁가격</span><span>{this.props.actionType === "Bidding"?
                                        func.round(this.state.items[0].price-status.RND.value*0.2-0.2,1).toFixed(1):
                                        func.round(this.state.items[0].price-status.RND.value*0.2,1).toFixed(1)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="confirm-container text-white row">
                                <div className="item col col-50"><Button onClick={this.miscarriage} soundplay="back">유찰</Button></div>
                                <div className="item col col-50"><Button onClick={this.winningBid} soundplay="turnend">낙찰</Button></div>
                                {/* <div className="item col col-100"><Button onClick={this.closePopup} soundplay="back">뒤로가기</Button></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :null}
            </Fragment>
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Action);