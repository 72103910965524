class History{
    constructor(carryOver){
        if(!carryOver){
            carryOver = {};
            carryOver.C = {value:0};
            carryOver.E = {value:0};
            carryOver.F = {value:0};
            carryOver.H = {value:0};
            carryOver.I = {value:0};
            carryOver.L = {value:0, number:0};
            carryOver.M = {value:0, number:0};
            carryOver.N = {value:0, number:0};
            carryOver.R = {value:0, number:0};
            carryOver.S = {value:0};
            carryOver.U = {value:0};
            carryOver.V = {value:0};
            carryOver._profit = {value:0};
            carryOver._PQ = {value:0};
        }

        const history =  {
            "A":{
                title:"대여금회수",
                value:[]
            },
            "B":{
                title:"기계매각",
                value:[]
            },
            "C":{
                title:"영업외수입",
                value:[],
                carryOver:carryOver.C
            },
            "D":{
                title:"수취보험금",
                value:[]
            },
            "E":{
                title:"차입금",
                value:[],
                carryOver:carryOver.E
            },
            "F":{
                title:"자본금",
                value:[],
                carryOver:carryOver.F
            },
            "G":{
                title:"매출",
                number:[],
                value:[]
            },

            "H":{
                title:"대여금",
                value:[],
                carryOver:carryOver.H
            },
            "I":{
                title:"기계공구",
                value:[],
                carryOver:carryOver.I
            },
            "J":{
                title:"노무비",
                value:[]
            },
            "K":{
                title:"제조경비",
                value:[]
            },

            "L":{
                title:"투입비",
                number:[],
                value:[],
                carryOver:carryOver.L
            },
            "M":{
                title:"완성비",
                number:[],
                value:[],
                carryOver:carryOver.M
            },
            "N":{
                title:"재료비",
                number:[],
                value:[],
                carryOver:carryOver.N
            },
            "O":{
                title:"상품",
                number:[],
                value:[]
            },

            "P":{
                title:"판매비",
                value:[]
            },
            "Q":{
                title:"일반관리비",
                value:[]
            },
            "R":{
                title:"연구개발비",
                number:[],
                value:[],
                carryOver:carryOver.R
            },
            "S":{
                title:"영업외비용",
                value:[],
                carryOver:carryOver.S
            },

            "T":{
                title:"차입금상환",
                value:[]
            },
            "U":{
                title:"배당·상여",
                value:[],
                carryOver:carryOver.U
            },
            "V":{
                title:"납세",
                value:[],
                carryOver:carryOver.V
            },
            "_bookPrice":{
                title:"매각시장부가",
                value:[]
            },
            "_profit":{
                title:"이월이익",
                carryOver:carryOver._profit
            },
            "_PQ":{
                title:"매출액",
                carryOver:carryOver._PQ
            }

        };

        return history;
    }
}

export default History;