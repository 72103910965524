class Status{
    constructor(){
        const status =  {
            cashBalance:{
                name:"현금잔액",
                value:0,
                visible:true
            },
            equityCapital:{
                name:"자기자본",
                value:0,
                visible:true
            },
            sales:{
                name:"매출액",
                value:0,
                visible:true
            },
            salesNumber:{
                name:"매출개수",
                value:0,
                visible:true
            },
            product:{
                name:"제품",
                value:0,
                price:0.1,
                visible:true
            },
            workInProcess:{
                name:"재공품",
                value:0,
                price:0.2,
                visible:true
            },
            material:{
                name:"재료",
                value:0,
                price:2.0,
                visible:true
            },
            machinePart:{
                name:"부속품",
                value:0,
                price:2.0,
                depreciation: 0.2,
                visible:false,
                possesion:[]
            },
            machineNormal:{
                name:"보통기계",
                value:0,
                price:10.0,
                depreciation: 1.0,
                visible:false,
                possesion:[]
            },
            machineLarge:{
                name:"대형기계",
                value:0,
                price:20.0,
                depreciation: 2.0,
                visible:false,
                possesion:[]
            },
            worker:{
                name:"워커",
                value:0,
                retirement:0,
                price:0.5,
                visible:false
            },
            salesman:{
                name:"세일즈맨",
                value:0,
                retirement:0,
                price:0.5,
                visible:false
            },
            advertising:{
                name:"광고",
                value:0,
                price:1.0,
                visible:false
            },
            RND:{
                name:"연구개발",
                value:0,
                price:2.0,
                visible:false
            },
            insurance:{
                name:"보험",
                value:0,
                price:0.2,
                visible:false
            },
            PAC:{
                name:"PAC 생산성",
                value:0,
                price:1.0,
                visible:false
            },
            merchandiser:{
                name:"머천다이저",
                value:0,
                price:1.0,
                visible:false
            },
            marketResearch:{
                name:"마켓리서치",
                value:0,
                price:1.0,
                visible:false
            },
            borrowed:{
                name:"차입금",
                value:0,
                price:1.0,
                visible:false
            },
            loanLended:{
                name:"대여금(채권)",
                value:0,
                price:1.0,
                interestRate:0.0,
                visible:false,
            },
            loanBorrowed:{
                name:"대여금(채무)",
                value:0,
                price:1.0,
                interestRate:0.0,
                visible:false,
            },
            bond:{
                name:"사채",
                value:0,
                price:1.0,
                interestRate:0.0,
                visible:false,
            },
            fire:{
                name:"화재",
                value:0,
                visible:false
            },
            mistake:{
                name:"제조미스",
                value:0,
                visible:false
            },
            theft:{
                name:"도난",
                value:0,
                visible:false
            }
        };

        return status;
    }
}

export default Status;