class TermProcessing{
    constructor(){
        return {
            employeePay : [1.5, 1.7, 1.9, 2.1, 2.3],
            severancePay : [1.0, 1.1, 1.2, 1.3, 1.4],
            machineUtilities : [2.0, 2.2, 2.4, 2.6, 2.8],
            employeeBenefits : [1.0, 1.1, 1.2, 1.3, 1.4]
        };
    }
}

export default TermProcessing;