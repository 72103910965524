class Performance{
    constructor(){
        const data =  [
            {
                title:"경영진단표",
                tabName:"diagnosis-table"
            },
            {
                title:"입출금집계표",
                tabName:"total-table"
            },
            {
                title:"손익계산서",
                tabName:"income-statement"
            },
            {
                title:"재무상태표",
                tabName:"financial-statement"
            },
            {
                title:"손익분기레벨추이표",
                tabName:"breakeven-level-trend",
                type:"bar",
                value:[],
                unit:{
                    from:120,
                    to:40,
                    start:100,
                    gridUnit:2,
                    max:100,
                    sign:"%"
                },
                area:[
                    {
                        name:"S.(초안정)",
                        from:60,
                        to:40,
                        fill:"transparent"
                    },
                    {
                        name:"A.(우량)",
                        from:80,
                        to:60,
                        fill:"#edeee8"
                    },
                    {
                        name:"B.(보통)",
                        from:90,
                        to:80,
                        fill:"#daddd2"
                    },
                    {
                        name:"C.(위험수위)",
                        from:100,
                        to:90,
                        fill:"#f6d0a3"
                    },
                    {
                        name:"D.(적자)",
                        from:120,
                        to:100,
                        fill:"#ed9879"
                    }
                ]
            },
            {
                title:"시장점유율그래프",
                tabName:"market-share",
                type:"line",
                value:[],
                unit:{
                    from:0,
                    to:55,
                    gridUnit:1,
                    max:50,
                    sign:"%"
                }
            },
            {
                title:"자기자본액그래프",
                tabName:"capital-amount",
                type:"line",
                value:[],
                unit:{
                    from:0,
                    to:55,
                    gridUnit:1,
                    max:50,
                    sign:""
                }
            },
            {
                title:"자기자본비율그래프",
                tabName:"capital-ratio",
                type:"line",
                value:[],
                unit:{
                    from:0,
                    to:110,
                    gridUnit:2,
                    max:100,
                    sign:"%"
                }
            },
        ];

        return data;
    }
}

export default Performance;