import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

import Speech from '../../../functions/Speech';

/* images */

import Button from '../parts/Button';
const speech = new Speech();



class Replay extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){
    this.props.setActive();
  }

    replay = () => {
        if(this.props.actionType === "SellingProducts"){
            if(this.props.tempData.type === "first") this.props.setActionType("Bidding");
            else if(this.props.tempData.type === "others") this.props.setActionType("BiddingOthers");
        } 
        this.props.changePage("action");
    }

    finish = () => {
        this.props.changePage("session-end");
        this.props.setTempData(null);
    }

    render() {
        let active = this.props.game.actions.filter(action=>action.name===this.props.actionType)[0].active;
        if(this.props.tempData
            && this.props.tempData.marketName
            && this.props.tempData.marketName.length>=6) active = false;
        
        return (
            <div id="page-replay" className="ver-center text-green">
                <div className="item-container">
                    <div className="item"><Button onClick={this.replay} speech={this.props.replay.replayText} className={ (( this.props.actionType !== "SellingProducts" && this.props.actionType !== "AdvertisingEffect" && active) 
                                                                || 
                                                            (active && (this.props.tempData 
                                                                && this.props.tempData.sellingAbility!==undefined 
                                                                && this.props.tempData.sellingAbility>0
                                                                && this.props.tempData.sellingAbilityTotal>this.props.tempData.totalNum)))
                                                                ||
                                                                (active && this.props.actionType == "AdvertisingEffect"&& (this.props.tempData 
                                                                    && this.props.tempData.sellingAbility!==undefined 
                                                                    && this.props.tempData.sellingAbility>0))
                                                                ?"":"deactive"}>{this.props.replay.replayText}</Button></div>
                    <div className="item"><Button onClick={this.finish} speech={this.props.replay.finishText} >{this.props.replay.finishText}</Button></div>
                </div>
            </div>
        );
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(Replay);
