import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

/* Components */
import Button from '../parts/Button';
import Speech from '../../../functions/Speech';
import Server from '../../../functions/Server';

const speech = new Speech();
const server = new Server();

class PeriodStart extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount = () => {

  }

  confirm = (e) => {
    e.preventDefault();
    this.props.changePage("basic-process");
  }


  render() {
    const user = this.props.user;
    return (
      <div id="page-period-start" className="ui-primary ui-fix-activities bg-green-dark text-white">
          <div className="fix">
              <div className="fix-inner">
                  <div className="item-container">
                      <div className="item"><div className="message text-center">{user.period}기를 시작합니다</div></div>
                      <div className="item"><Button onClick={this.confirm} speech="확인" >확인</Button></div>
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(PeriodStart);
