class Function{

    getMax = (rule,status,period=1) => {
        if(rule.type === "buy"){
            let max;
            if(!this.isEmpty(rule.price)) max = Math.floor(status.cashBalance.value / rule.price);
            else max = Math.floor(status.cashBalance.value / status[rule.status].price);
            
            if(rule.limit){
                const relatedNum = rule.limit - status[rule.status].value;
                if(max > relatedNum) max = relatedNum;
            }
            if(rule.max){
                if(max>rule.max) max = rule.max;
            }
            return max;
        } 
        else if(rule.type === "related_buy"){
            let max = Math.floor(status.cashBalance.value / status[rule.status].price);
            const relatedNum = status[rule.related].value - status[rule.status].value;

            if(max > relatedNum) max = relatedNum;
            if(max < 0) max = 0;
            return max;
        } 
        else if(rule.type === "convert"){
            let max = Math.floor(status.cashBalance.value / status[rule.status].price);
            const relatedNum = status[rule.related].value;
            if(max > relatedNum) max = relatedNum;

            if(rule.status === "workInProcess"){
                // 생산능력 계산
                let worker = status.worker.value;
                let machineLarge = status.machineLarge.value;
                let machineNormal = status.machineNormal.value;
                let machineAdvanced = this.pairing(status.machinePart.value,machineNormal);
                let PAC = status.PAC.value;

                machineNormal = machineNormal - machineAdvanced;

                let workingLarge = this.pairing(worker,machineLarge);
                worker -= workingLarge;
                let workingAdvanced = this.pairing(worker,machineAdvanced);
                worker -= workingAdvanced;
                let workingNormal = this.pairing(worker,machineNormal);
                let ability = workingLarge*(4+PAC) + workingAdvanced*(2+PAC) + workingNormal*(1+PAC);

                if(max > ability) max = ability;
            }
            
            return max;
        } 
        else if(rule.type === "borrow"){
            let m = period<4?2:3;
            let max = Math.floor(status["equityCapital"].value*m - status[rule.status].value);
            return max;
        } 
        else if(rule.type === "specialBorrow"){
            let max = rule.max;
            return max;
        }
        else if(rule.type === "loan"){
            let max = Math.floor(status.cashBalance.value / status[rule.status].price);
            return max;
        } 
        else if(rule.type === "loanWithdrawal"){
            let max = Math.floor(status["loanLended"].value / rule.price);
            return max;
        } 
        else if(rule.type === "sell"){
            let max = status[rule.status].value;
            
            // 독점판매
            if(rule.related){
                let related_value = status[rule.related].value;
                if(rule.related == "advertising"){
                    let salesman = status.salesman.value;

                    if(related_value > salesman*2) related_value = salesman*2;
                    
                    if(max>related_value*rule.relatedNum) max = related_value*rule.relatedNum;
                }else if(max>status[rule.related].value*rule.relatedNum){
                    max = related_value*rule.relatedNum;
                }
            } 

            // 최대 판매개수 제한
            if(rule.max){
                if(max>rule.max) max = rule.max;
            }
            // 기계 매각시 판매능력 계산 안함
            const isMachine = ['machineNormal','machinePart','machineLarge'].includes(rule.status);

            return isMachine?max:this.checkSellingAbility(status,max);
        }
        else if(rule.type === "bidding"){
            let max = status[rule.status].value;

            // 판매능력 계산
            let salesman = status.salesman.value;
            let advertising = status.advertising.value;
            if(advertising>salesman*2)advertising=salesman*2;
            let ability = salesman*2 + advertising*2;
            if(max > ability) max = ability;

            // 최대 판매개수 제한
            if(rule.max){
                if(max>rule.max) max = rule.max;
            }

            return max;
        }
        else if(rule.type === "lend"){
            let max = status[rule.status].value;
            return max;
        }
    }


    // 판매능력 제한
    checkSellingAbility = (status, value) => {
        let salesman = status.salesman.value;
        let advertising = status.advertising.value;
        if(advertising>salesman*2)advertising=salesman*2;
        let ability = salesman*2 + advertising*2;
        if(value > ability) value = ability;

        return value;
    }

    getSellingAbility = (status) => {
        let salesman = status.salesman.value;
        let advertising = status.advertising.value;
        if(advertising>salesman*2) advertising=salesman*2;
        return salesman*2 + advertising*2;
    }

    round = (value, d) => {
        const m = Math.pow(10,d);
        return Math.round(value*m)/m
    }

    numFix = (value) => {
        return this.round(this.divisionHandling(value),1);
    }

    nanToZero = (value) => {
        if(isNaN(value)) return 0;
        else return value;
    }

    infinityToZero = (value) => {
        if(isFinite(value)) return value;
        else return 0;
    }

    divisionHandling = (value) => {
        return this.infinityToZero(this.nanToZero(value));
    }

    pairing = (value1, value2) => {
        let larger = value1>value2?value1:value2;
        let smaller = value1<=value2?value1:value2;

        let result = this.nanToZero(larger%smaller);

        if(result===0 && smaller>0 || result<smaller) return smaller;
        else return result;
    }

    isEmpty = (value) => {
        if(value === null || value === undefined || isNaN(value)) return true;
        else return false;
    }

    setCookie = (name, value, exp) => {
        var date = new Date();
        date.setTime(date.getTime() + exp*24*60*60*1000);
        document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
    }
    
    getCookie = (name) => {
        var value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return value? value[2] : null;
    };

    removeCookie = function(name) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1999 00:00:10 GMT;';
    }
    
}

export default Function;