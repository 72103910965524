
import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

/* Components */
import Button from '../../parts/Button';


import Server from '../../../functions/Server';
const server = new Server();

class UserCard extends Component {

  constructor(props){
    super(props);
  }


  removeUser = () => {
    const this_ = this;
    if(window.confirm("정말 유저를 삭제하시겠습니까?")){
      server.removeUser(this.props.data.email, function(data){
        server.getUserList((data)=>{
            this_.props.setUserList(data);
        });
      })
    }
  }

  openSaveList = () => {
    this.props.openSaveList(this.props.data.email, this.props.data.name);
  }

  saveUserFile = () => {
    const this_ = this;
    server.getUserdata(this.props.data.email, function(data){
      this_.downloadJSON(data);
    });
  }

  downloadJSON = async (data) => {
    const fileName = 
      this.props.data.email 
      + "_" + new Date().getFullYear() + new Date().getMonth() + new Date().getDate()
      + "_" + new Date().getHours() + new Date().getMinutes();
      
    const json = JSON.stringify(data);
    const blob = new Blob([json],{type:'application/json'});
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  

  render() {
    const data = this.props.data;
    //console.log(data);
    return (
        <div className="usercard">
            <div className="inner">
              
                <div className="usercard-header float-container bg-green text-white">
                  <div className="float-left">{data.name} ({data.company})</div>
                  <div className="float-right">{data.period}기 진행중</div>
                </div>
                <div className="usercard-body">
                  <div className="usercard-status row">

                  {!this.props.simplify_userdata&&
                    <Fragment>
                      <div className="col float-container">
                        <div className="float-left">{data.status.cashBalance.name}</div>
                        <div className="float-right">{data.status.cashBalance.value}</div>
                      </div>
                      <div className="col float-container">
                        <div className="float-left">{data.status.equityCapital.name}</div>
                        <div className="float-right">{data.status.equityCapital.value}</div>
                      </div>
                      <div className="col float-container">
                        <div className="float-left">{data.status.sales.name}</div>
                        <div className="float-right">{data.status.sales.value}</div>
                      </div>
                      <div className="col float-container">
                        <div className="float-left">{data.status.salesNumber.name}</div>
                        <div className="float-right">{data.status.salesNumber.value}</div>
                      </div>
                      <hr className="divider"></hr>
                    </Fragment>
                    }
                    <div className="col float-container">
                      <div className="float-left">{data.status.product.name}</div>
                      <div className="float-right">{data.status.product.value}</div>
                    </div>
                    <div className="col float-container">
                      <div className="float-left">{data.status.workInProcess.name}</div>
                      <div className="float-right">{data.status.workInProcess.value}</div>
                    </div>
                    <div className="col float-container">
                      <div className="float-left">{data.status.material.name}</div>
                      <div className="float-right">{data.status.material.value}</div>
                    </div>

                    <hr className="divider"></hr>

                    <div className="col float-container">
                      <div className="float-left">{data.status.machinePart.name}</div>
                      <div className="float-right">{data.status.machinePart.value}</div>
                    </div>
                    <div className="col float-container">
                      <div className="float-left">{data.status.machineNormal.name}</div>
                      <div className="float-right">{data.status.machineNormal.value}</div>
                    </div>
                    <div className="col float-container">
                      <div className="float-left">{data.status.machineLarge.name}</div>
                      <div className="float-right">{data.status.machineLarge.value}</div>
                    </div>

                    <hr className="divider"></hr>

                    <div className="col float-container">
                      <div className="float-left">{data.status.worker.name}</div>
                      <div className="float-right">{data.status.worker.value}</div>
                    </div>
                    <div className="col float-container">
                      <div className="float-left">{data.status.salesman.name}</div>
                      <div className="float-right">{data.status.salesman.value}</div>
                    </div>

                    <hr className="divider"></hr>

                    <div className="col float-container">
                      <div className="float-left">{data.status.advertising.name}</div>
                      <div className="float-right">{data.status.advertising.value}</div>
                    </div>
                    <div className="col float-container">
                      <div className="float-left">{data.status.RND.name}</div>
                      <div className="float-right">{data.status.RND.value}</div>
                    </div>
                    <div className="col float-container">
                      <div className="float-left">{data.status.insurance.name}</div>
                      <div className="float-right">{data.status.insurance.value}</div>
                    </div>
                    <div className="col float-container">
                      <div className="float-left">{data.status.PAC.name}</div>
                      <div className="float-right">{data.status.PAC.value}</div>
                    </div>
                    <div className="col float-container">
                      <div className="float-left">{data.status.merchandiser.name}</div>
                      <div className="float-right">{data.status.merchandiser.value}</div>
                    </div>
                    <div className="col float-container">
                      <div className="float-left">{data.status.marketResearch.name}</div>
                      <div className="float-right">{data.status.marketResearch.value}</div>
                    </div>

                    {!this.props.simplify_userdata&&
                    <Fragment>
                    <hr className="divider"></hr>

                    <div className="col float-container">
                      <div className="float-left">{data.status.borrowed.name}</div>
                      <div className="float-right">{data.status.borrowed.value}</div>
                    </div>
                    <div className="col float-container">
                      <div className="float-left">{data.status.loanLended.name}</div>
                      <div className="float-right">{data.status.loanLended.value}</div>
                    </div>
                    <div className="col float-container">
                      <div className="float-left">{data.status.loanBorrowed.name}</div>
                      <div className="float-right">{data.status.loanBorrowed.value}</div>
                    </div>
                    <div className="col float-container">
                      <div className="float-left">{data.status.bond.name}</div>
                      <div className="float-right">{data.status.bond.value}</div>
                    </div>

                    <hr className="divider"></hr>

                    <div className="col float-container">
                      <div className="float-left">{data.status.fire.name}</div>
                      <div className="float-right">{data.status.fire.value}</div>
                    </div>
                    <div className="col float-container">
                      <div className="float-left">{data.status.mistake.name}</div>
                      <div className="float-right">{data.status.mistake.value}</div>
                    </div>
                    <div className="col float-container">
                      <div className="float-left">{data.status.theft.name}</div>
                      <div className="float-right">{data.status.theft.value}</div>
                    </div>
                    </Fragment>
                    }
                  </div>
                  {!this.props.simplify_userdata&&
                    <Fragment>
                      <div className="usercard-email">{data.email}</div>
                      <div className="usercard-btn text-green row">
                        <div className="item col col-50"><Button className="btn" onClick={this.removeUser} soundplay="back">유저삭제</Button></div>
                        <div className="item col col-50"><Button className="btn" onClick={this.openSaveList}  soundplay="button">저장리스트</Button></div>
                      </div>
                      <div className="usercard-btn text-green row">
                        <div className="item col col-100"><Button className="btn" onClick={this.saveUserFile} soundplay="button">데이터파일 저장</Button></div>
                      </div>
                    </Fragment>
                    }
                </div>
            </div>
        </div>
    );
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(UserCard);
