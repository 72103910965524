import Market from "./Market";
class Markets{
    constructor(){
        const markets =  [
            new Market("제주",3, 1.0, 4.0),
            new Market("광주",4, 1.1, 3.6),
            // new
            new Market("대전",6, 1.2, 3.2),
            new Market("대구",9, 1.3, 2.8),
            // old
            // new Market("대구",6, 1.2, 3.2),
            // new Market("수원",9, 1.3, 2.8),
            new Market("부산",13, 1.4, 2.4),
            new Market("서울",20, 1.5, 2.0)
        ];

        return markets;
    }
}

export default Markets;