import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

/* Components */
import SubTab from '../parts/SubTab';
import FlowTable from '../parts/FlowTable';
import IncomeStatement from '../parts/IncomeStatement';
import FinancialStatement from '../parts/FinancialStatement';

import Function from "../../../functions/Function";
import History from "../../../data/user/History";

const func = new Function();
const { updateIn } = require('immutable');

class Settlement extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount()  {
        // this.props.changeSubTab('financial-statement');
    }

    render() {
        const subtab = this.props.subtab;
        const list_item = this.props.game.settlement_list.filter(item => item.tabName === subtab)[0];
        const settlementData = this.props.settlementData;
        return (
            <Fragment>
            <SubTab list={this.props.game.settlement_list}></SubTab>
            {subtab!="income-statement"&&subtab!="financial-statement"
                &&<FlowTable item={list_item}></FlowTable>} 
            {subtab==="income-statement"&&<IncomeStatement data={settlementData}></IncomeStatement>} 
            {subtab==="financial-statement"&&<FinancialStatement data={settlementData}></FinancialStatement>} 
            </Fragment>
        );
        
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Settlement);