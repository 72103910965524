class SettlementData{
    constructor(){
        const data = {
            step:0,
            returnOptions:[],
            chart:{},
            settlement:{},
            disposalProfits: {
                allocation: 0,
                bonus:0,
                carryOver:0
            },
            endProcess: {
                debtRepaymentAmount:0,
                debtRepaymentAmountLoan:0,
                debtRepaymentAmountBond:0,
                paymentTotal:0,
                paymentTotalBefore:0
            },
            directCost: true,
            profitDisposal : false,
            userSave:null
        };

        return data;
    }
}

export default SettlementData;