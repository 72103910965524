import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import Function from "../../../functions/Function";

/* images */

/* Components */
import Button from '../parts/Button';
import DisplayValue from '../parts/DisplayValue';

/* functions */
import Server from '../../../functions/Server';
const server = new Server();

const { updateIn } = require('immutable');
const func = new Function();

class SettlePeriodCost extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){
      this.props.autoSettlement();
  }

  confirm = (e) => {
    e.preventDefault();
    this.props.nextTab();
  }

  render() {
    const returnOptions = this.props.settlementData.returnOptions;
    const items = returnOptions.map(option =>(<DisplayValue label={option.name} conClassName='item col col-50' value={option.value}></DisplayValue>));
    return (
        <div className="ui-primary ui-fix-tab text-black scroll-ver">
            <div className="fix">
                <div className="fix-inner">
                    <h3>기간비용정산</h3>
                    <div className="content-body">
                        <div className="row">
                            {items}
                        </div>
                    </div>
                    <div className="confirm-container text-green row">
                        <div className="item col col-100"><Button onClick={this.confirm} soundplay="turnend">반납하기</Button></div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SettlePeriodCost);
