import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import Login from './login/Login'; 
import SelectTeam from './login/SelectTeam'; 
import Intro from './login/Intro'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';

/* images */

/* Components */

class LoginBox extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const page = this.props.page;
    return (
      <Fragment>
        <div className="content-box">
            {(page=='login'||page=='signup')&&<Login></Login>}
            {page=='selectteam'&&<SelectTeam></SelectTeam>}
        </div>
        <Intro></Intro>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(LoginBox);
