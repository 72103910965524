import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../store/StoreFunc';
/* images */
 
/* Components */
import Tab from './parts/Tab'; 
import SubTab from './parts/SubTab'; 
import Total from './parts/Total'; 
import IncomeStatement from './parts/IncomeStatement'; 
import FinancialStatement from './parts/FinancialStatement'; 
import Graph from './parts/Graph'; 
import Table from './parts/Table'; 

class PerformanceBox extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount = () => {
    this.props.changeSubTab("period0");
  }

  render() {
    console.log("graphData");
    const tab = this.props.tab;
    const subtab = this.props.subtab;
    const performanceList = this.props.user.performance;
    const settlemetData_history = this.props.user.settlementData_history;
    const graphData = performanceList.filter(item=>item.tabName===tab)[0];
  //  console.log("graphData",graphData);
  //  console.log("HIstory!!" , settlemetData_history , subtab);
    // console.log(performanceList.filter(item=>item.tabName===tab));
    return (
        <Fragment>
            {(tab==="diagnosis-table"||tab==="total-table"||tab==="income-statement"||tab==="financial-statement")&&<div id="performance-box" className="content-box ui-primary ui-fix-tab bg-green">
            </div>}
            <Tab list={performanceList}></Tab>
            {tab==="diagnosis-table"&&<Table></Table>}
            {(tab==="total-table"||tab==="income-statement"||tab==="financial-statement")&&settlemetData_history&&<SubTab list={(()=>{let list = []; for(let i=0; i<settlemetData_history.length; i++)list.push({title:(i+1)+'기',tabName:"period"+i}); return list})()}></SubTab>}
            {(tab==="total-table")&&subtab!=""&&settlemetData_history&&settlemetData_history.length>0&&<Total data={settlemetData_history[parseInt(subtab.replace("period",""))]}></Total>}
            {(tab==="income-statement")&&subtab!=""&&settlemetData_history&&settlemetData_history.length>0&&<IncomeStatement data={settlemetData_history[parseInt(subtab.replace("period",""))]}></IncomeStatement>}
            {(tab==="financial-statement")&&subtab!=""&&settlemetData_history&&settlemetData_history.length>0&&<FinancialStatement data={settlemetData_history[parseInt(subtab.replace("period",""))]}></FinancialStatement>}
            {graphData&&graphData.type&&<Graph data={graphData}></Graph>}
        </Fragment>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(PerformanceBox);
