import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

import Speech from '../../../functions/Speech';
import Sound from '../../../functions/Sound';

/* image */
import img_draw_card from '../../../assets/images/draw-card.svg';
import img_draw_card_logo from '../../../assets/images/draw-card-logo.svg';
import img_decision_card from '../../../assets/images/decision-card.svg';
import img_risk_card from '../../../assets/images/risk-card.svg';
import img_lucky_card from '../../../assets/images/lucky-card.svg';

const speech = new Speech();



class DrawCard extends Component {


    state = {
        cardType: {}
    }

  constructor(props){
    super(props);
  }

  componentDidMount(){
    this.props.close_statusbox();
  }

  changePage = (e) => {
    this.props.changePage(this.state.cardType.page);
    this.props.setDrawingCard(false);
  }

  drawCard = (e) => {

    if(!this.props.state.is_drawing_card){
      this.props.setDrawingCard(true);
      const period = this.props.state.user.period;
      
      let ran = Math.random();
      let cardType;

      let isRestart = false;
      if(this.props.prev_card == "risk_card"){
        const game = this.props.game;
        const risk = game.risk_card[this.props.state.risk_index];
        isRestart = risk.restart?true:false;
      }
      // console.log("isRestart",isRestart);
      
      if(period === 1) cardType = {type:"decision", page:"decision_card", name:"Decision Card", className:"card-decision"};
      else{
          // console.log("이전카드",this.props.prev_card,this.props.pprev_card);
          if(
            /* this.props.prev_card == "risk_card" && this.props.pprev_card == "risk_card" */
            this.props.risk_count >= 2
          ){
            if(!isRestart)this.props.setRiskCount(0);
            // 전체 86 보통 60 행운1 3 행운2 3
            if(ran < 0.9095) cardType = {page:"decision_card", name:"Decision Card", className:"card-decision"};
            else if(ran < 0.9547) cardType = {page:"lucky_card_1", name:"Lucky Card", className:"card-lucky"};
            else cardType = {page:"lucky_card_2", name:"Lucky Card", className:"card-lucky"};
          }
          else{

            // 전체 86 보통 60 행운1 3 행운2 3 리스크 20
            if(ran < 0.6976) cardType = {page:"decision_card", name:"Decision Card", className:"card-decision"};
            else if(ran < 0.7323) cardType = {page:"lucky_card_1", name:"Lucky Card", className:"card-lucky"};
            else if(ran < 0.7670) cardType = {page:"lucky_card_2", name:"Lucky Card", className:"card-lucky"};
            else{
              cardType = {page:"risk_card", name:"Risk Card", className:"card-risk"};
              this.props.getRiskIndex();
              this.props.riskStart();
            }

            if(cardType.page=="risk_card") this.props.setRiskCount(this.props.risk_count+1);
            else if(!isRestart) this.props.setRiskCount(0);
          }
      }
      
      // console.log("risk_count",this.props.risk_count);

      // cardType = {page:"lucky_card_1", name:"Lucky Card", className:"card-lucky"};

      // cardType = {page:"risk_card", name:"Risk Card", className:"card-risk"};
      // this.props.setRiskIndex(2);
      // this.props.riskStart();

      this.props.setPrevCard(cardType.page);

      
      this.setState({
          cardType:cardType
      });
      
      const this_ = this;
      const target = e.currentTarget;
      setTimeout(()=>{
        target.classList.add("reverse");
        setTimeout(()=>{
          switch(cardType.page){
            case 'decision_card': this_.props.sound.play('turnend');
                                  break;
            case 'risk_card': this_.props.sound.play('card_risk');
                                  break;
            case 'lucky_card_1':
            case 'lucky_card_2': this_.props.sound.play('card_lucky');
                                  break;
          }
        },500);
        setTimeout(this_.changePage,1300);
      },100);
      //this.changePanel();
    }
  }

  // onTransitionEnd = () => {
  //   switch(this.state.cardType.page){
  //     case 'decision_card': this.props.sound.play('card_decision');
  //                           break;
  //     case 'risk_card': this.props.sound.play('card_risk');
  //                           break;
  //     case 'lucky_card_1':
  //     case 'lucky_card_2': this.props.sound.play('card_lucky');
  //                           break;
  //   }
  // }


  render() {
    const game = this.props.game;
    const actions = game.actions.filter(action => game['option'].indexOf(action.name)!= -1 );
    const buttons = actions.map(
        action => (<div className="item col col-50"><button className={"btn "+(action.active?"":"deactive")} key={action.name} onClick={this.gotoAction} data-action={action.name} data-speech={action.speech}>{action.title}</button></div>)
    );
    const cardType = this.state.cardType;
    const className_ = cardType.className;
    return (
        <div id="page-drawcard" className="ver-center text-green">
          <div className="page-inner">
              <button className="visual-card" onClick={this.drawCard}>
                <div className="card-inner">
    <div className="card-front" onTransitionEnd={this.onTransitionEnd}><img src={this.props.logo_dataUrl?img_draw_card_logo:img_draw_card} />{this.props.logo_dataUrl&&<div id="card-logo"><img src={this.props.logo_dataUrl} /></div>}</div>
                  <div className="card-back">
                    {className_=='card-decision'&&<img id="decision-card" src={img_decision_card} />}
                    {className_=='card-risk'&&<img id="risk-card" className={className_=='card-risk'?'active':""} src={img_risk_card} />}
                    {className_=='card-lucky'&&<img id="lucky-card" className={className_=='card-lucky'?'active':""} src={img_lucky_card} />}
                  </div>
                </div>
              </button>
          </div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(DrawCard);
