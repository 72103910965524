import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

class BackToBidding extends Component {
    componentDidMount() {
        this.gotoBidding();
    }

    gotoBidding = () => {
        if(this.props.tempData.type === "first") this.props.setActionType("Bidding");
        else if(this.props.tempData.type === "others") this.props.setActionType("BiddingOthers");
        this.props.changePage("action", false);
    }

    render() {
        return(<div></div>)
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(BackToBidding);