class Risks{
    constructor(){
        const risks =  [
            {
                name: "Fire",
                title: "화재발생!",
                message: "재료 창고에 화재가 났습니다. 창고내의 재료는 전부 시장에 돌려주세요.\n단, 보험에 가입하여 있는 기업은 1개에 대하여 1.0의 보험금을 받을 수 있습니다. (보험칩은 돌려줌).  의사결정을 한번 더 하세요. (사고재해메모칸에 기입됩니다)",
                status:"material",
                type:"lose",
                loseRecord:"fire",
                number:"all",
                insurance:true,
                insuranceSelect:true,
                insuranceValue:1.0,
                restart:true,
                decision:true,
                recordType:"D"
            },
            {
                name: "Theft",
                title: "도난발생!",
                message: "영업소내의 제품 1개가 도난을 당해 없어졌습니다. 단, 보험에 가입하여 있는 기업은 1개에 대하여 1.5의 보험금을 받을 수 있습니다. (보험칩은 돌려줌). 의사결정을 한번 더 하세요. (사고재해메모칸에 기입됩니다)",
                status:"product",
                type:"lose",
                loseRecord:"theft",
                number:1,
                insurance:true,
                insuranceSelect:true,
                insuranceValue:1.5,
                restart:true,
                decision:true,
                recordType:"D"
            },
            {
                name: "MissManufacturing",
                title: "제조미스발생!",
                message: "귀사의 공장에서 제조 상의 실수로 1개가 불량이 되었습니다. 공장내에 있는 재공품 1개를 시장에 돌려주세요! (사고재해메모칸에 기입됩니다)",
                status:"workInProcess",
                type:"lose",
                loseRecord:"mistake",
                number:1,
                decision:false
            },
            {
                name: "RetirementWorker",
                title: "워커 퇴직!",
                message: "귀사의 워커 1명이 퇴직을 하였습니다. 워커 1명에 대한 퇴직금 0.5를 노무비에 지불합니다. 단, 아래중 한가지를 실시할 수 있습니다.",
                status:"worker",
                type:"retirement",
                number:1,
                price:0.5,
                actions:["Bidding","PurchasingMaterials"],
                recordType:"J"
            },
            {
                name: "RetirementSalesman",
                title: "세일즈맨 퇴직!",
                message: "귀사의 세일즈맨 1명이 퇴직을 하였습니다. 세일즈맨 1명에 대한 퇴직금 0.5를 판매비에 지불합니다. 단, 아래중 한가지를 실시할 수 있습니다.",
                status:"salesman",
                type:"retirement",
                number:1,
                price:0.5,
                actions:["PurchasingMaterials","Complete","Input"],
                recordType:"P"
            },
            {
                name: "Claim",
                title: "클레임 발생!",
                message: `소비자로부터 "제품에 결함이 있다"는 클레임을 받았습니다. 고치는 비용 0.5(제조경비-K)를 지불하여 주십시오.\n단 다음 한가지를 실시할 수 있습니다.`,
                type:"costs",
                price:0.5,
                actions:["PurchasingMaterials"],
                recordType:"K"
            },
            {
                name: "MachineBreakdown",
                title: "기계 고장!",
                message: `수리비 0.5(제조경비 - K)를 지불하여 주십시오.\n단, 다음 한가지를 실시할 수 있습니다.`,
                type:"costs",
                price:0.5,
                actions:["Bidding","PurchasingMaterials"],
                recordType:"K"
            },
            {
                name: "Design Issues",
                title: "설계에 문제 발생!",
                message: `설계를 수정하는 비용으로 0.5 (제조경비 - K)를 지불하여 주십시오.\n단 다음 한가지를 실시할 수 있습니다.`,
                type:"costs",
                price:0.5,
                actions:["PurchasingMaterials"],
                recordType:"K"
            },
            {
                name: "Accident",
                title: "사고 발생!",
                message: `워커(Worker)는 사고가 났습니다. 따라서 금번은 생산이 안됩니다.\n단 다음 한가지를 실시할 수 있습니다.`,
                type:"rest",
                actions:["Bidding","PurchasingMaterials"]
            },
            {
                name: "ConsumerMovement",
                title: "소비자 운동발생!",
                message: `귀사는 소비의 불매운동 때문에 이번에는 제품의 판매가 안됩니다.\n단, 다음 한가지를 실시할 수 있습니다.`,
                type:"rest",
                actions:["Complete","Input","PurchasingMaterials"]
            },
            {
                name: "EconomicFluctuation",
                title: "경제변동!",
                message: `경제환경이 바뀌어졌습니다. 반대로 돌아가 주십시오.`,
                type:"rest",
                decision:false
            },
            {
                name: "Disease",
                title: "사장 병으로 쓰러짐!",
                message: `중요한 의사결정자인 귀하는 휴양을 해야 할 필요가 있습니다. 금번은 쉬십시오.`,
                type:"rest",
                decision:false
            },
            {
                name: "Typhoon",
                title: "태풍 발생!",
                message: `대단히 큰 태풍이 발생하였습니다. 피해를 입어 활동을 할 수가 없습니다. 금번은 쉬십시오.`,
                type:"rest",
                decision:false
            },
            {
                name: "SpecialPurchaseAll",
                title: "모든 회사 공통!",
                message: `특별재료 구입가능!!\n재료가 시장에 범람하고 있습니다. 모든 회사는 1개 1.2로 3개까지 구입 가능합니다. (부족한 것은 창고로부터..) 의사결정을 한번 더 하세요.`,
                type:"action",
                actions:["SpecialPurchaseAll"],
                restart:true,
                decision:true
            },
            {
                name: "ExclusiveSale",
                title: "신제품 개발 성공!",
                message: `3.2의 가격으로 독점판매를 할 수 있습니다. 연구개발칩 1장에 대하여 2개까지 팔립니다. (판매능력 범위 내에 한함)`,
                type:"action",
                actions:["ExclusiveSale"]
            },
            {
                name: "FailureDevelopment",
                title: "신제품 개발 실패!",
                message: "개발 프로젝트의 하나가 실패 하였습니다. 연구개발칩을 한장 돌려주십시오. 단, 연구개발을 하고 있는 회사에 한함. 의사결정을 한번 더 하세요.",
                status:"RND",
                type:"lose",
                number:1,
                restart:true,
                decision:true
            }
        ];

        return risks;
    }
}

export default Risks;