import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';


/* images */
import icon_cards from '../../../assets/icon/cards.svg';
import icon_cards_active from '../../../assets/icon/cards-active.svg';

/* Components */
import Button from '../parts/Button';
import Speech from '../../../functions/Speech';
import Data from '../../../data/Data';
const speech = new Speech();
const data = new Data();

class SessionStart extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){
    this.props.setActive();
    this.props.setTempData(null);
  }

  gotoRuleB = (e) => {
      this.props.changePage('ruleb');
  }

  gotoBidding = (e) => {
    
    this.props.changePage('action');
    this.props.setActionType('BiddingOthers');
      
  }

  gotoSpecialPurchase= (e) => {
    this.props.setPageNext('session-end');
      this.props.changePage('action');
      this.props.setActionType('SpecialPurchaseAll');
  }

  endPeriod = (e) => {
      this.props.updateSettlement(data.getSettlementData());
      this.props.changeMode('settlement');
      this.props.changeTab('');
      this.props.changeSubTab('');
      this.props.setTabList(this.props.game.periodend_list);
  }


  render() {
    const user = this.props.user;
    const actions = this.props.game.actions;
    const bidding = actions.filter(action=>action.name==="BiddingOthers")[0].active;
    const specialPurchase = actions.filter(action=>action.name==="SpecialPurchaseAll")[0].active;

    return (
        <div id="page-session-start" className="ui-primary ui-fix-activities text-green scroll-ver">
          <div className="fix">
            <div className="fix-inner">
                <Button id="btn-start" className="bg-green" onClick={this.gotoRuleB} speech="의사결정">
                    <img className="icon" src={icon_cards} />
                    <img className="icon-active" src={icon_cards_active} />
                    <p>의사결정</p>
                </Button>
                <div className="row-div">
                  <div className="item-container row">
                      <div className="item col col-33"><Button className={!bidding?'deactive':null} onClick={this.gotoBidding} speech="응찰">응찰</Button></div>
                      <div className="item col col-33"><Button className={!specialPurchase?'deactive':null}onClick={this.gotoSpecialPurchase} speech="특별 재료 구입">특별<br />재료구입</Button></div>
                      <div className="item col col-33"><Button onClick={this.endPeriod} speech={user.period+"기 종료"}>{user.period}기 종료</Button></div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SessionStart);
