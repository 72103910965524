import Status from "./user/Status";
import History from "./user/History";
import Analysis from "./user/Analysis";
import Performance from "./performance/Performance";


class User{
    constructor(){
        const user = {
            period: 1,
            session_count:1,
            basic_process_complete: true,
            status : new Status(),
            history : new History(),
            historyIndex : new History(),
            analysis : new Analysis(),
            performance : new Performance(),
            settlementData_history : []
        };

        return user;
    }
}

export default User;