import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

class DisplayValue extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
        <div className={'item ' + this.props.conClassName}>
            <div className="label">{this.props.label}</div>
            <div id={this.props.id} className={'value ' + (this.props.className?this.props.className:null)} onClick={this.onClick}>{this.props.value}</div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(DisplayValue);
