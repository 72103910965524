import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import Speech from '../../../functions/Speech';

class SubTab extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){
    const list = this.props.list;
    if(list.length>0)this.props.changeSubTab(list[0].tabName);
  }

  tabClick = (e) => {
    this.props.changeSubTab(e.currentTarget.getAttribute('tab'));
    this.props.sound.play('button');
  }

  render() {
    const list = this.props.list;
    const tab = this.props.subtab;

    const buttons = list.map(item=>(<button className={tab===item.tabName?'active':''} onClick={this.tabClick} tab={item.tabName}>{item.title}</button>));
    return (
        <nav className="subtab ui-primary scroll-hor">
            <div className="scroll-inner">
            {buttons}
            </div>
        </nav>
    );
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(SubTab);
