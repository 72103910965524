import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import ButtonForm from './ButtonForm';
import InputCheckbox from './InputCheckbox';
/* Components */

class CardForm extends Component {

  constructor(props){
    super(props);
  }

  render() {

    const itemsData = this.props.itemsData;

    const bgColor = (()=>{
        switch(this.props.id){
            case 'decision_card': return 'bg-black text-white';
                                  break;
            case 'risk_card': return 'bg-red text-white';
                                  break;
            case 'lucky_card_1':
            case 'lucky_card_2': return 'bg-blue text-white';
                                  break;
        }
    })();

    return (
        <div id={this.props.id} className={'card '+bgColor}>
            <h3>{this.props.title}</h3>
            {this.props.message?<div className="card-message"><div className="text-inner">{this.props.message}</div></div>:null}
            {this.props.risk&&this.props.risk.insurance&&this.props.status.insurance.value>0?<InputCheckbox id="card-checkbox" inputRef={this.props.checkbox}>보험사용</InputCheckbox>:null}
            <div className="card-buttons">
                <ButtonForm itemsData={itemsData}></ButtonForm>
            </div>
        </div>
    );
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(CardForm);
