import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';
import Function from "../../../functions/Function";

/* images */

/* Components */
import Button from '../parts/Button';
import DisplayValue from '../parts/DisplayValue';

/* functions */
import Server from '../../../functions/Server';
const server = new Server();

const { updateIn } = require('immutable');
const func = new Function();

class Total extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){
    this.tableFloatingAnim();
    document.querySelector('.table-container').addEventListener("scroll",this.tableFloating);
  }
  componentWillUnmount(){
    document.querySelector('.table-container').removeEventListener("scroll",this.tableFloating);
  }

  tableFloatingAnim = () => {
    this.tableFloating();
    if(document.querySelector('.table-container'))requestAnimationFrame(this.tableFloatingAnim);
  }

  tableFloating = () => {
      if(document.querySelector('.table-container')){
          let x = document.querySelector('.table-container').scrollLeft;
          document.querySelector('#floating-table').style.transform = "translateX("+x+"px)";
      }
  }

  confirm = (e) => {
    e.preventDefault();
  }

  render() {
    const historyIndex = this.props.historyIndex;
    const settlementData = this.props.settlementData;
    const chart = settlementData.chart;
  //   let table = analysis.map((item)=>{
  //     let list = item.value.map((v)=>{
  //         return(
  //             <Fragment>
  //                 <td colspan={v.result!=undefined?1:2}>{(v.value?func.round(v.value,1):0).toFixed(1)}</td>
  //                 {v.result!=undefined?<td colspan="1">{v.result+"%"}</td>:null}
  //             </Fragment>
  //         )
  //     });

  //     return(<tr className="row">
  //         <td className="table-label">{item.title}</td>
  //         {list}
  //     </tr>)
  // });

    const index = (()=>{
      let list = []; 
      for(let i=0; i<this.props.session_count; i++)
      list.push(
        ( ()=>{return(<th><div>{i===0?"기초처리":i}</div></th>)} )() 
      ); 
      return list
    })()

    const subject = (()=>{
    //  console.log("historyIndex",historyIndex);
      let list = []; 

      for(let prop in historyIndex){
        if(['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V'].indexOf(prop)!= -1){
          let tr = [];
          tr.push(
            ( ()=>{return(<td className="table-label table-label-index"><div>{prop}</div></td>)} )() 
          ); 
          tr.push(
            ( ()=>{return(<td className="table-label"><div>{historyIndex[prop].title}</div></td>)} )() 
          ); 
          tr.push(
            ( ()=>{return(<td className="table-label">
              {historyIndex[prop].number!=undefined?<div>개수</div>:null}
              {historyIndex[prop].value!=undefined?<div>{historyIndex[prop].number!=undefined?"금액":""}</div>:null}
            </td>)})() 
          ); 

          list.push(
            ( ()=>{return(<tr>{tr}</tr>)} )() 
          );
        }
      }
      return list;
    })()

    const table = (()=>{
    //  console.log("historyIndex",historyIndex);
      let list = []; 

      for(let prop in historyIndex){
        if(['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V'].indexOf(prop)!= -1){
          let tr = [];
          
          for(let i=0; i<this.props.session_count; i++)
          tr.push(
            ( ()=>{return(<td>
              {historyIndex[prop].number!==undefined?<div>{historyIndex[prop].number[i]!==undefined?historyIndex[prop].number[i]:""}</div>:""}
              {historyIndex[prop].value!==undefined?<div>{historyIndex[prop].value[i]!==undefined?func.numFix(historyIndex[prop].value[i]).toFixed(1):""}</div>:""}
            </td>)} )() 
          );

          let termprocessing;
          switch(prop){
            case 'J': termprocessing = chart["_payWorker"].value;
                    break;
            case 'K': termprocessing = chart["_machineUtilities"].value;
                    break;
            case 'P': termprocessing = chart["_paySalesman"].value;
                    break;
            case 'Q': termprocessing = chart["_employeeBenefits"].value;
                    break;
          }

          // 기말처리
          tr.push(
            ( ()=>{return(<td>
              {historyIndex[prop].number!==undefined?<div></div>:""}
              {historyIndex[prop].value!==undefined?<div>{termprocessing!==undefined?termprocessing.toFixed(1):""}</div>:""}
            </td>)} )() 
          ); 

          // 합계
          tr.push(
            ( ()=>{return(<td>
              {historyIndex[prop].number!==undefined?<div>{chart[prop].number!==undefined?chart[prop].number:""}</div>:""}
              {historyIndex[prop].value!==undefined?<div>{chart[prop].value!==undefined?chart[prop].value.toFixed(1):""}</div>:""}
            </td>)} )() 
          ); 
          list.push(
            ( ()=>{return(<tr>{tr}</tr>)} )() 
          );
        }
      }
      return list;
    })()

    return (
        <div id="tab-total" className="table-container ui-primary ui-fix-tab bg-default text-green scroll-both">
          <div id="floating-table" className="table-col">
            <table className="table">
              <tr>
                <th className="table-label" colSpan="3"><div>계정과목</div></th>
              </tr>
              {subject}
             </table>
          </div>
          <div className="table-col">
            <table className="table">
                  <tr>
                      {index}
                      <th><div>기말처리</div></th>
                      <th><div>합계</div></th>
                  </tr>
                  {table}
              </table>
          </div>
        </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Total);
