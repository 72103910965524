import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'; 
import { mapStateToProps,mapDispatchToProps } from '../../../store/StoreFunc';

class Graph extends Component {
    constructor(props){
        super(props);
    }

    state = {
        dataIndex : 0,
        graph_set : {
            width:0,
            height:0,
            grid:{
                col:[],
                row:[]
            }
        }
    }

    componentDidMount() {
        this.sizeSet();
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
      }

    handleResize = () => {
        this.sizeSet();
    }

    sizeSet = () => {
        const ww = window.innerWidth;
        const wh = window.innerHeight;
        const height = wh - 91;

        let graph_set = {
            width:ww,
            height:height,
            colNum:5,
            rowNum:55,
            grid:{
                col:[],
                row:[]
            }
        };

        graph_set.gapCol = graph_set.width/(graph_set.colNum+1);
        graph_set.gapRow = graph_set.height/(graph_set.rowNum+1);

        for(let i=0; i<graph_set.colNum; i++){
            graph_set.grid.col.push((i+1)*graph_set.gapCol);
        }

        for(let i=0; i<graph_set.rowNum; i++){
            graph_set.grid.row.push((i+1)*graph_set.gapRow);
        }
        

       this.setState({
            ...this.state,
            graph_set:graph_set
        });
    }

    resizeGraph = () => {
        const wh = window.innerHeight;
        const height = wh - 80;

        this.setState({
            ...this.state,
            graph_set:{
                ...this.state.graph_set,
                width:height*2,
                height:height
            }
        });
    }

    render() {
        const selectedData = this.props.data;
        const graph_set = this.state.graph_set;
        const unit = selectedData.unit;
        const valueData = selectedData.value;
        const range = Math.abs(unit.from - unit.to);
        const height = graph_set.height;

        let row = (()=>{
            let rowNum = (range/unit.gridUnit)-1;
            let gap = graph_set.height / (rowNum+1);
            let r = [];
            for(let i=0; i<rowNum; i++){
                r.push((i+1)*gap);
            }

            return r;
        })();

        const grid = (() => {
            const direction = unit.from>unit.to?"down":"up";
            const height = graph_set.height;
            

            let colLine = graph_set.grid.col.map(
                x => {
                    return (<line className="grid-line grid-line-col" x1={x} y1="0" x2={x} y2={graph_set.height}></line>);
                }
            );
    
            let rowLine = row.map(
                (y, i) => {
                    const y_ = direction=="down"?y:height-y;

                    let className = "grid-line grid-line-row" ;
                    if(i%10 === 9) className += " grid-line-ten";
                    else if(i%5 === 4) className += " grid-line-five";
    
                    return (<line className={className} x1="0" y1={y_} x2={graph_set.width} y2={y_}></line>);
                }
            );
            
            let subText;
            if(selectedData.area){
                subText = selectedData.area.map(
                    (area) => {
                        const start = unit.from<unit.to?unit.from:unit.to;
                        const areaStart = area.from<area.to?area.from:area.to;
                        const areaRange = Math.abs(area.from-area.to);
                        const areaHeight = (areaRange/range)*height;
                        const x = 5;
                        const y = (areaStart - start)/range * height + areaHeight/2;

                        return (<text className="area-name" x={x} y={y}>{area.name}</text>);
                    }
                );
                if(unit.start){
                    subText.push((()=>{
                        const x = 5;
                        const y = height - (unit.from-unit.start)/range * height;

                        return (<text className="unit-text" x={graph_set.gapCol/2} y={y}>{unit.start}{unit.sign}</text>);
                    })());
                }
            }
            else{
                subText = row.map(
                    (y, i) => {
                        const y_ = direction=="down"?y:height-y;
                        const startValue = unit.from<unit.to?unit.from:unit.to;
                        const value = startValue + (i+1)*unit.gridUnit;
                        if(i%5 === 4) return (<text className="unit-text" x={graph_set.gapCol/2} y={y_}>{value}{unit.sign}</text>);
                    }
                );
            }
            

            return (
                <Fragment>
                    {colLine}
                    {rowLine}
                    {subText}
                </Fragment>
            )
        })();



        const graph = (()=>{
            let graph;

            if(selectedData.type=="line")

            graph = (()=>{
                const gapCol = graph_set.gapCol;
                const barWidth = gapCol * 0.5;

                let bars = valueData.map(
                    (value, i) => {
                        const barRange = value;
                        let ratio = (barRange/range);
                        let isOver = false;
                        if(ratio > 0.95){
                            ratio = 0.95;
                            isOver = true;
                        } 
                        else if(ratio < 0.05){
                            ratio = 0.05;
                            isOver = true;
                        }
                        const barHeight = ratio*height - (isOver?5:0);
                        const x = (i+1)*gapCol + (gapCol-barWidth)/2;
                        const y = height - barHeight;

                        const x1 = x, y1 = y, x2 = x + barWidth, y2 = y, x3 = x2, y3 = y + barHeight, x4 = x, y4 = y3;
                        const gap = height*0.01;
                        const mx = (x2 + x1)*0.5, my = y1, cx = x + (x2 - x1)*0.25, cy = y1-gap;
                        

                        if(isOver) return (<Fragment><path className="value-line" d={"M"+x1+" "+(y1-gap)+" Q"+cx+" "+(cy-gap)+" "+mx+" "+(my-gap)+" T"+x2+" "+(y2-gap)} /><path className="bar" d={"M"+x1+" "+y1+" Q"+cx+" "+cy+" "+mx+" "+my+" T"+x2+" "+y2+" L"+x3+" "+y3+" L"+x4+" "+y4+" Z"} /></Fragment>)
                        else return (<path className="bar" d={"M"+x1+" "+y1+" L"+x2+" "+y2+" L"+x3+" "+y3+" L"+x4+" "+y4+" Z"} />)
                    }
                );

                let valueLine = (()=>{
                    let str = "";
                    const gapCol = graph_set.gapCol;
                    const height = graph_set.height;
                    
        
                    for(let i=0; i<valueData.length; i++){
                        let ratio = (valueData[i]/range);
                        if(ratio > 0.95){
                            ratio = 0.95;
                        } 
                        if(i==0) str += "M "+ (i+1)*gapCol + " " + (height - ratio * height)
                        else str += " L "+ (i+1)*gapCol + " " + (height - ratio * height)
                    }
                    return (<Fragment><path className="value-line-back" d={str}></path><path className="value-line" d={str}></path></Fragment>);
                })()
        
                let points= valueData.map(
                    (value, i) => {
                        const gapCol = graph_set.gapCol;
                        const height = graph_set.height;
                        let ratio = value/range;
                        if(ratio > 0.95){
                            ratio = 0.95;
                        } 
                        const x = (i+1)*gapCol;
                        const y = height - (ratio*height);
        
                        return (<circle className="value-point" cx={x} cy={y} r="5"></circle>);
                    }
                );
        
                let valueText = valueData.map(
                    (value, i) => {
                        const gapCol = graph_set.gapCol;
                        const height = graph_set.height;
                        let ratio = (value/range);
                        if(ratio > 0.95){
                            ratio = 0.95;
                        } 
                        else if(ratio < 0.05){
                            ratio = 0.05;
                        }
                        const x = (i+1)*gapCol;
                        const y = height - (ratio*height);
  
                        return (<text className="value-text" x={x} y={y-10}>{value}{unit.sign}</text>);
                    }
                );
    
                return (
                    <Fragment>
                        {bars}
                        {valueLine}
                        {points}
                        {valueText}
                    </Fragment>
                );
            })();

            else if(selectedData.type === "bar")

            graph = (()=>{
                const gapCol = graph_set.gapCol;
                const height = graph_set.height;
                const barWidth = gapCol * 0.5;
                

                let bars = valueData.map(
                    (value, i) => {
                        const barRange = range - (value - unit.to) - (unit.start?(unit.from - unit.start):0);
                        
                        let ratio = barRange / range;
                        if(unit.start) ratio = barRange / ( barRange>0? (range-(unit.from - unit.start)):(unit.from - unit.start) );

                    //    console.log(ratio);
                        
                        let isOver = false;
                        if(unit.start){
                            if(ratio > 0.93){
                                ratio = 0.93;
                                isOver = true;
                            } 
                            else if(ratio < -0.8){
                                ratio = -0.8;
                                isOver = true;
                            }
                        }
                        else{
                            if(ratio > 0.95){
                                ratio = 0.95;
                                isOver = true;
                            } 
                            else if(ratio < 0.05){
                                ratio = 0.05;
                                isOver = true;
                            }
                        }
                        
                        let barHeight = ratio*height - (isOver?5:0);
                        if(unit.start){
                            if(ratio>0)barHeight = ratio*(height - height*(unit.from - unit.start)/range) - (isOver?5:0);
                            else barHeight = ratio*(height*(unit.from - unit.start)/range) - (isOver?5:0);
                        } 

                        const x = (i+1)*gapCol + (gapCol-barWidth)/2;
                        const y = height - barHeight - (unit.start? height*( (unit.from - unit.start)/range) :0 );

                        const x1 = x, y1 = y, x2 = x + barWidth, y2 = y, x3 = x2, y3 = y + barHeight, x4 = x, y4 = y3;
                        const gap = (unit.start&&ratio<0?-1:1)*height*0.01;
                        const mx = (x2 + x1)*0.5, my = y1, cx = x + (x2 - x1)*0.25, cy = y1 - gap;
                        

                        if(isOver) return (<Fragment><path className="value-line" d={"M"+x1+" "+(y1-gap)+" Q"+cx+" "+(cy-gap)+" "+mx+" "+(my-gap)+" T"+x2+" "+(y2-gap)} /><path className="bar" d={"M"+x1+" "+y1+" Q"+cx+" "+cy+" "+mx+" "+my+" T"+x2+" "+y2+" L"+x3+" "+y3+" L"+x4+" "+y4+" Z"} /></Fragment>)
                        else return (<path className="bar" d={"M"+x1+" "+y1+" L"+x2+" "+y2+" L"+x3+" "+y3+" L"+x4+" "+y4+" Z"} />)

                        // return (<rect className="value-text" x={x} y={y} width={barWidth} height={barHeight}></rect>);
                    }
                );
        
                let valueText = valueData.map(
                    (value, i) => {
                        const barRange = range - (value - unit.to);
                        let ratio = (barRange/range);
                        if(ratio > 0.95) ratio = 0.95;
                        else if(ratio < 0.05) ratio = 0.05;
                        const barHeight = ratio*height;
                        const x = (i+1)*gapCol + gapCol/2;
                        const y = height - barHeight;

                        return (<text className="value-text" x={x} y={y-10}>{value}{unit.sign}</text>);
                    }
                );
    
                return (
                    <Fragment>
                        {bars}
                        {valueText}
                    </Fragment>
                );
            })();

            return graph;
        })();

        let periodText = graph_set.grid.col.map(
            (x,i) => {
                return (<text className="period-text" x={x+3} y={graph_set.height-10}>{i+1}기</text>);
            }
        );

        let area = selectedData.area?selectedData.area:[];
        area = area.map(
            (area) => {
                const start = unit.from<unit.to?unit.from:unit.to;
                const areaStart = area.from<area.to?area.from:area.to;
                const areaRange = Math.abs(area.from-area.to);
                const areaHeight = (areaRange/range)*height;
                const x = 0;
                const y = (areaStart - start)/range * height;

                return (<rect x={x} y={y} width={graph_set.width} height={areaHeight} fill={area.fill}></rect>);
            }
        );

        
        let viewBox = "0 0 " + graph_set.width + " " + graph_set.height;  

        return (
            <Fragment>
                <div id="graph-container" className="ui-primary ui-fix-tab text-black scroll-ver">
                    <svg id="graph" viewBox={viewBox}  width={graph_set.width} height={graph_set.height}>
                        {area}
                        {grid}
                        {graph}
                        {periodText}
                    </svg>
                </div>
            </Fragment>
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Graph);